import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SVGIcon from '../SVGIcon/SVGIcon';
import MascotICon from './Mascot.svg';
import ZaloIcon from './Zalo.svg';
import MessengerIcon from './Messenger.svg';
import HotlineIcon from './HotLine.svg';
import Cancel from './Cancel.svg';
import CancelMobile from './CancelMobile.svg';
import { Viewport } from 'components/ViewportProvider';
import { PHONE_NUMBER } from 'utils/constants';
import backToTopIcon from './backToTop.png';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';

const ChatSupportWrapper = styled.div`
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 10000;
  @media (min-width: 1451px) {
    bottom: 30px;
    right: 30px;
  }
  @media (min-width: 992px) and (max-width: 1450px) {
    bottom: 20px;
    right: 20px;
  }
  transition: 0.5s;
  ${({ isShowBackToTop }) =>
    isShowBackToTop &&
    `
    transform: translateY(-60px)
  `}
`;

const IconWrapper = styled(SVGIcon)`
  transition: 0.2s ease-out;
  border-radius: 50%;
  background: transparent;
`;
const WidgetWrapper = styled.a`
  position: absolute;
  top: 0;
  right: 1px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  transform: translateY(0px);
  transition: all 0.4s ease-in-out;
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  &:hover ${IconWrapper} {
    transform: scale(1.08);
  }
`;
const WidgetText = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  font-size: 16px;
  color: #424242;
  font-weight: 500;
  width: max-content;
`;

const MascotIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.5);
  opacity: 0;
  transition: all 0.4s ease-in-out;
  pointer-events: none;
  z-index: 0;
  ${({ isShowIcon }) =>
    isShowIcon &&
    `
      transform: scale(1);
      opacity: 1;
      z-index: 1;
      pointer-events: auto;
    `}
`;

const Mascot = styled.div`
  position: relative;
  z-index: 10000;
  cursor: pointer;
  width: 54px;
  height: 54px;
  ${({ openChatSupport }) =>
    openChatSupport &&
    `
  & ~ ${WidgetWrapper} {
    opacity: 1;
    pointer-events: auto;
    &:nth-child(2) {
      transform: translateY(-82px);
    }
    &:nth-child(3) {
      transform: translateY(-154px);
    }
    &:nth-child(4) {
      transform: translateY(-226px);
    }
  }
  `}
  @media (min-width: 992px) {
    &:hover ${MascotIconWrapper} {
      transform: scale(1.1);
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  @media (min-width: 992px) {
    background: transparent;
  }
  ${({ openChatSupport }) =>
    openChatSupport &&
    `
  opacity: 1;
  pointer-events: auto;
  `}
`;
const BackToTop = styled.div`
  position: fixed;
  bottom: 90px;
  right: 0px;
  z-index: 1000;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s;
  display: flex;
  align-items: center;
  gap: 6px;
  @media (min-width: 1451px) {
    bottom: 30px;
  }
  @media (min-width: 992px) and (max-width: 1450px) {
    bottom: 20px;
  }
  ${({ isShowBackToTop }) =>
    isShowBackToTop &&
    `
    right: 25px;
    opacity: 1;
    @media (min-width: 1451px) {
      right: 35px;
    }
    @media (min-width: 992px) and (max-width: 1450px) {
      right: 25px;
    }
  `}
  &:hover {
    scale: 1.1;
  }
`;
const BackToTopIcon = styled.img`
  width: 44px;
  height: 44px;
`;
const BackToTopText = styled.div`
  font-size: 14px;
  background: #f0f9f1;
  padding: 4px 8px;
  border-radius: 20px;
  color: #43a047;
  font-weight: 400;
`;
const ChatSupport = () => {
  const [openChatSupport, setOpenChatSupport] = useState(false);
  const [isShowBackToTop, setIsShowBackToTop] = useState(false);
  const handleOnScroll = () => {
    if (window.scrollY > 700) {
      setIsShowBackToTop(true);
    } else {
      setIsShowBackToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      handleOnScroll();
    });
    return () =>
      window.removeEventListener('scroll', () => {
        handleOnScroll();
      });
  }, []);
  const isGyomuSuper = window?.location?.pathname === '/nguyen-lieu-nhat-ban-gyomu-japan';
  return (
    <Viewport.Consumer>
      {({ width }) => {
        const isDesktop = width > 992;
        return (
          <>
            <ChatSupportWrapper isShowBackToTop={isGyomuSuper && isShowBackToTop}>
              <Mascot onClick={() => setOpenChatSupport(!openChatSupport)} openChatSupport={openChatSupport}>
                <MascotIconWrapper isShowIcon={!openChatSupport}>
                  <SVGIcon src={MascotICon} imgAttr={{ loading: 'lazy', width: '54px', height: '54px' }} />
                </MascotIconWrapper>
                <MascotIconWrapper isShowIcon={openChatSupport}>
                  <SVGIcon
                    src={isDesktop ? Cancel : CancelMobile}
                    imgAttr={{ loading: 'lazy', width: '58px', height: '58px' }}
                  />
                </MascotIconWrapper>
              </Mascot>
              <WidgetWrapper href={PHONE_NUMBER}>
                <WidgetText>Hotline</WidgetText>
                <IconWrapper src={HotlineIcon} imgAttr={{ loading: 'lazy', width: '50px', height: '50px' }} />
              </WidgetWrapper>
              <WidgetWrapper target="_blank" href="https://m.me/kamereo.vn">
                <WidgetText>Chat Messenger</WidgetText>
                <IconWrapper src={MessengerIcon} imgAttr={{ loading: 'lazy', width: '50px', height: '50px' }} />
              </WidgetWrapper>
              <WidgetWrapper target="_blank" href="https://zalo.me/2357831519751856428">
                <WidgetText>Chat Zalo</WidgetText>
                <IconWrapper src={ZaloIcon} imgAttr={{ loading: 'lazy', width: '51px', height: '51px' }} />
              </WidgetWrapper>
            </ChatSupportWrapper>
            {isGyomuSuper && (
              <BackToTop
                isShowBackToTop={isShowBackToTop}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                <BackToTopText>
                  <FormattedMessage {...messages.backToTop} />
                </BackToTopText>
                <BackToTopIcon src={backToTopIcon} />
              </BackToTop>
            )}
            <Overlay openChatSupport={openChatSupport} onClick={() => setOpenChatSupport(!openChatSupport)} />
          </>
        );
      }}
    </Viewport.Consumer>
  );
};

export default ChatSupport;
