/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.LandingPage.KamereoBrand';

export default defineMessages({
  titleGyomu: {
    id: `${scope}.titleGyomu`,
    defaultMessage: 'Dòng sản phẩm thương hiệu Gyomu Japan',
  },
  descriptionGyomu: {
    id: `${scope}.descriptionGyomu`,
    defaultMessage:
      'Gyomu Japan là một doanh nghiệp thực phẩm, chúng tôi luôn nỗ lực sản xuất các sản phẩm chất lượng cao, cam kết mang đến cho khách hàng sản phẩm chuyên dụng với giá cả hợp lý và đảm bảo an toàn thực phẩm tuyệt đối.',
  },
  gyomuSuperTarget: {
    id: `${scope}.gyomuSuperTarget`,
    defaultMessage:
      '“Là một doanh nghiệp thực phẩm, chúng tôi luôn nỗ lực sản xuất các sản phẩm chất lượng cao, cam kết mang đến cho khách hàng sản phẩm chuyên dụng với giá cả hợp lý và đảm bảo an toàn thực phẩm tuyệt đối“. ',
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: 'Tìm hiểu thêm về chất lượng sản phẩm',
  },
  seeMore: {
    id: `${scope}.seeMore`,
    defaultMessage: 'Xem thêm',
  },
  clickHere: {
    id: `${scope}.clickHere`,
    defaultMessage: 'Tại đây',
  },
  clickHereBottom: {
    id: `${scope}.clickHereBottom`,
    defaultMessage: 'tại đây',
  },
  affirmative: {
    id: `${scope}.affirmative`,
    defaultMessage: 'Đây cũng cũng chính là giá trị cốt lõi mà Kamereo muốn hướng đến',
  },
  collaboration: {
    id: `${scope}.collaboration`,
    defaultMessage: 'Hợp Tác Giữa Kamereo và Gyomu Japan',
  },
  commitText: {
    id: `${scope}.commitText`,
    defaultMessage: 'Chúng tôi cam kết mang đến sản phẩm chất lượng Nhật Bản với giá tốt nhất!',
  },
  annoucementText: {
    id: `${scope}.annoucementText`,
    defaultMessage:
      'Chúng tôi rất vui mừng thông báo về sự hợp tác chiến lược giữa Kamereo và Gyomu Japan, một trong những chuỗi siêu thị bán buôn nổi tiếng nhất từ Nhật Bản. Sự hợp tác này nhằm mang đến cho khách hàng doanh nghiệp của Kamereo cơ hội tiếp cận hàng loạt sản phẩm chất lượng cao và giá cả hợp lý từ Gyomu Japan.',
  },
  introduceGyomuText: {
    id: `${scope}.introduceGyomuText`,
    defaultMessage:
      'Gyomu Japan nổi tiếng với việc cung cấp đa dạng các loại thực phẩm và nguyên liệu nấu ăn từ Nhật Bản, từ thực phẩm tươi sống, thực phẩm đông lạnh, đồ hộp, gia vị đến các sản phẩm nông sản và đồ uống. Tất cả sản phẩm đều được kiểm soát chất lượng nghiêm ngặt, đảm bảo an toàn vệ sinh thực phẩm và giữ nguyên hương vị truyền thống của Nhật Bản.',
  },
  collaborationText: {
    id: `${scope}.collaborationText`,
    defaultMessage:
      'Thông qua sự hợp tác này, khách hàng doanh nghiệp của Kamereo có thể dễ dàng mua các sản phẩm chất lượng cao từ Gyomu Japan với giá tốt nhất. Chúng tôi cam kết mang đến cho bạn những sản phẩm tốt nhất để đáp ứng nhu cầu kinh doanh của bạn.',
  },
  infoText: {
    id: `${scope}.infoText`,
    defaultMessage:
      'Để biết thêm thông tin chi tiết về Gyomu Japan và danh sách sản phẩm, vui lòng truy cập trang web chính thức của Gyomu Japan tại Nhật Bản. Bạn cũng có thể xem thêm các sản phẩm khác tại Danh mục sản phẩm của Kamereo.',
  },
  inviteText: {
    id: `${scope}.inviteText`,
    defaultMessage:
      'Hãy tận dụng cơ hội này để mua sắm các sản phẩm Nhật Bản chất lượng cao với giá tốt nhất thông qua Kamereo và Gyomu Japan!',
  },
  whyGyomuHasBestPrice: {
    id: `${scope}.whyGyomuHasBestPrice`,
    defaultMessage: 'Vì sao sản phẩm chất lượng cao của Gyomu Japan lại có giá thấp? Tìm hiểu thêm',
  },
  eventName: {
    id: `${scope}.eventName`,
    defaultMessage: 'Sự kiện trải nghiệm sản phẩm',
  },
  eventLocation: {
    id: `${scope}.eventLocation`,
    defaultMessage: 'Khách sạn Sheraton Saigon Grand Opera',
  },
  eventTime: {
    id: `${scope}.eventTime`,
    defaultMessage: '5 tháng 11, 2024',
  },
  freeAttendance: {
    id: `${scope}.freeAttendance`,
    defaultMessage: 'Miễn phí tham dự',
  },
  discoverNow: {
    id: `${scope}.discoverNow`,
    defaultMessage: 'Khám phá ngay!',
  },
  qualityProducts: {
    id: `${scope}.qualityProducts`,
    defaultMessage: 'Sản phẩm cao cấp',
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'Từ',
  },
  japan: {
    id: `${scope}.japan`,
    defaultMessage: 'Nhật Bản',
  }
});