/*
 *
 * DeliveryDateTime constants
 *
 */

enum ActionTypes {
  LOAD_DELIVERY_DATE_TIME_REQUEST = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_TIME_REQUEST',
  LOAD_DELIVERY_DATE_TIME_SUCCESS = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_TIME_SUCCESS',
  LOAD_DELIVERY_DATE_TIME_FAILURE = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_TIME_FAILURE',
  LOAD_DELIVERY_DATE_OFF_REQUEST = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_OFF_REQUEST',
  LOAD_DELIVERY_DATE_OFF_SUCCESS = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_OFF_SUCCESS',
  LOAD_DELIVERY_DATE_OFF_FAILURE = 'app/DeliveryDateTime/LOAD_DELIVERY_DATE_OFF_FAILURE',
}

export default ActionTypes;
