import DownloadApp from 'components/DownloadApp';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';
import React from 'react';
import { RouteProps, Link } from 'react-router-dom';
import { Row } from 'antd';
import Select from 'components/Select/Select';
import BackIcon from './Back.svg';
import SVGIcon from '../SVGIcon/SVGIcon';
import bg from './Slice.png';
import bg1 from './Slice-1.png';
import bg2 from './Slice-2.png';
import bg3 from './Slice-3.png';
import media from 'utils/mediaStyle';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from 'utils/messages';
import ChatSupport from 'components/ChatSupport';
import { Language } from 'types/schema';

const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: relative;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  padding: 80pt 8pt;
  ${media.lg`
    padding: 100pt 8pt;
  `};
`;
const Bg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  background-color: #f9fafc;
`;

const ImageTopLeft = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 731px;
  width: 50.7%;
`;
const ImageTopRight = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 709px;
  width: 49.3%;
`;
const ImageBottomLeft = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 955px;
  width: 66.4%;
`;
const ImageBottomRight = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 485px;
  width: 33.6%;
`;
const LangContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
`;

const DownloadAppContainer = styled.div`
  margin-top: 40px;
`;
const BackBtn = styled.div`
  max-width: 720px;
  margin: 0 auto;
  margin-bottom: 10px;

  & a {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    & > div {
      width: 35px;
      height: 35px;
      background: #f5f5f5;
      border-radius: 50%;
      cursor: pointer;
      & > div {
        justify-content: unset;
      }
    }
    & > span {
      color: rgb(51, 51, 51);
    }
    &:hover {
      span {
        color: rgb(67, 160, 71);
      }
    }
  }
`;
interface GateLayoutProps extends RouteProps {
  children?: JSX.Element[] | JSX.Element;
  size?: string;
}

export default class GateLayout extends React.Component<GateLayoutProps> {
  public onChangeLang = (value: Language) => {
    window.localStorage.setItem('lang', value);
    window.location.reload();
  };
  public render() {
    const lang = window.localStorage.getItem('lang') || 'vi';
    return (
      <Container>
        <Content>
          <LangContainer>
            <Select
              onChange={this.onChangeLang}
              size="small"
              value={lang}
              items={[
                { value: 'en', label: 'EN' },
                { value: 'vi', label: 'VI' },
              ]}
              style={{ minWidth: 60 }}
            />
          </LangContainer>
          <BackBtn>
            <Link to="/">
              <SVGIcon src={BackIcon} />
              <FormattedMessage {...messages.backToHome} />
            </Link>
          </BackBtn>
          <Row justify="center" align="top">
            <ErrorBoundary>{this.props.children}</ErrorBoundary>
          </Row>
          <DownloadAppContainer>
            <DownloadApp />
          </DownloadAppContainer>
        </Content>
        <ChatSupport />
        <Bg>
          <ImageTopLeft src={bg1} />
          <ImageTopRight src={bg2} />
          <ImageBottomLeft src={bg} />
          <ImageBottomRight src={bg3} />
        </Bg>
      </Container>
    );
  }
}
