import gql from 'graphql-tag';

export default gql`
  query orderTotalSummary($orderDateRange: DatetimeRange, $storeId: String!) {
    orderTotalSummary(orderDateRange: $orderDateRange, storeId: $storeId) {
      startTime
      totalAmount
    }
  }
`;
