import gql from 'graphql-tag';

export default gql`
  mutation signImageUpload($resourceGroup: ResourceGroup!, $contentType: String!, $fileName: String!) {
    signImageUpload(resourceGroup: $resourceGroup, contentType: $contentType, fileName: $fileName) {
      success
      userErrors {
        field
        message
      }
      uploadUrl
      fullUrl
    }
  }
`;
