/*
 * OrdersPage Messages
 *
 * This contains all the text for the OrdersPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.OrdersPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the OrdersPage container!',
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'Nhà cung cấp',
  },
  exportCSV: {
    id: `${scope}.exportCSV`,
    defaultMessage: 'Xuất file .csv',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Sản phẩm',
  },
  notes: {
    id: `${scope}.notes`,
    defaultMessage: 'Ghi chú',
  },
  by: {
    id: `${scope}.by`,
    defaultMessage: 'Người mua',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Trạng thái',
  },
  allStatus: {
    id: `${scope}.allStatus`,
    defaultMessage: 'Tất cả trạng thái',
  },
  selectStatus: {
    id: `${scope}.selectStatus`,
    defaultMessage: 'Chọn trạng thái',
  },
  viewDetails: {
    id: `${scope}.viewDetails`,
    defaultMessage: 'Xem chi tiết',
  },

  PENDING_APPROVAL: {
    id: `${scope}.status.PENDING_APPROVAL`,
    defaultMessage: 'Đang chờ duyệt',
  },
  APPROVAL_DISAPPROVED: {
    id: `${scope}.status.APPROVAL_DISAPPROVED`,
    defaultMessage: 'Không duyệt',
  },
  PENDING_ACCEPTANCE: {
    id: `${scope}.status.PENDING_ACCEPTANCE`,
    defaultMessage: 'Chờ chấp nhận',
  },
  ACCEPTED: {
    id: `${scope}.status.ACCEPTED`,
    defaultMessage: 'Đã chấp nhận',
  },
  CANCELED: {
    id: `${scope}.status.CANCELED`,
    defaultMessage: 'Đã huỷ',
  },
  DECLINED: {
    id: `${scope}.status.DECLINED`,
    defaultMessage: 'Đã từ chối',
  },
  SENT: {
    id: `${scope}.status.SENT`,
    defaultMessage: 'Đã gửi email',
  },
  DELIVERED: {
    id: `${scope}.status.DELIVERED`,
    defaultMessage: 'Đã giao',
  },
  IN_PROGRESS: {
    id: `${scope}.status.IN_PROGRESS`,
    defaultMessage: 'Đang xử lý',
  },
  PENDING_DELIVERY: {
    id: `${scope}.status.PENDING_DELIVERY`,
    defaultMessage: 'Chờ giao hàng',
  },
  DELIVERING: {
    id: `${scope}.status.DELIVERING`,
    defaultMessage: 'Đang giao',
  },
  orderTime: {
    id: `${scope}.orderTime`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryTime: {
    id: `${scope}.deliveryTime`,
    defaultMessage: 'Ngày giao hàng',
  },
  declineReason: {
    id: `${scope}.declineReason`,
    defaultMessage: 'Lý do từ chối',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Tổng',
  },
  deliveryToday: {
    id: `${scope}.deliveryToday`,
    defaultMessage: 'Giao hàng hôm nay',
  },
  ordersToday: {
    id: `${scope}.ordersToday`,
    defaultMessage: 'Đơn hàng hôm nay',
  },
  pendingApprovalOrders: {
    id: `${scope}.pendingApprovalOrders`,
    defaultMessage: 'Đơn hàng đang chờ duyệt',
  },
  recentOrders: {
    id: `${scope}.recentOrders`,
    defaultMessage: 'Đơn hàng gần đây',
  },
  activityReport: {
    id: `${scope}.activityReport`,
    defaultMessage: 'Báo cáo hoạt động',
  },
  totalPurchase: {
    id: `${scope}.totalPurchase`,
    defaultMessage: 'Giá trị đã mua',
  },
  orderHistory: {
    id: `${scope}.orderHistory`,
    defaultMessage: 'Đơn hàng',
  },
  allSupplier: {
    id: `${scope}.allSupplier`,
    defaultMessage: 'Tất cả nhà cung cấp',
  },
  selectSupplier: {
    id: `${scope}.selectSupplier`,
    defaultMessage: 'Chọn nhà cung cấp',
  },
  orderDate: {
    id: `${scope}.orderDate`,
    defaultMessage: 'Ngày đặt hàng',
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: 'Ngày giao hàng',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Xoá',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Đồng ý',
  },
  cancelOrder: {
    id: `${scope}.cancelOrder`,
    defaultMessage: 'Huỷ đơn hàng',
  },
  cancelOrderMessage: {
    id: `${scope}.cancelOrderMessage`,
    defaultMessage: 'Bạn có chắc muốn huỷ đơn hàng này?',
  },
  hasNoOrders: {
    id: `${scope}.hasNoOrders`,
    defaultMessage: 'Không có đơn hàng nào',
  },
  totalItems: {
    id: `${scope}.totalItems`,
    defaultMessage: 'Tổng số sản phẩm',
  },
  confirmOrder: {
    id: `${scope}.confirmOrder`,
    defaultMessage: 'Điều chỉnh thực nhận',
  },
  receiveOrder: {
    id: `${scope}.receiveOrder`,
    defaultMessage: 'Đã nhận hàng',
  },
  editOrder: {
    id: `${scope}.editOrder`,
    defaultMessage: 'Thay đổi đơn hàng',
  },
  actualQuantity: {
    id: `${scope}.actualQuantity`,
    defaultMessage: 'Số lượng thực tế',
  },
  editQuantity: {
    id: `${scope}.editQuantity`,
    defaultMessage: 'Cập nhật số lượng',
  },
  orderQuantity: {
    id: `${scope}.orderQuantity`,
    defaultMessage: 'Số lượng đã đặt',
  },
  acceptedQuantity: {
    id: `${scope}.acceptedQuantity`,
    defaultMessage: 'Số lượng đã nhận',
  },
  creator: {
    id: `${scope}.creator`,
    defaultMessage: 'Người đặt hàng',
  },
  storeName: {
    id: `${scope}.storeName`,
    defaultMessage: 'Tên cửa hàng',
  },
  declineOrderMessage: {
    id: `${scope}.declineOrderMessage`,
    defaultMessage: 'Bạn có chắc muốn từ chối đơn hàng này?',
  },
  declineThisOrder: {
    id: `${scope}.declineThisOrder`,
    defaultMessage: 'Không duyệt đơn hàng này',
  },
  confirmDeliveryOrderMessage: {
    id: `${scope}.confirmDeliveryOrderMessage`,
    defaultMessage: 'Vẫn chưa đến thời gian giao hàng, bạn có chắc muốn xác nhận đơn hàng đã được nhận?',
  },
  thisIsReDeliveryOrder: {
    id: `${scope}.thisIsReDeliveryOrder`,
    defaultMessage: 'Đây là đơn hàng được giao bù',
  },
  thisIsSampleOrder: {
    id: `${scope}.thisIsSampleOrder`,
    defaultMessage: 'Đây là đơn hàng mẫu',
  },
  thisIsGiftOrder: {
    id: `${scope}.thisIsGiftOrder`,
    defaultMessage: 'Đây là đơn hàng quà tặng',
  },
});
