import gql from 'graphql-tag';

export default gql`
  query invitation($id: ID!) {
    invitation(id: $id) {
      id
      name
      telephone
      location {
        address
        district
        city
      }
      supplier {
        id
        name
      }
      stores {
        name
        imageUrl
        telephone
        location {
          address
          district
          city
        }
      }
    }
  }
`;
