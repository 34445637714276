import gql from 'graphql-tag';

export default gql`
  mutation buyerUpdate($input: BuyerUpdateInput!) {
    buyerUpdate(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
