import { css } from 'styled-components';

const sizes = {
  // Small screen / phone
  sm: 576,
  // Medium screen / tablet
  md: 768,
  // Large screen / desktop
  lg: 1025,
  // lgr: 1025,
  // Extra large screen / wide desktop
  xl: 1200,
};

// Iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((acc: any, label: string) => {
  if (label === 'sm' || label === 'md' || label === 'lg' || label === 'xl') {
    acc[label] = (f: any, ...args: any[]) => css`
      @media (min-width: ${sizes[label]}px) {
        ${css(f, ...args) as any};
      }
    `;
  }

  return acc;
}, {});
