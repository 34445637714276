import gql from 'graphql-tag';

export default gql`
  query getGroupRTEByCity {
    getGroupRTEByCity {
        total
        city
    }
  }
`;
