import styled from 'styles/styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isHomePage }) =>
    isHomePage &&
    `
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  `}
`;

export default Wrapper;
