import { sortBy } from 'lodash';

export interface Item {
  product: {
    id: string;
    price: number;
    vat: number;
    name: string;
    uom: string;
  };
  quantity: number;
}

export interface FulfillmentTime {
  hoursToDelivery: number;
  days: number;
  cutoffTime: string;
}

export interface Supplier {
  items: Item[];
  total: number;
  supplier: {
    id: string;
    fulfillmentTime: FulfillmentTime;
    moq: number;
    name: string;
    private: boolean;
  };
}

export default (cart, lightCart) => {
  const currentCartSet = cart.cartSet;

  const cartSet: Supplier[] = lightCart.carts.map(lightSupplierCart => {
    const currentSupplier = currentCartSet.find(s => s.supplier.id === Number(lightSupplierCart.supplier.id));

    if (currentSupplier) {
      const items: Item[] = lightSupplierCart.items.map(lightItem => {
        const currentItem = currentSupplier.items.find(item => item.product.id === lightItem.item.id);

        if (currentItem) {
          const product: object = {
            ...currentItem.product,
            ...lightItem.item,
          };

          return {
            ...currentItem,
            product: product,
            quantity: Number(lightItem.orderedQty),
          };
        }

        return {
          product: lightItem.item,
          quantity: Number(lightItem.orderedQty),
        };
      });

      const supplier = {
        ...currentSupplier.supplier,
        ...lightSupplierCart.supplier,
      };

      return {
        ...currentSupplier,
        items: items,
        total: Number(lightSupplierCart.total),
        supplier: supplier,
      };
    }

    const items: Item[] = lightSupplierCart.items.map(lightItem => ({
      product: lightItem.item,
      quantity: Number(lightItem.orderedQty),
    }));

    return {
      ...lightSupplierCart,
      items: items,
      total: Number(lightSupplierCart.total),
    };
  });

  return {
    cartSet: cartSet,
  };
};

export const sortCartSetByProductName = (cartSet = []) =>
  sortBy(
    cartSet.map((singleSupplier: any) => ({
      ...singleSupplier,
      items: sortBy(singleSupplier.items, item => item.product.name.toLowerCase()),
    })),
    cart => cart.supplier.name,
  );
