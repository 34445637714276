import gql from 'graphql-tag';

export default gql`
  query cart($storeId: String!) {
    cart(storeId: $storeId) {
      redInvoiceInfo {
        registeredName
        registeredAddress
        redInvoiceNumber
      }
      deliveryNotes
      cartSet {
        paymentMethod
        deliveryNotes
        orderDeadline
        supplier {
          fulfillmentTime {
            hoursToDelivery
            days
            cutoffTime
          }
          moq
          id
          name
          private
        }
        earliestDate
        deliveryDatetime {
          start
          end
        }
        deliveryTimeSlots {
          start
          end
        }
        total
        items {
          product {
            supplierInternalCode
            id
            name
            imageUrl
            note
            vat
            uom
            uomLocal
            price
            allowPurchase
            inStock
            category {
              name
              parent {
                name
              }
            }
          }
          quantity
        }
      }
    }
  }
`;
