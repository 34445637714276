import gql from 'graphql-tag';

export default gql`
  mutation meUpdateLang($lang: Language!) {
    meUpdateLang(lang: $lang) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
