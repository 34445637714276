import React, { FC, useEffect, useMemo, useState } from "react";
import { Modal } from 'antd';
import styled from 'styled-components';
import Button from '../../components/Button/Button';
import media from 'utils/mediaStyle';
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { ApplicationRootState } from "types";
import isGoogleBot from "utils/urls/isGoogleBot";
import selectHomePage from "containers/HomePage/selectors";
import { ContainerState } from "containers/HomePage/types";
import { IKeyTreatments } from '@splitsoftware/splitio-redux/lib/types';
import { selectTreatmentValueByName } from "split/selectors";
import { TreatmentNames } from "split/constants";
import { IS_USE_STATIC_POPUP } from "containers/HomePage";
import { selectPromotionDataPopup } from "containers/MainLayout/selectors";
import messages from "utils/messages";
import { FormattedMessage } from "react-intl";

const CustomModal = styled(Modal)`
  .ant-modal-content {
    width: 100%;
  }

  ${media.md`
    .ant-modal-content {
      width: 411px;
      margin: 0 auto;
    }
  `}
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TitleModal = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: #242527;
  text-align: center;
`;

const SelectWrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  align-items: center;

`

const DescriptionModal = styled.div`
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; 
  color: #242527;
  text-align: center;
`
const RegionButton = styled(Button)`
  padding: 12px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  flex: 1;
  height: 48px;

  &.selected {
    color: #4b9c63 !important;
    border: 1px solid #4b9c63 !important;
    background-color: #F0F9F1 !important;
  }
`

const ButtonConfirmModal = styled(Button)`
  padding: 12px auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  height: 48px;
`;

interface IStateProps {
  homePage: ContainerState;
  homepagePopupTreatment: string | IKeyTreatments;
  promotionDataPopup: any;
}

const SelectRegionModal: FC<IStateProps> = (props) => {
  const { homePage: { homePagePopup, loadingHomePagePopup }, homepagePopupTreatment, promotionDataPopup } = props;
  const isLogin = window.localStorage.getItem('token');


  const [region, setRegion] = useState('HCM');
  const regionLocal = localStorage.getItem('region');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const blockHomePagePopup = window.localStorage.getItem('blockHomePagePopup') || {};

  const popup = useMemo(() => {
    if (homePagePopup && 'key' in homePagePopup) {
      return homePagePopup;
    }
    return undefined;
  }, [homePagePopup]);


  useEffect(() => {
    if (!loadingHomePagePopup && !(
      !isGoogleBot() &&
      ((popup && !blockHomePagePopup[popup.key] && homepagePopupTreatment === 'on') ||
        (!isLogin && promotionDataPopup && IS_USE_STATIC_POPUP))
    )) {
      setIsPopupVisible(true);
    }
  }, [blockHomePagePopup, popup, homepagePopupTreatment, isLogin, promotionDataPopup]);

  const onChange = (value) => {
    setRegion(value);
  }

  const onConfirm = () => {
    window.localStorage.setItem('region', region);
    window.location.reload();
  }

  return (
    <CustomModal open={isPopupVisible && !regionLocal} closable={false} footer={false} centered>
      <ModalWrapper center={true}>
        <TitleModal>
          <FormattedMessage {...messages.selectRegionToViewPrices} defaultMessage="Chọn khu vực để xem giá" />
        </TitleModal>
        <DescriptionModal>
          <FormattedMessage {...messages.getTheBestPriceForYourBusiness} defaultMessage="Nhận giá tốt nhất cho doanh nghiệp của bạn" />
        </DescriptionModal>
        <SelectWrapper>
          <RegionButton className={region === 'HN' && 'selected'} onClick={() => onChange('HN')}>
            <FormattedMessage {...messages.northern} defaultMessage="Miền Bắc" />
          </RegionButton>
          <RegionButton className={region === 'HCM' && 'selected'} onClick={() => onChange('HCM')}>
            <FormattedMessage {...messages.southern} defaultMessage="Miền Nam" />
          </RegionButton>
        </SelectWrapper>
        <ButtonConfirmModal
          type="bg-primary"
          onClick={onConfirm}
        >
          <FormattedMessage {...messages.continue} defaultMessage="Tiếp tục" />
        </ButtonConfirmModal>
      </ModalWrapper>
    </CustomModal>
  )
}

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  homePage: selectHomePage(),
  homepagePopupTreatment: selectTreatmentValueByName(TreatmentNames.BUYER_WEB_HOME_PAGE_POPUP),
  promotionDataPopup: selectPromotionDataPopup(),
});


const withConnect = connect(mapStateToProps);

export default withConnect(SelectRegionModal);
