import gql from 'graphql-tag';

export default gql`
  mutation userUpdate($input: UserUpdateInput) {
    userUpdate(input: $input) {
      success
      userErrors {
        field
        message
      }
      user {
        id
        name
        email
        status
        telephone
        imageUrl
        permissions {
          action
        }
        userGroups {
          groupId
          name
          permissions {
            action
          }
        }
      }
    }
  }
`;
