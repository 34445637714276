import Next from './images/Next.svg';
import Prev from './images/Prev.svg';
import MavenProRegularLatin from 'fonts/mavenpro-regular-latin.woff2';
import MavenProRegularLatinExt from 'fonts/mavenpro-regular-latin-ext.woff2';
import MavenProRegularVietnamese from 'fonts/mavenpro-regular-vietnamese.woff2';
import MavenProMediumLatin from 'fonts/mavenpro-medium-latin.woff2';
import MavenProMediumLatinExt from 'fonts/mavenpro-medium-latin-ext.woff2';
import MavenProMediumVietnamese from 'fonts/mavenpro-medium-vietnamese.woff2';
import MavenProBoldLatin from 'fonts/mavenpro-bold-latin.woff2';
import MavenProBoldLatinExt from 'fonts/mavenpro-bold-latin-ext.woff2';
import MavenProBoldVietnamese from 'fonts/mavenpro-bold-vietnamese.woff2';
import { createGlobalStyle } from 'styles/styled-components';
import { createFontStack } from '@capsizecss/core';

const mavenProMetrics = {
  familyName: 'Maven Pro Fallback',
  fullName: 'Maven Pro Medium',
  postscriptName: 'MavenPro-Medium',
  category: 'sans-serif',
  capHeight: 667,
  ascent: 965,
  descent: -210,
  lineGap: 0,
  unitsPerEm: 1000,
  xHeight: 499,
  xWidthAvg: 472,
  subsets: {
    latin: {
      xWidthAvg: 472,
    },
    thai: {
      xWidthAvg: 500,
    },
  },
};

const { fontFaces, fontFamily } = createFontStack([mavenProMetrics], {
  fontFaceProperties: {
    fontDisplay: 'swap',
  },
});

const GlobalStyle = createGlobalStyle`
*,
::after,
::before {
  box-sizing: border-box;
}
//** Regular weight */
/* vietnamese */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Maven Pro Regular'), local('MavenPro-Regular'), url(${MavenProRegularVietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Maven Pro Regular'), local('MavenPro-Regular'), url(${MavenProRegularLatinExt}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

 /* latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Maven Pro Regular'), local('MavenPro-Regular'), url(${MavenProRegularLatin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//** Medium weight */
/* vietnamese */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Maven Pro Medium'), local('MavenPro-Medium'), url(${MavenProMediumVietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Maven Pro Medium'), local('MavenPro-Medium'), url(${MavenProMediumLatinExt}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Maven Pro Medium'), local('MavenPro-Medium'), url(${MavenProMediumLatin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

//** Bold weight */
/* vietnamese */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Maven Pro Bold'), local('MavenPro-Bold'), url(${MavenProBoldVietnamese}) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Maven Pro Bold'), local('MavenPro-Bold'), url(${MavenProBoldLatinExt}) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Maven Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Maven Pro Bold'), local('MavenPro-Bold'), url(${MavenProBoldLatin}) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

${fontFaces}

body {
  margin: 0;
  padding: 0;
  font-family: 'Maven Pro', 'Arial', sans-serif, ${fontFamily};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(0, 0, 0, 0.87);
  line-height: 18px;
  font-size: 14px;
  font-weight: 500;
  height: calc(100% - 40pt);
  background: #f9fafc;
}
.hideScroll {
  overflow: hidden;
}

pre {
  white-space: pre-line;
  font-family: 'Maven Pro', 'Arial', sans-serif;
}

#root {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0,0,0,0.87);
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

p {
  margin: 0;
}

a:hover {
  color: rgba(0, 0, 0, 0.87);
}

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  background: #2e904e;
  box-shadow: 1px 1px 4px rgba(4, 15, 15, 0.25);
}

.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  display: none !important;
}

.ant-calendar-header .ant-calendar-prev-month-btn {
  left: 16px;
}

.ant-calendar-header .ant-calendar-prev-month-btn::after {
  content: '' !important;
  background-image: url(${Prev});
  width: 14px;
  height: 14px;
  position: absolute;
  top: 12px;
}

.ant-calendar-header .ant-calendar-next-month-btn::after {
  content: '' !important;
  background-image: url(${Next});
  width: 14px;
  height: 14px;
  position: absolute;
  top: 12px;
}

.ReactModalPortal > div {
  z-index: 1049;
}
.ant-input-search-large {
  .anticon {
    font-size: 20px;
  }
}
.ant-btn {
  font-family: 'Maven Pro', 'Arial', sans-serif;
  padding: 0 20px;
}
.ant-btn-circle {
  padding: 0;
}

.hideOnMobile {
  @media screen and (max-width: 575px)  {
    display: none;
  }
}

.hideOnDesktop {
  @media screen and (min-width: 575px)  {
    display: none;
  }
}

.hideOnSmall {
  @media screen and (max-width: 1025px)  {
    display: none;
  }
}
thead.ant-table-thead>tr>th, tbody.ant-table-tbody>tr>td {
  @media screen and (max-width: 1025px)  {
    padding: 10px;
  }
}
.showOnSmall.showOnSmall.showOnSmall {
  @media screen and (min-width: 1025px)  {
    display: none;
  }
}
.ant-form-explain, .ant-form-extra {
  margin-top: 6px;
  margin-bottom: 6px;
}
.ant-menu-item-selected > a:hover {
  color: #2F904F;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}
.ant-menu-item-selected > a {
  color: unset;
}
.ant-menu-item-selected {
  color: unset;
}

.ant-tooltip-inner {
  padding: 17px 14px;
  padding-right: 34px;
}

.ant-calendar-header.ant-calendar-header a:hover {
  color: #008fe5;
}
.ant-calendar-header .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
}
.ant-form-item-label {
  text-transform: capitalize;
}
.ant-menu-submenu-title.ant-menu-submenu-title.ant-menu-submenu-title {
  margin-top: 0;
  margin-bottom: 0;
}

.ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 29px;
}

.ant-skeleton-paragraph {
  padding: 0;
  margin-bottom: 0;
}
.card-skeleton {
  .ant-skeleton-avatar {
    width: 100% !important;
    height: 160px !important;
  }
  .ant-skeleton-header {
    padding: 0;
  }
}
.ant-form-explain, .ant-form-extra {
  margin-bottom: 11px !important;
  margin-top: 0 !important;
}
.ant-popover-inner-content.ant-popover-inner-content {
  padding: 12px;
}
.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
  padding-left: 34px;
}
.ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: white !important;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-card-body.ant-card-body {
  padding: 8pt;
}
/* .ant-table-tbody.ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
  background: transparent;
}
.ant-table-tbody>tr:hover:not(.ant-table-expanded-row) {
  transition: all 0.3s ease;
  background-color: #f0f8fb;
}
.ant-table-thead.ant-table-thead > tr > th {
  border-bottom: none;
}
.ant-table-row-level-0.ant-table-row-level-0 {
  border-top: 1px solid #e8e8e8;
  &:hover {
    border-top-color: #2e904e;
  }
}
.ant-table-thead.ant-table-thead > tr > th, .ant-table-tbody.ant-table-tbody > tr > td {
  padding: 6pt;
} */

.ant-card {
  box-shadow: 4px 4px 8px rgba(4,15,15,0.12);
  border: none;
  &:hover {
    background-color: #f0f8fb;
  }
}

.ant-menu-vertical .ant-menu-item.ant-menu-item {
  margin: 0;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeText {
  0% {
    transform: translateY(10px);
    opacity: 0.2
  }
  10% {
    transform: translateY(0px);
    opacity: 0.8;
  }
  20% {
    transform: translateY(0px);
    opacity: 1;
  }
  40% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(5px);
    opacity: 0;
  }
}

.ant-menu-item > a:hover {
  color: #2F904F;
}
.ant-dropdown-menu.ant-dropdown-menu {
  padding: 0;
}

.ant-dropdown-menu.dropdown-language {
  position: relative;
  right: 10px;
  top: 5px;
  padding: 4px 0;
}

.ant-btn .anticon.anticon-plus>svg, .ant-btn .anticon.anticon-minus>svg {
  shape-rendering: auto;
}
.ant-btn-circle, .ant-btn-circle-outline {
  min-width: 20pt;
}

.speech-bubble {
  --background: #3B8FDE;
  --paddingsides: max(1.5vw, 20px);
  --paddingtopbottom: max(1vw, 10px);
  --radius: max(0.5vw, 5px);
  background-color: var(--background)!important;
  position: absolute;
  overflow: unset !important;
  & .ant-notification-notice-message {
    color: white!important
  }
  & .ant-notification-notice-close {
    color: white!important
  }
}

.speech-bubble::after {
  --h: max(0.75vw, 12px);
  height: var(--h);
  width: var(--h);
  background: linear-gradient(to bottom right,transparent 0%,transparent 50%,var(--background) 50%,var(--background) 100%)!important;
  top: calc(var(--h) * -1);;
  left: 120px;
  position: absolute;
  content: ""
}
.ant-dropdown.root-class.ant-dropdown-placement-bottomCenter {
  left: 0 !important;
  position: fixed;
  width: 100%;
  ul {
    width: 100%;
  }
}
.ant-affix {
  z-index: 1000 !important;
  top: 105px !important;
}
.tab-pages-wrapper  {
  margin: 10px 0 !important;
  margin-top: 0px !important;
  div[role="tablist"]{
    border-bottom: unset !important;
    div[role="tab"] {
      font-size: 20px;
      font-weight: 600;
      padding: 8px 16px !important;
      &.ant-tabs-tab-active {
        color: #43A047 !important;
        font-weight: 600;
        font-size: 20px;
      }
    }
    .ant-tabs-ink-bar {
      background: #43A047 !important;
    }
  }
}
.zalo-chat-widget {
  @media(max-width: 992px) {
    bottom: 100px !important;
    right: 16px !important;
  }
}
@media(max-width: 992px) {
  .fb_dialog .fb_dialog_content {
    iframe[data-testid="bubble_iframe"] {
      bottom: 95px !important;
    }
    iframe[data-testid="availabilityStatus_iframe"] {
      bottom: 92px !important;
    }
  }
}
a {
  text-decoration: none;
  color: #40A9FF;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: #4b9c63 !important;
}
.ant-input-affix-wrapper,
.ant-input {
  border-radius: 8px !important;
}
.ant-input-prefix ~ input {
  padding-left: 0px !important;
}
.ant-form-item-label {
  padding-bottom: 0px !important;
}
.ant-spin .ant-spin-dot-item {
  background-color: #4b9c63;
}
.ant-form-item-control.has-success,
.ant-form-item-control.has-error {
  #acquisitionSource > .ant-select-selection > .ant-select-arrow {
   margin-right: 5px;
 }
}
.ant-form-large .ant-form-item .ant-form-item-label>label {
  height: 25px !important;
}
@media(max-width: 576px) {
  .ant-picker-range-wrapper {
    .ant-picker-panels {
      flex-direction: column;
    }
  }
}
textarea {
  font-family: 'Maven Pro' !important;
}
.ratio-box {
	position: relative;
  margin-inline: auto;
  max-width: 800px;
  display: block;
}

.ratio-box:after {
  content: '';
  display: block;
  height: 0;
  width: 100%;
  padding-bottom: var(--box-pb, 62.5%);
}

.ratio-box img,
.ratio-box iframe,
.ratio-box video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	display: block;
}
`;
export default GlobalStyle;
