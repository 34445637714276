import gql from 'graphql-tag';

export default gql`
  query OrderStatistics(
    $storeId: String!
    $countStart: String!
    $countEnd: String!
    $aggregateStart: String!
    $aggregateEnd: String!
  ) {
    orders(pagination: { page: 0, size: 10 }, filter: { storeId: $storeId }) {
      totalPage
      totalResults
      data {
        id
        supplier {
          id
          name
        }
        creator {
          id
          name
        }
        orderDate
        status
        statusText
        total
        deliveryTime {
          start
          end
        }
      }
    }

    orderTotalSummary(storeId: $storeId, orderDateRange: { start: $aggregateStart, end: $aggregateEnd }) {
      startTime
      totalAmount
    }

    orderCountSummary(storeId: $storeId, dateRange: { start: $countStart, end: $countEnd }) {
      orderedWithinPeriod
      deliverWithinPeriod
      pending
      pendingApproval
    }
  }
`;
