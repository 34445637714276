import gql from 'graphql-tag';

export default gql`
  query notificationMessages($pagination: Pagination!) {
    notificationMessages(pagination: $pagination) {
      totalPage
      totalResults
      data {
        id
        readStatus
        messageType
        title
        content
        createdAt
        updatedAt
        data {
          type
          orderId
          navType
          navValue
        }
      }
    }
  }
`;
