import gql from 'graphql-tag';

export default gql`
  query cartGetAvailableDeliveryTimeSlots($supplierId: Int!, $start: String!, $end: String!) {
    cartGetAvailableDeliveryTimeSlots(supplierId: $supplierId, start: $start, end: $end) {
      date
      timeSlots {
        start
        end
      }
    }
  }
`;
