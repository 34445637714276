import gql from 'graphql-tag';

export default gql`
  query buyer {
    buyer {
      name
      id
      telephone
      status
      imageUrl
      region
      isFreeshipOffer
      location {
        address
        ward
        district
        city
      }
      stores {
        id
        name
        imageUrl
        telephone
        location {
          address
          ward
          district
          city
        }
        lastOrderedAt
        disabled
        status
      }
      redInvoiceInfo {
        companyName
        location {
          address
          district
          city
        }
        mst
      }
      userGroups {
        name
        groupId
        permissions {
          action
        }
        members {
          email
          name
        }
      }
      enabledFeatures
      fullAddress {
        provinceCode
        districtCode
        wardCode
        provinceFullName
        districtFullName
        wardFullName
        kmrRegion
      }
    }
  }
`;
