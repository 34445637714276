import gql from 'graphql-tag';

export default gql`
  query homePagePopup {
    homePagePopup {
      ... on GoToScreenPopup {
        key
        type
        imageUrl
        screen {
          ... on CategoryScreen {
            name
            title
            filter {
              supplierNames
              categoryName
              tags
              query
            }
            sort {
              field
              order
            }
          }
        }
      }
    }
  }
`;
