import isValidBase64 from './isValidBase64';
import isValidImageURL from './urls/isValidImageURL';
import { Buffer } from 'buffer';

export interface OriginalImageData {
  bucket: string;
  key: string;
}

export interface ResponsiveImageData extends OriginalImageData {
  edits: {
    resize: {
      width: number;
      height: number;
      fit: string;
    };
  };
}

export interface URLData extends URL {
  base64String?: string;
  decoded64Object?: {
    bucket: string;
    key: string;
  };
}

export function getImageBucketOrigin(env?: string) {
  const isProd = env === 'production';
  return {
    bucket: `image-handler-${isProd ? 'prod' : 'qa'}`,
    origin: `https://images-handler.${isProd ? '' : 'qa.'}kamereo.vn`,
  };
}

function isImagefromSupplier(pathname: string) {
  const imageReg = new RegExp(/supplier\/\d+\/PRODUCT_IMAGE\/[\d\w-]+\.(jpe?g|png)/);
  return imageReg.test(pathname);
}

export const getURLData = (url: string): Partial<URLData> => {
  if (isValidImageURL(url)) {
    const imageURL = new URL(url);
    const imagePathname = imageURL?.pathname.slice(1);
    if (isImagefromSupplier(imagePathname)) {
      const appEnv = process.env.APP_ENV !== 'production' ? 'qa' : 'production';
      const { bucket, origin } = getImageBucketOrigin(appEnv);
      const decoded64Object = {
        bucket: bucket,
        key: imagePathname,
      };

      return { decoded64Object: decoded64Object, origin: origin };
    }
    return { base64String: imagePathname, origin: imageURL.origin };
  }
  return {};
};

function isValidJson(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export const decodeBase64 = (dataString: string): OriginalImageData | undefined => {
  if (!isValidBase64(dataString)) {
    return;
  }
  const decodedString = Buffer.from(dataString, 'base64').toString('utf-8');
  let imageDataObject: OriginalImageData;
  imageDataObject = isValidJson(decodedString) ? JSON.parse(decodedString) : undefined;
  return imageDataObject;
};

export const encodeBase64 = (data: ResponsiveImageData): string => {
  const jsonString = JSON.stringify(data);
  const base64String = Buffer.from(jsonString).toString('base64');
  return base64String;
};

const generateResponsiveImage = (url: string, width: number, height: number): string => {
  const { decoded64Object, base64String, origin } = getURLData(url);
  if (origin === undefined) {
    return url;
  }
  const imageDataObject = decoded64Object ? decoded64Object : base64String && decodeBase64(base64String);
  if (imageDataObject) {
    const responsiveImageData: ResponsiveImageData = {
      ...imageDataObject,
      edits: {
        resize: {
          width: width,
          height: height,
          fit: 'fill',
        },
      },
    };

    const responsiveImageURL = `${origin}/${encodeBase64(responsiveImageData)}`;
    return responsiveImageURL;
  }
  return url;
};

export default generateResponsiveImage;
