import gql from 'graphql-tag';

export default gql`
  query listSalesPic($salesIds: [String]!) {
    listSalesPic(salesIds: $salesIds) {
      data {
        id
        name
        phoneNumber
      }
    }
  }
`;
