import gql from 'graphql-tag';

export const FRAGMENT_ORDER = gql`
  fragment order on Order {
    id
    total
    items {
      total
      price
      note
      orderedPrice
      product {
        id
        name
        note
        price
        vat
        uom
        uomLocal
        inStock
      }
      deliveryQuantity
      orderedQuantity
      deliveredNote

      buyerNote
    }
    orderDate
    deliveryTime {
      start
      end
    }
    status
    statusText
    note
    buyer {
      id
      name
    }
    store {
      id
      name
    }
    supplier {
      id
      name
      imageUrl
      location {
        address
        district
        city
      }
    }
    creator {
      id
      name
    }
    paymentMethod
    redelivery
  }
`;

export default gql`
  mutation orderReceive($id: ID!) {
    orderReceive(id: $id) {
      userErrors {
        field
        message
      }
      success
      order {
        ...order
      }
    }
  }

  ${FRAGMENT_ORDER}
`;
