import gql from 'graphql-tag';

export default gql`
  mutation meResetPassword($password: String!, $token: String!, $key: String!) {
    meResetPassword(password: $password, token: $token, key: $key) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
