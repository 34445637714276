import { Button } from 'antd';
import styled from 'styled-components';

export default styled(Button)<any>`
  ${({ type, ghost, width }) => {
    if (width) {
      return `
        width: ${width};
      `;
    }
    if (type === 'bg-primary') {
      return `
        background-color: #2F904F;
        border-color: ##2F904F;
        color: white;
      `;
    }
    if (type === 'primary') {
      return `
          background-color: transparent;
          border-color: #52C41A;
          color: #2F904F;
          box-shadow: none;
          transition: notset;
        `;
    }
    if (type === 'secondary') {
      if (ghost) {
        return `
          background-color: transparent;
          border-color: transparent;
          color: rgba(0, 0, 0, 0.54);
          box-shadow: none;
          transition: notset;
          :hover, :active, :focus {
            background-color: transparent;
            border-color: transparent;
            color: rgba(0, 0, 0, 0.54);
          }
          ::after {
            animation: notset;
          }
        `;
      }
      return `
        color: rgba(0, 0, 0, 0.87);
        :hover, :active, :focus {
          background-color: #d8dde9;
          color: rgba(0, 0, 0, 0.87);
        }
      `;
    }
    if (type === 'link') {
      return `
        background-color: transparent;
        border-color: transparent;
        color: #008fe5;
        box-shadow: none;
        padding: 0;
        :hover, :active, :focus {
          background-color: transparent;
          border-color: transparent;
          color: #008fe5;
        }
      `;
    }
    return ``;
  }};
`;
