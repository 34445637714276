import parseSlug from 'utils/parseSlug';
// import categoriesSlug from '../../../categoriesSlug/categoriesSlug.json';
import { suffixCategoriesRegexp } from './constants';

interface CategoriesSlug {
  [key: string]: {
    queryName: string,
    parent: number,
    name: {
      [key: string]: string
    }
  },
}

export const getCategoriesSlug = (): CategoriesSlug => {
  return (window as any).__PRELOADED_STATE__?.categoriesSlug || {};
};

export const getQueryNameFromSlug = (categoryID: number) => {
  if (!categoryID) return '';
  const categoriesSlug = getCategoriesSlug();
  const targetCategory = categoriesSlug?.[categoryID];
  return targetCategory ? targetCategory.queryName : '';
};

export const getCategoryIDFromSlug = (slug: string) => {
  const categoryCodeName = `-${parseSlug(slug)}`;
  const codename = categoryCodeName?.match(suffixCategoriesRegexp.categories.codename)?.[0];
  const categoryID = codename ? parseInt(codename.slice(2), 10) : 0;
  return categoryID;
};

export const generateSlugFromID = (categoryID: number, lang: string) => {
  if (!categoryID) return '';
  const categoriesSlug = getCategoriesSlug();
  const targetCategory = categoriesSlug?.[categoryID];
  return targetCategory ? targetCategory.name[lang] : '';
};

export const generateSlugFromQueryName = (queryName: string, lang: string) => {
  const categoriesSlug = getCategoriesSlug();
  const targetCategory = Object.values(categoriesSlug).find((item) => item.queryName === queryName);
  if (targetCategory) {
    return targetCategory?.name[lang];
  }
  return '';
};
