import gql from 'graphql-tag';

export default gql`
  query widgets($regions: [String]) {
    widgets(regions: $regions) {
      ... on CarouselBannerWidget {
        type
        widgetId
        banner {
          items {
            ... on GoToScreenBannerItem {
              imageUrl
              type
              screen {
                ... on CategoryScreen {
                  name
                  title
                  filter {
                    supplierNames
                    tags
                  }
                  sort {
                    field
                    order
                  }
                }
              }
            }
            ... on ShowWebViewBannerItem {
              type
              imageUrl
              title
              url
            }
            ... on ShowPopupBannerItem {
              type
              popupContent
              imageUrl
              popupImageUrl
              title
              buttonText
              buttonAction {
                ... on PopupGoToScreen {
                  name
                  title
                  filter {
                    supplierNames
                    tags
                    query
                  }
                }
                ... on ClosePopupModal {
                  type
                }
              }
            }
          }
        }
      }
      ... on ShowcaseWidget {
        type
        widgetId
        item {
          key
          title
          backgroundImageId
          sort {
            field
            order
          }
          filter {
            supplierNames
            categoryName
            tags
            limitedTimeOfferPeriod {
              start
              end
            }
          }
        }
      }
      ... on RecommendationWidget {
        type
        widgetId
        item {
          key
          title
        }
      }
    }
  }
`;
