const color = {
  primary: '#4b9c63',
};
export const theme = {
  components: {
    Button: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryActive: color.primary,
      controlOutline: 'rgba(46,144,78,0.2)',
    },
    Select: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      controlOutline: 'rgba(46,144,78,0.2)',
    },
    Input: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      controlOutline: 'rgba(46,144,78,0.2)',
    },
    Tabs: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryActive: color.primary,
    },
    Dropdown: {
      colorPrimary: color.primary,
      // backgroundColorPrimaryActive: color.primary,
      colorPrimaryActive: color.primary,
      controlItemBgActive: 'rgba(66, 66, 66, 0.05)',
      controlItemBgActiveHover: 'rgba(0, 0, 0, 0.06)',
    },
    DatePicker: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryFocus: color.primary,
      controlOutline: 'rgba(46,144,78,0.2)',
    },
    Radio: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryFocus: color.primary,
    },
    Pagination: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
    },
    Switch: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryActive: color.primary,
    },
    Checkbox: {
      colorPrimary: color.primary,
      colorPrimaryHover: color.primary,
      colorPrimaryFocus: color.primary,
    },
    List: {
      titleMarginBottom: 0,
    },
  },
  token: {
    fontFamily: 'Maven Pro',
  },
};
