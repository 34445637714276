import gql from 'graphql-tag';

export default gql`
  query wardSearch(
    $pagination: Pagination!
    $wardCodes: [String]
    $districtCodes: [String]
    $orderBy: [OrderBy]
  ) {
    wardSearch(
      pagination: $pagination
      wardCodes: $wardCodes
      districtCodes: $districtCodes
      orderBy: $orderBy
    ) {
      total
      data {
        code
        name
        nameEn
        fullName
        fullNameEn
        districtCode
      }
    }
  }
`;