import React, { Suspense } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import MainLayout from '../containers/MainLayout';
import LoadingIndicator from 'components/LoadingIndicator';

interface MainLayoutProps extends RouteProps {
  component: any;
  redirect?: string;
  isAuth?: boolean;
  requiredAuthorize?: boolean;
}

export default function ({ component: Component, redirect = '/signin', isAuth = true, ...rest }: MainLayoutProps) {
  const loggedIn = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={(matchProps) => {
        if (!isAuth || loggedIn) {
          matchProps.location.state = { redirectPath: null };
          return (
            <MainLayout>
              <Suspense fallback={<LoadingIndicator />}>
                <Component {...matchProps} />
              </Suspense>
            </MainLayout>
          );
        }
        return (
          <Redirect
            to={{
              pathname: redirect,
              state: { referrer: matchProps.location, redirectPath: redirect },
            }}
          />
        );
      }}
    />
  );
}
