import gql from 'graphql-tag';

export default gql`
  query categories($supplierName: String, $isFavorite: Boolean) {
    categories(supplierName: $supplierName, isFavorite: $isFavorite) {
      id
      nameLocal
      name
      children {
        id
        nameLocal
        name
      }
    }
  }
`;
