export interface PermissionGroup {
  id: string;
  title: string;
  description: string;
  permissions: string[];
}

export const viewPermissions = {
  '/favorite': ['BUYER_FAVORITE_ITEM_LIST'],
  '/group-management': ['BUYER_GROUP_LIST'],
  '/groups/:slug': ['BUYER_GROUP_LIST'],
  '/user-management': ['BUYER_USER_LIST'],
  '/user-details/:slug': ['BUYER_USER_LIST'],
  '/orders': ['ORDER_LIST'],
  '/orders-pending-approval': ['ORDER_APPROVE'],
  '/pending-orders-history': ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_PLACE'],
  '/orders/:slug': ['ORDER_VIEW'],
  '/checkout': ['ORDER_PLACE'],
  '/checkout/step/1': ['ORDER_PLACE'],
  '/checkout/step/2': ['ORDER_PLACE'],
  '/checkout/step/3': ['ORDER_PLACE'],
};

const lang = window.localStorage.getItem('lang');

export const permissionGroups = [
  {
    id: 'createOrder',
    title: lang === 'vi' ? 'Tạo Đơn Hàng' : 'Create Order',
    description:
      lang === 'vi'
        ? 'Chọn sản phẩm vào giỏ hàng và thực hiện 3 bước để tạo đơn hàng'
        : 'Add items into Cart and proceed 3 steps to create an order',
    permissions: ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_PLACE'],
  },
  {
    id: 'approveOrderRequest',
    title: lang === 'vi' ? 'Duyệt Đơn Hàng' : 'Approve Order Request',
    description:
      lang === 'vi'
        ? 'Duyệt, chỉnh sửa và từ chối Yêu cầu đặt hàng'
        : 'Approve, edit and decline purchase order request',
    permissions: ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_APPROVE'],
  },
  {
    id: 'cancelOrder',
    title: lang === 'vi' ? 'Huỷ Đơn Hàng' : 'Cancel Order',
    description:
      lang === 'vi'
        ? 'Thực hiện lệnh huỷ với những đơn hàng Nhà Cung Cấp chưa chấp nhận'
        : 'Cancel the order that hasn’t been accepted by suppliers',
    permissions: ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_CANCEL'],
  },
  {
    id: 'confirmDeliveredOrder',
    title: lang === 'vi' ? 'Xác Nhận Đã Nhận Hàng' : 'Confirm Delivered Order',
    description:
      lang === 'vi'
        ? 'Thay đổi trạng thái đơn hàng từ Đã gửi/Đã chấp nhận thành Đã giao'
        : 'Change the order status from Sent/Accepted to Delivered',
    permissions: ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_DELIVERY_CONFIRM'],
  },
  {
    id: 'updateActualReceivingInfo',
    title: lang === 'vi' ? 'Cập Nhật Thực Nhận' : 'Update Actual Receiving Info',
    description:
      lang === 'vi'
        ? 'Cập nhật giá và số lượng của sản phẩm nhận thực tế'
        : 'Update actual received price and quantity',
    permissions: ['ORDER_LIST', 'ORDER_VIEW', 'ORDER_ITEM_SET_PRICE', 'ORDER_ITEM_SET_QUANTITY'],
  },
  {
    id: 'setUpAccount',
    title: lang === 'vi' ? 'Cài Đặt Tài Khoản' : 'Set Up Account',
    description: lang === 'vi' ? 'Cập nhật, điều chỉnh mục Cài đặt Công ty' : 'Make update for company settings',
    permissions: ['BUYER_FEATURE_TOGGLE', 'BUYER_INFO_EDIT'],
  },
  {
    id: 'manageUserGroup',
    title: lang === 'vi' ? 'Phân Quyền Người Dùng & Nhóm' : 'Manage User & Group',
    description:
      lang === 'vi'
        ? 'Mời người dùng, tạo và cấp quyền sử dụng cho cá nhân và nhóm người dùng'
        : 'Invite, create and select permissions for users and groups',
    permissions: ['BUYER_USER_ADD', 'BUYER_USER_LIST', 'BUYER_GROUP_ADD', 'BUYER_GROUP_EDIT', 'BUYER_GROUP_LIST'],
  },
  {
    id: 'manageFavoriteSection',
    title: lang === 'vi' ? 'Quản Lí Mục Yêu Thích' : 'Manage Favorite Section',
    description:
      lang === 'vi'
        ? 'Thêm và xoá sản phẩm yêu thích/ thường mua trong mục Yêu thích'
        : 'Add and remove frequently bought and favourite items in Favourite Section',
    permissions: ['BUYER_FAVORITE_ITEM_ADD'],
  },
  {
    id: 'viewFavoriteItems',
    title: lang === 'vi' ? 'Xem Mục Yêu Thích' : 'View Favorite Items',
    description: lang === 'vi' ? 'Xem các sản phẩm trong mục Yêu thích' : 'View items in Favourite Section',
    permissions: ['BUYER_FAVORITE_ITEM_LIST'],
  },
];

export const convertPermissionsToPermissionGroups = (permissions: string[]) => {
  const permissionResult: PermissionGroup[] = [];

  for (const permissionGroup of permissionGroups) {
    let permissionCounter = 0;

    for (const permission of permissionGroup.permissions) {
      if (permissions.find(p => p === permission)) {
        permissionCounter += 1;
      }
    }

    if (permissionCounter === permissionGroup.permissions.length) {
      permissionResult.push(permissionGroup);
    }
  }

  return permissionResult;
};

export const getPermissionsActive = (permissions: string[], selectedPermissions) =>
  permissions.map(permission => selectedPermissions.includes(permission)).every(i => i);

export default {
  ORDER_PLACE: 'Create order',
  ORDER_ITEM_SET_PRICE: 'Update price',
  ORDER_ITEM_SET_QUANTITY: 'Update quantity',
  ORDER_APPROVE: 'Approve order',
  ORDER_ACCEPT: 'Accept order',
  ORDER_CANCEL: 'Cancel order',
  ORDER_DELIVERY_CONFIRM: 'Confirm delivery order',
  ORDER_VIEW: 'View order',
  ORDER_LIST: 'View orders',

  BUYER_FAVORITE_ITEM_ADD: 'Add favorite item',
  BUYER_FAVORITE_ITEM_LIST: 'View favorite items',
  BUYER_USER_ADD: 'Add user',
  BUYER_USER_LIST: 'View users',
  BUYER_GROUP_ADD: 'Create group',
  BUYER_GROUP_EDIT: 'Edit group',
  BUYER_GROUP_LIST: 'View groups',

  SUPPLIER_EXCLUSIVE_PRICE_SET: 'Set exclusive price',
  SUPPLIER_DELIVERY_SCHEDULE_SET: 'Set delivery schedule',
};
