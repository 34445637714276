import React from 'react';
import { Select as ASelect } from 'antd';
import styled from 'styled-components';
import media from 'utils/mediaStyle';
import ArrowDownIcon from './ArrowDown.svg';
import SVGIcon from '../SVGIcon/SVGIcon';

const IconContainer = styled.div<any>`
  margin-top: 2px;
  ${({ type }) => {
    if (type === 'secondary-2') {
      return `
        ${SVGIcon} {
          path {
            fill: white;
          }
        }
      `;
    }
    return ``;
  }};
`;

export const ArrowDown = ({ type }: { type?: any }) => (
  <IconContainer type={type}>
    <SVGIcon renderAsImg={false} src={ArrowDownIcon} />
  </IconContainer>
);

export const StyledSelect = styled(ASelect)<any>`
  min-width: 100pt;
  ${media.sm}
  ${({ type }) => {
    if (type === 'secondary-2') {
      return `
        .ant-select-selection {
          background-color: #008fe5;
          border-color: #008fe5;
          color: white;
        }
      `;
    }
    return ``;
  }};

  .ant-select-selection-placeholder {
    font-weight: 400;
  }
`;

const StyledSpan = styled.span<any>`
  .ant-select-selection__rendered & {
    ${({ color }) => `
      box-shadow: ${color} 0px 0px 0px 197px;
      background: ${color};
      color: white;
      padding-right: 20px;
      padding-left: 10px;
    `};
  }
`;

export interface SelectItem {
  value: any;
  label: any;
  color?: string;
}

interface Props {
  type?: string;
  items: SelectItem[];
  [key: string]: any;
}

const Select: React.FC<Props> = (props: Props) => {
  return (
    <StyledSelect {...props} suffixIcon={<ArrowDown type={props.type} />}>
      {props.items.map((item) => (
        <ASelect.Option key={item.label} value={item.value}>
          {item.color ? <StyledSpan color={`${item.color}`}>{item.label}</StyledSpan> : item.label}
        </ASelect.Option>
      ))}
    </StyledSelect>
  );
};

export default Select;
