import gql from 'graphql-tag';

export default gql`
  query provinceSearch($regions: [String], $pagination: Pagination!, $provinceCodes: [String], $orderBy: [OrderBy]) {
    provinceSearch(regions: $regions, pagination: $pagination, provinceCodes: $provinceCodes, orderBy: $orderBy) {
      total
      data {
        code
        name
        nameEn
        fullName
        fullNameEn
      }
    }
  }
`;
