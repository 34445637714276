export default {
  'Ho Chi Minh': {
    name: 'Hồ Chí Minh',
    districts: {
      'District 1': {
        name: 'Quận 1',
        wards: {
          'Ben Nghe Ward': 'Phường Bến Nghé',
          'Ben Thanh Ward': 'Phường Bến Thành',
          'Cau Kho Ward': 'Phường Cầu Kho',
          'Cau Ong Lanh Ward': 'Phường Cầu Ông Lãnh',
          'Co Giang Ward': 'Phường Cô Giang',
          'Da Kao Ward': 'Phường Đa Kao',
          'Nguyen Cu Trinh Ward': 'Phường Nguyễn Cư Trinh',
          'Nguyen Thai Binh Ward': 'Phường Nguyễn Thái Bình',
          'Pham Ngu Lao Ward': 'Phường Phạm Ngũ Lão',
          'Tan Dinh Ward': 'Phường Tân Định',
        },
      },
      'District 2': {
        name: 'Quận 2',
        wards: {
          'Thanh My Loi Ward': 'Phường  Thạnh Mỹ Lợi',
          'An Khanh Ward': 'Phường An Khánh',
          'An Loi Dong Ward': 'Phường An Lợi Đông',
          'An Phu Ward': 'Phường An Phú',
          'Binh An Ward': 'Phường Bình An',
          'Binh Khanh Ward': 'Phường Bình Khánh',
          'Binh Trung Dong Ward': 'Phường Bình Trưng Đông',
          'Binh Trung Tay Ward': 'Phường Bình Trưng Tây',
          'Cat Lai Ward': 'Phường Cát Lái',
          'Thao Dien Ward': 'Phường Thảo Điền',
          'Thu Thiem Ward': 'Phường Thủ Thiêm',
        },
      },
      'District 3': {
        name: 'Quận 3',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 4': {
        name: 'Quận 4',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 16': 'Phường 16',
          'Ward 18': 'Phường 18',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 5': {
        name: 'Quận 5',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 6': {
        name: 'Quận 6',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 7': {
        name: 'Quận 7',
        wards: {
          'Binh Thuan Ward': 'Phường Bình Thuận',
          'Phu My Ward': 'Phường Phú Mỹ',
          'Phu Thuan Ward': 'Phường Phú Thuận',
          'Tan Hung Ward': 'Phường Tân Hưng',
          'Tan Kieng Ward': 'Phường Tân Kiểng',
          'Tan Phong Ward': 'Phường Tân Phong',
          'Tan Phu Ward': 'Phường Tân Phú',
          'Tan Quy Ward': 'Phường Tân Quy',
          'Tan Thuan Dong Ward': 'Phường Tân Thuận Đông',
          'Tan Thuan Tay Ward': 'Phường Tân Thuận Tây',
        },
      },
      'District 8': {
        name: 'Quận 8',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 16': 'Phường 16',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 9': {
        name: 'Quận 9',
        wards: {
          'Hiep Phu Ward': 'Phường Hiệp Phú',
          'Long Binh Ward': 'Phường Long Bình',
          'Long Phuoc Ward': 'Phường Long Phước',
          'Long Thanh My Ward': 'Phường Long Thạnh Mỹ',
          'Long Truong Ward': 'Phường Long Trường',
          'Phu Huu Ward': 'Phường Phú Hữu',
          'Phuoc Binh Ward': 'Phường Phước Bình',
          'Phuoc Long A Ward': 'Phường Phước Long A',
          'Phuoc Long B Ward': 'Phường Phước Long B',
          'Tan Phu Ward': 'Phường Tân Phú',
          'Tang Nhon Phu A Ward': 'Phường Tăng Nhơn Phú A',
          'Tang Nhon Phu B Ward': 'Phường Tăng Nhơn Phú B',
          'Truong Thanh Ward': 'Phường Trường Thạnh',
        },
      },
      'District 10': {
        name: 'Quận 10',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 11': {
        name: 'Quận 11',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 16': 'Phường 16',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'District 12': {
        name: 'Quận 12',
        wards: {
          'An Phu Dong Ward': 'Phường An Phú Đông',
          'Dong Hung Thuan Ward': 'Phường Đông Hưng Thuận',
          'Hiep Thanh Ward': 'Phường Hiệp Thành',
          'Tan Chanh Hiep Ward': 'Phường Tân Chánh Hiệp',
          'Tan Hung Thuan Ward': 'Phường Tân Hưng Thuận',
          'Tan Thoi Hiep Ward': 'Phường Tân Thới Hiệp',
          'Tan Thoi Nhat Ward': 'Phường Tân Thới Nhất',
          'Thanh Loc Ward': 'Phường Thạnh Lộc',
          'Thanh Xuan Ward': 'Phường Thạnh Xuân',
          'Thoi An Ward': 'Phường Thới An',
          'Trung My Tay Ward': 'Phường Trung Mỹ Tây',
        },
      },
      'Binh Tan District': {
        name: 'Quận Bình Tân',
        wards: {
          'An Lac Ward': 'Phường An Lạc',
          'An Lac A Ward': 'Phường An Lạc A',
          'Binh Hung Hoa Ward': 'Phường Bình Hưng Hòa',
          'Binh Hung Hoa A Ward': 'Phường Bình Hưng Hòa A',
          'Binh Hung Hoa B Ward': 'Phường Bình Hưng Hòa B',
          'Binh Tri Dong Ward': 'Phường Bình Trị Đông',
          'Binh Tri Dong A Ward': 'Phường Bình Trị Đông A',
          'Binh Tri Dong B Ward': 'Phường Bình Trị Đông B',
          'Tan Tao Ward': 'Phường Tân Tạo',
          'Tan Tao A Ward': 'Phường Tân Tạo A',
        },
      },
      'Binh Thanh District': {
        name: 'Quận Bình Thạnh',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 17': 'Phường 17',
          'Ward 19': 'Phường 19',
          'Ward 2': 'Phường 2',
          'Ward 21': 'Phường 21',
          'Ward 22': 'Phường 22',
          'Ward 24': 'Phường 24',
          'Ward 25': 'Phường 25',
          'Ward 26': 'Phường 26',
          'Ward 27': 'Phường 27',
          'Ward 28': 'Phường 28',
          'Ward 3': 'Phường 3',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
        },
      },
      'Go Vap District': {
        name: 'Quận Gò Vấp',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 16': 'Phường 16',
          'Ward 17': 'Phường 17',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'Phu Nhuan District': {
        name: 'Quận Phú Nhuận',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 17': 'Phường 17',
          'Ward 2': 'Phường 2',
          'Ward 25': 'Phường 25',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'Tan Binh District': {
        name: 'Quận Tân Bình',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 10': 'Phường 10',
          'Ward 11': 'Phường 11',
          'Ward 12': 'Phường 12',
          'Ward 13': 'Phường 13',
          'Ward 14': 'Phường 14',
          'Ward 15': 'Phường 15',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'Ward 8': 'Phường 8',
          'Ward 9': 'Phường 9',
        },
      },
      'Tan Phu District': {
        name: 'Quận Tân Phú',
        wards: {
          'Hiep Tan Ward': 'Phường Hiệp Tân',
          'Hoa Thanh Ward': 'Phường Hòa Thạnh',
          'Phu Thanh Ward': 'Phường Phú Thạnh',
          'Phu Tho Hoa Ward': 'Phường Phú Thọ Hòa',
          'Phu Trung Ward': 'Phường Phú Trung',
          'Son Ky Ward': 'Phường Sơn Kỳ',
          'Tan Quy Ward': 'Phường Tân Quý',
          'Tan Son Nhi Ward': 'Phường Tân Sơn Nhì',
          'Tan Thanh Ward': 'Phường Tân Thành',
          'Tan Thoi Hoa Ward': 'Phường Tân Thới Hòa',
          'Tay Thanh Ward': 'Phường Tây Thạnh',
        },
      },
      'Thu Duc District': {
        name: 'Quận Thủ Đức',
        wards: {
          'Binh Chieu Ward': 'Phường Bình Chiểu',
          'Binh Tho Ward': 'Phường Bình Thọ',
          'Hiep Binh Chanh Ward': 'Phường Hiệp Bình Chánh',
          'Hiep Binh Phuoc Ward': 'Phường Hiệp Bình Phước',
          'Linh Chieu Ward': 'Phường Linh Chiểu',
          'Linh Dong Ward': 'Phường Linh Đông',
          'Linh Tay Ward': 'Phường Linh Tây',
          'Linh Trung Ward': 'Phường Linh Trung',
          'Linh Xuan Ward': 'Phường Linh Xuân',
          'Tam Binh Ward': 'Phường Tam Bình',
          'Tam Phu Ward': 'Phường Tam Phú',
          'Truong Tho Ward': 'Phường Trường Thọ',
        },
      },
      'Nha Be District': {
        name: 'Huyện Nhà Bè',
        wards: {
          'Hiep Phuoc Ward': 'Phường Hiệp Phước',
          'Long Thoi Commune': 'Xã Long Thới',
          'Nha Be Town': 'Thị trấn Nhà Bè',
          'Nhon Duc Commune': 'Xã Nhơn Đức',
          'Phu Xuan Commune': 'Xã Phú Xuân',
          'Phuoc Kien Commune': 'Xã Phước Kiển',
          'Phuoc Loc Commune': 'Xã Phước Lộc',
        },
      },
      'Binh Chanh District': {
        name: 'Huyện Bình Chánh',
        wards: {
          'An Phu Tay Commune': 'Xã An Phú Tây',
          'Binh Chanh Commune': 'Xã Bình Chánh',
          'Binh Hung Commune': 'Xã Bình Hưng',
          'Binh Loi Commune': 'Xã Bình Lợi',
          'Da Phuoc Commune': 'Xã Đa Phước',
          'Hung Long Commune': 'Xã Hưng Long',
          'Le Minh Xuan Commune': 'Xã Lê Minh Xuân',
          'Pham Van Hai Commune': 'Xã Phạm Văn Hai',
          'Phong Phu Commune': 'Xã Phong Phú',
          'Quy Duc Commune': 'Xã Quy Đức',
          'Tan Kien Commune': 'Xã Tân Kiên',
          'Tan Nhut Commune': 'Xã Tân Nhựt',
          'Tan Quy Tay Commune': 'Xã Tân Quý Tây',
          'Tan Tuc Town': 'Thị trấn Tân Túc',
          'Vinh Loc A Commune': 'Xã Vĩnh Lộc A',
          'Vinh Loc B Commune': 'Xã Vĩnh Lộc B',
        },
      },
      'Hoc Mon District': {
        name: 'Huyện Hóc Môn',
        wards: {
          'Dong Thanh Commune': 'Xã  Đông Thạnh',
          'Hoc Mon Ward': 'Phường  Hóc Môn',
          'Ba Diem Ward': 'Phường Bà Điểm',
          'Nhi Binh Commune': 'Xã Nhị Bình',
          'Tan Hiep Commune': 'Xã Tân Hiệp',
          'Tan Thoi Nhi Commune': 'Xã Tân Thới Nhì',
          'Tan Xuan Commune': 'Xã Tân Xuân',
          'Thoi Tam Thon Commune': 'Xã Thới Tam Thôn',
          'Trung Chanh Commune': 'Xã Trung Chánh',
          'Xuan Thoi Dong Commune': 'Xã Xuân Thới Đông',
          'Xuan Thoi Son Commune': 'Xã Xuân Thới Sơn',
          'Xuan Thoi Thuong Commune': 'Xã Xuân Thới Thượng',
        },
      },
      'Can Gio District': {
        name: 'Huyện Cần Giờ',
        wards: {
          'An Thoi Dong Commune': 'Xã An Thới Đông',
          'Binh Khanh Commune': 'Xã Bình Khánh',
          'Can Thanh Ward': 'Phường Cần Thạnh',
          'Long Hoa Commune': 'Xã Long Hòa',
          'Ly Nhon Commune': 'Xã Lý Nhơn',
          'Tam Thon Hiep Commune': 'Xã Tam Thôn Hiệp',
          'Thanh An Commune': 'Xã Thạnh An',
        },
      },
      'Cu Chi District': {
        name: 'Huyện Củ Chi',
        wards: {
          'An Nhon Tay Commune': 'Xã An Nhơn Tây',
          'An Phu Commune': 'Xã An Phú',
          'An Phu Trung Commune': 'Xã An Phú Trung',
          'Binh My Commune': 'Xã Bình Mỹ',
          'Cu Chi Town': 'Thị trấn Củ Chi',
          'Hoa Phu Commune': 'Xã Hòa Phú',
          'Nhuan Duc Commune': 'Xã Nhuận Đức',
          'Pham Van Coi Commune': 'Xã Phạm Văn Cội',
          'Phu Hoa Dong Commune': 'Xã Phú Hòa Đông',
          'Phu My Hung Commune': 'Xã Phú Mỹ Hưng',
          'Phuoc Hiep Commune': 'Xã Phước Hiệp',
          'Phuoc Thanh Commune': 'Xã Phước Thạnh',
          'Phuoc Vinh An Commune': 'Xã Phước Vĩnh An',
          'Tan An Hoi Commune': 'Xã Tân An Hội',
          'Tan Phu Trung Commune': 'Xã Tân Phú Trung',
          'Tan Thanh Dong Commune': 'Xã Tân Thạnh Đông',
          'Tan Thanh Tay Commune': 'Xã Tân Thạnh Tây',
          'Tan Thong Hoi Commune': 'Xã Tân Thông Hội',
          'Thai My Commune': 'Xã Thái Mỹ',
          'Trung An Commune': 'Xã Trung An',
          'Trung Lap Ha Commune': 'Xã Trung Lập Hạ',
          'Trung Lap Thuong Commune': 'Xã Trung Lập Thượng',
        },
      },
    },
  },
  'Binh Duong': {
    name: 'Bình Dương',
    districts: {
      'Thu Dau Mot City': {
        name: 'Thành phố Thủ Dầu Một',
        wards: {
          'Chanh My Commune': 'Xã Chánh Mỹ',
          'Chanh Nghia Ward': 'Phường Chánh Nghĩa',
          'Dinh Hoa Ward': 'Phường Định Hòa',
          'Hiep An Ward': 'Phường Hiệp An',
          'Hiep Thanh Ward': 'Phường Hiệp Thành',
          'Hoa Phu Ward': 'Phường Hòa Phú',
          'Phu Cuong Ward': 'Phường Phú Cường',
          'Phu Hoa Ward': 'Phường Phú Hòa',
          'Phu Loi Ward': 'Phường Phú Lợi',
          'Phu My Ward': 'Phường Phú Mỹ',
          'Phu Tan Ward': 'Phường Phú Tân',
          'Phu Tho Ward': 'Phường Phú Thọ',
          'Tan An Commune': 'Xã Tân An',
          'Tuong Binh Hiep Commune': 'Xã Tương Bình Hiệp',
        },
      },
      'Bau Bang District': {
        name: 'Huyện Bàu Bàng',
        wards: {
          'Cay Truong II Commune': 'Xã Cây Trường II',
          'Hung Hoa Commune': 'Xã Hưng Hòa',
          'Lai Hung Commune': 'Xã Lai Hưng',
          'Lai Uyen Commune': 'Xã Lai Uyên',
          'Long Nguyen Commune': 'Xã Long Nguyên',
          'Tan Hung Commune': 'Xã Tân Hưng',
          'Tru Van Tho Commune': 'Xã Trừ Văn Thố',
        },
      },
      'Dau Tieng District': {
        name: 'Huyện Dầu Tiếng',
        wards: {
          'An Lap Commune': 'Xã An Lập',
          'Dau Tieng Ward': 'Phường Dầu Tiếng',
          'Dinh  Hiep Commune': 'Xã Định  Hiệp',
          'Dinh An Commune': 'Xã Định An',
          'Dinh Thanh Commune': 'Xã Định Thành',
          'Long Hoa Commune': 'Xã Long Hòa',
          'Long Tan Commune': 'Xã Long Tân',
          'Minh Hoa Commune': 'Xã Minh Hòa',
          'Minh Tan Commune': 'Xã Minh Tân',
          'Minh Thanh Commune': 'Xã Minh Thạnh',
          'Thanh An Commune': 'Xã Thanh An',
          'Thanh Tuyen Commune': 'Xã Thanh Tuyền',
        },
      },
      'Ben Cat Town': {
        name: 'Thị xã Bến Cát',
        wards: {
          'An Dien Commune': 'Xã An Điền',
          'An Tay Commune': 'Xã An Tây',
          'Cay Truong Commune': 'Xã Cây Trường',
          'Chanh Phu Hoa Commune': 'Xã Chánh Phú Hòa',
          'Hoa Loi Ward': 'Phường Hòa Lợi',
          'My Phuoc Ward': 'Phường Mỹ Phước',
          'Phu An Commune': 'Xã Phú An',
          'Tan Dinh Commune': 'Xã Tân Định',
          'Thoi Hoa Commune': 'Xã Thới Hòa',
        },
      },
      'Phu Giao District': {
        name: 'Huyện Phú Giáo',
        wards: {
          'An Binh Commune': 'Xã An Bình',
          'An Linh Commune': 'Xã An Linh',
          'An Long Commune': 'Xã An Long',
          'An Thai Commune': 'Xã An Thái',
          'Phuoc Hoa Commune': 'Xã Phước Hòa',
          'Phuoc Sang Commune': 'Xã Phước Sang',
          'Phuoc Vinh Ward': 'Phường Phước Vĩnh',
          'Tam Lap Commune': 'Xã Tam Lập',
          'Tan Hiep Commune': 'Xã Tân Hiệp',
          'Tan Long Commune': 'Xã Tân Long',
          'Vinh Hoa Commune': 'Xã Vĩnh Hòa',
        },
      },
      'Tan Uyen Town': {
        name: 'Thị xã Tân Uyên',
        wards: {
          'Bach Dang Commune': 'Xã Bạch Đằng',
          'Hoi Nghia Commune': 'Xã Hội Nghĩa',
          'Khanh Binh Commune': 'Xã Khánh Bình',
          'Phu Chanh Commune': 'Xã Phú Chánh',
          'Tan Hiep Commune': 'Xã Tân Hiệp',
          'Tan Phuoc Khanh Ward': 'Phường Tân Phước Khánh',
          'Tan Vinh Hiep Commune': 'Xã Tân Vĩnh Hiệp',
          'Thai Hoa Ward': 'Phường Thái Hòa',
          'Thanh Hoi Commune': 'Xã Thạnh Hội',
          'Thanh Phuoc Commune': 'Xã Thạnh Phước',
          'Uyen Hung Ward': 'Phường Uyên Hưng',
          'Vinh Tan Commune': 'Xã Vĩnh Tân',
        },
      },
      'Di An City': {
        name: 'Thành phố Dĩ An',
        wards: {
          'An Binh Ward': 'Phường An Bình',
          'Binh An Ward': 'Phường Bình An',
          'Binh Thang Ward': 'Phường Bình Thắng',
          'Di An Ward': 'Phường Dĩ An',
          'Dong Hoa Ward': 'Phường Đông Hòa',
          'Tan Binh Ward': 'Phường Tân Bình',
          'Tan Dong Hiep Ward': 'Phường Tân Đông Hiệp',
        },
      },
      'Thuan An City': {
        name: 'Thành phố Thuận An',
        wards: {
          'An Phu Ward': 'Phường An Phú',
          'An Son Commune': 'Xã An Sơn',
          'An Thanh Ward': 'Phường An Thạnh',
          'Binh Chuan Ward': 'Phường Bình Chuẩn',
          'Binh Hoa Ward': 'Phường Bình Hòa',
          'Binh Nham Commune': 'Xã Bình Nhâm',
          'Hung Dinh Commune': 'Xã Hưng Định',
          'Lai Thieu Ward': 'Phường Lái Thiêu',
          'Thuan Giao Ward': 'Phường Thuận Giao',
          'Vinh Phu Ward': 'Phường Vĩnh Phú',
        },
      },
      'Bac Tan Uyen District': {
        name: 'Huyện Bắc Tân Uyên',
        wards: {
          'Binh My Commune': 'Xã Bình Mỹ',
          'Dat Cuoc Commune': 'Xã Đất Cuốc',
          'Hieu Liem Commune': 'Xã Hiếu Liêm',
          'Lac An Commune': 'Xã Lạc An',
          'Tan Binh Commune': 'Xã Tân Bình',
          'Tan Dinh Commune': 'Xã Tân Định',
          'Tan Lap Commune': 'Xã Tân Lập',
          'Tan My Commune': 'Xã Tân Mỹ',
          'Tan Thanh Commune': 'Xã Tân Thành',
          'Thuong Tan Commune': 'Xã Thường Tân',
        },
      },
    },
  },
  'Long An': {
    name: 'Long An',
    districts: {
      'Tan An City': {
        name: 'Thành phố Tân An',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Ward 4': 'Phường 4',
          'Ward 5': 'Phường 5',
          'Ward 6': 'Phường 6',
          'Ward 7': 'Phường 7',
          'An Vinh Ngai Commune': 'Xã An Vĩnh Ngãi',
          'Binh Tam Commune': 'Xã Bình Tâm',
          'Huong Tho Phu Commune': 'Xã Hướng Thọ Phú',
          'Khanh Hau Ward': 'Phường Khánh Hậu',
          'Loi Binh Nhon Commune': 'Xã Lợi Bình Nhơn',
          'Nhon Thanh Trung Commune': 'Xã Nhơn Thạnh Trung',
          'Tan Khanh Ward': 'Phường Tân Khánh',
        },
      },
      'Kien Tuong Town': {
        name: 'Thị xã Kiến Tường',
        wards: {
          'Ward 1': 'Phường 1',
          'Ward 2': 'Phường 2',
          'Ward 3': 'Phường 3',
          'Binh Hiep Commune': 'Xã Bình Hiệp',
          'Binh Tan Commune': 'Xã Bình Tân',
          'Thanh Hung Commune': 'Xã Thạnh Hưng',
          'Thanh Tri Commune': 'Xã Thạnh Trị',
          'Tuyen Thanh Commune': 'Xã Tuyên Thạnh',
        },
      },
      'Tan Hung District': {
        name: 'Huyện Tân Hưng',
        wards: {
          'Binh Thanh Town': 'Thị trấn Bình Thạnh',
          'Hung Dien Commune': 'Xã Hưng Điền',
          'Hung Dien B Commune': 'Xã Hưng Điền B',
          'Hung Ha Commune': 'Xã Hưng Hà',
          'Hung Thanh Commune': 'Xã Hưng Thạnh',
          'Tan Hung Town': 'Thị trấn Tân Hưng',
          'Thanh Hung Commune': 'Xã Thạnh Hưng',
          'Vinh Buu Commune': 'Xã Vĩnh Bữu',
          'Vinh Chau A Commune': 'Xã Vĩnh Châu A',
          'Vinh Chau B Commune': 'Xã Vĩnh Châu B',
          'Vinh Dai Commune': 'Xã Vĩnh Đại',
          'Vinh Loi Commune': 'Xã Vĩnh Lợi',
          'Vinh Thanh Commune': 'Xã Vĩnh Thạnh',
        },
      },
      'Vinh Hung District': {
        name: 'Huyện Vĩnh Hưng',
        wards: {
          'Hung Dien A Commune': 'Xã Hưng Điền A',
          'Khanh Hung Commune': 'Xã Khánh Hưng',
          'Thai Binh Trung Commune': 'Xã Thái Bình Trung',
          'Thai Tri Commune': 'Xã Thái Trị',
          'Tuyen Binh Commune': 'Xã Tuyên Bình',
          'Tuyen Binh Tay Commune': 'Xã Tuyên Bình Tây',
          'Vinh Binh Commune': 'Xã Vĩnh Bình',
          'Vinh Hung Town': 'Thị trấn Vĩnh Hưng',
          'Vinh Thuan Commune': 'Xã Vĩnh Thuận',
          'Vinh Tri Commune': 'Xã Vĩnh Trị',
        },
      },
      'Moc Hoa District': {
        name: 'Huyện Mộc Hóa',
        wards: {
          'Binh Hoa Dong Commune': 'Xã Bình Hòa Đông',
          'Binh Hoa Tay Commune': 'Xã Bình Hòa Tây',
          'Binh Hoa Trung Commune': 'Xã Bình Hòa Trung',
          'Binh Phong Thanh Commune': 'Xã Bình Phong Thạnh',
          'Binh Thanh Commune': 'Xã Bình Thạnh',
          'Tan Lap Commune': 'Xã Tân Lập',
          'Tan Thanh Commune': 'Xã Tân Thành',
        },
      },
      'Tan Thanh District': {
        name: 'Huyện Tân Thạnh',
        wards: {
          'Bac Hoa Commune': 'Xã Bắc Hoà',
          'Hau Thanh Dong Commune': 'Xã Hậu Thạnh Đông',
          'Hau Thanh Tay Commune': 'Xã Hậu Thạnh Tây',
          'Kien Binh Commune': 'Xã Kiến Bình',
          'Nhon Hoa Commune': 'Xã Nhơn Hoà',
          'Nhon Hoa Lap Commune': 'Xã Nhơn Hoà Lập',
          'Nhon Ninh Commune': 'Xã Nhơn Ninh',
          'Tan Binh Commune': 'Xã Tân Bình',
          'Tan Hoa Commune': 'Xã Tân Hoà',
          'Tan Lap Commune': 'Xã Tân Lập',
          'Tan Ninh Commune': 'Xã Tân Ninh',
          'Tan Thanh Commune': 'Xã Tân Thành',
          'Tan Thanh Town': 'Thị trấn Tân Thạnh',
        },
      },
      'Thanh Hoa District': {
        name: 'Huyện Thạnh Hóa',
        wards: {
          'Tan Dong Commune': 'Xã Tân Đông',
          'Tan Hiep Commune': 'Xã Tân Hiệp',
          'Tan Tay Commune': 'Xã Tân Tây',
          'Thanh An Commune': 'Xã Thạnh An',
          'Thanh Hoa Town': 'Thị trấn Thạnh Hóa',
          'Thanh Phu Commune': 'Xã Thạnh Phú',
          'Thanh Phuoc Commune': 'Xã Thạnh Phước',
          'Thuan Binh Commune': 'Xã Thuận Bình',
          'Thuan Nghia Hoa Commune': 'Xã Thuận Nghĩa Hòa',
          'Thuy Dong Commune': 'Xã Thủy Đông',
          'Thuy Tay Commune': 'Xã Thủy Tây',
        },
      },
      'Duc Hue District': {
        name: 'Huyện Đức Huệ',
        wards: {
          'Binh Hoa Bac Commune': 'Xã Bình Hòa Bắc',
          'Binh Hoa Hung Commune': 'Xã Bình Hòa Hưng',
          'Binh Hoa Nam Commune': 'Xã Bình Hòa Nam',
          'Binh Thanh Commune': 'Xã Bình Thành',
          'Dong Thanh Town': 'Thị trấn Đông Thành',
          'My Binh Commune': 'Xã Mỹ Bình',
          'My Quy Dong Commune': 'Xã Mỹ Quý Đông',
          'My Quy Tay Commune': 'Xã Mỹ Quý Tây',
          'My Thanh Bac Commune': 'Xã Mỹ Thạnh Bắc',
          'My Thanh Dong Commune': 'Xã Mỹ Thạnh Đông',
          'My Thanh Tay Commune': 'Xã Mỹ Thạnh Tây',
        },
      },
      'Duc Hoa District': {
        name: 'Huyện Đức Hòa',
        wards: {
          'An Ninh Dong Commune': 'Xã An Ninh Đông',
          'An Ninh Tay Commune': 'Xã An Ninh Tây',
          'Duc Hoa Town': 'Thị trấn Đức Hòa',
          'Duc Hoa Dong Commune': 'Xã Đức Hoà Đông',
          'Duc Hoa Ha Commune': 'Xã Đức Hoà Hạ',
          'Duc Hoa Thuong Commune': 'Xã Đức Hoà Thượng',
          'Duc Lap Ha Commune': 'Xã Đức Lập Hạ',
          'Duc Lap Thuong Commune': 'Xã Đức Lập Thượng',
          'Hau Nghia Town': 'Thị trấn Hậu Nghĩa',
          'Hiep Hoa Town': 'Thị trấn Hiệp Hòa',
          'Hiep Hoa Commune': 'Xã Hiệp Hòa',
          'Hoa Khanh Dong Commune': 'Xã Hoà Khánh Đông',
          'Hoa Khanh Nam Commune': 'Xã Hoà Khánh Nam',
          'Hoa Khanh Tay Commune': 'Xã Hòa Khánh Tây',
          'Huu Thanh Commune': 'Xã Hựu Thạnh',
          'Loc Giang Commune': 'Xã Lộc Giang',
          'My Hanh Bac Commune': 'Xã Mỹ Hạnh Bắc',
          'My Hanh Nam Commune': 'Xã Mỹ Hạnh Nam',
          'Tan My Commune': 'Xã Tân Mỹ',
          'Tan Phu Commune': 'Xã Tân Phú',
        },
      },
      'Ben Luc District': {
        name: 'Huyện Bến Lức',
        wards: {
          'An Thanh Commune': 'Xã An Thạnh',
          'Ben Luc Town': 'Thị trấn Bến Lức',
          'Binh Duc Commune': 'Xã Bình Đức',
          'Long Hiep Commune': 'Xã Long Hiệp',
          'Luong Binh Commune': 'Xã Lương Bình',
          'Luong Hoa Commune': 'Xã Lương Hòa',
          'My Yen Commune': 'Xã Mỹ Yên',
          'Nhut Chanh Commune': 'Xã Nhựt Chánh',
          'Phuoc Loi Commune': 'Xã Phước Lợi',
          'Tan Buu Commune': 'Xã Tân Bửu',
          'Tan Hoa Commune': 'Xã Tân Hòa',
          'Thanh Duc Commune': 'Xã Thạnh Đức',
          'Thanh Hoa Commune': 'Xã Thạnh Hòa',
          'Thanh Loi Commune': 'Xã Thạnh Lợi',
          'Thanh Phu Commune': 'Xã Thanh Phú',
        },
      },
      'Thu Thua District': {
        name: 'Huyện Thủ Thừa',
        wards: {
          'Binh An Commune': 'Xã Bình An',
          'Binh Thanh Commune': 'Xã Bình Thạnh',
          'Long Thanh Commune': 'Xã Long Thạnh',
          'Long Thuan Commune': 'Xã Long Thuận',
          'My An Commune': 'Xã Mỹ An',
          'My Lac Commune': 'Xã Mỹ Lạc',
          'My Phu Commune': 'Xã Mỹ Phú',
          'My Thanh Commune': 'Xã Mỹ Thạnh',
          'Nhi Thanh Commune': 'Xã Nhị Thành',
          'Tan Lap Commune': 'Xã Tân Lập',
          'Tan Thanh Commune': 'Xã Tân Thành',
          'Thu Thua Commune': 'Xã Thủ Thừa',
        },
      },
      'Tan Tru District': {
        name: 'Huyện Tân Trụ',
        wards: {
          'An Nhut Tan Commune': 'Xã An Nhựt Tân',
          'Binh Lang Commune': 'Xã Bình Lãng',
          'Binh Tinh Commune': 'Xã Bình Tịnh',
          'Binh Trinh Dong Commune': 'Xã Bình Trinh Đông',
          'Duc Tan Commune': 'Xã Đức Tân',
          'Lac Tan Commune': 'Xã Lạc Tấn',
          'My Binh Commune': 'Xã Mỹ Bình',
          'Nhut Ninh Commune': 'Xã Nhựt Ninh',
          'Que My Thanh Commune': 'Xã Quê Mỹ Thạnh',
          'Tan Phuoc Tay Commune': 'Xã Tân Phước Tây',
          'Tan Tru Town': 'Thị trấn Tân Trụ',
        },
      },
      'Can Duoc District': {
        name: 'Huyện Cần Đước',
        wards: {
          'Can Duoc Town': 'Thị trấn Cần Đước',
          'Long Cang Commune': 'Xã Long Cang',
          'Long Dinh Commune': 'Xã Long Định',
          'Long Hoa Commune': 'Xã Long Hòa',
          'Long Huu Dong Commune': 'Xã Long Hựu Đông',
          'Long Huu Tay Commune': 'Xã Long Hựu Tây',
          'Long Khe Commune': 'Xã Long Khê',
          'Long Son Commune': 'Xã Long Sơn',
          'Long Thanh Commune': 'Xã Long Thạnh',
          'Long Trach Commune': 'Xã Long Trạch',
          'My Le Commune': 'Xã Mỹ Lệ',
          'Phuoc Dong Commune': 'Xã Phước Đông',
          'Phuoc Tuy Commune': 'Xã Phước Tuy',
          'Phuoc Van Commune': 'Xã Phước Vân',
          'Tan An Commune': 'Xã Tân Ân',
          'Tan Chanh Commune': 'Xã Tân Chánh',
          'Tan Lan Commune': 'Xã Tân Lân',
          'Tan Trach Commune': 'Xã Tân Trạch',
        },
      },
      'Can Giuoc District': {
        name: 'Huyện Cần Giuộc',
        wards: {
          'Can Giuoc Town': 'Thị trấn Cần Giuộc',
          'Dong Thanh Commune': 'Xã Đông Thạnh',
          'Long An Commune': 'Xã Long An',
          'Long Hau Commune': 'Xã Long Hậu',
          'Long Phung Commune': 'Xã Long Phụng',
          'Long Thuong Commune': 'Xã Long Thượng',
          'My Loc Commune': 'Xã Mỹ Lộc',
          'Phuoc Hau Commune': 'Xã Phước Hậu',
          'Phuoc Lai Commune': 'Xã Phước Lại',
          'Phuoc Lam Commune': 'Xã Phước Lâm',
          'Phuoc Ly Commune': 'Xã Phước Lý',
          'Phuoc Vinh Dong Commune': 'Xã Phước Vĩnh Đông',
          'Phuoc Vinh Tay Commune': 'Xã Phước Vĩnh Tây',
          'Tan Kim Commune': 'Xã Tân Kim',
          'Tan Tap Commune': 'Xã Tân Tập',
          'Thuan Thanh Commune': 'Xã Thuận Thành',
          'Truong Binh Commune': 'Xã Trường Bình',
        },
      },
      'Chau Thanh District': {
        name: 'Huyện Châu Thành',
        wards: {
          'An Luc Long Commune': 'Xã An Lục Long',
          'Binh Quoi Commune': 'Xã Bình Quới',
          'Duong Xuan Hoi Commune': 'Xã Dương Xuân Hội',
          'Hiep Thanh Commune': 'Xã Hiệp Thạnh',
          'Hoa Phu Commune': 'Xã Hòa Phú',
          'Long Tri Commune': 'Xã Long Trì',
          'Phu Ngai Tri Commune': 'Xã Phú Ngãi Trị',
          'Phuoc Tan Hung Commune': 'Xã Phước Tân Hưng',
          'Tam Vu Town': 'Thị trấn Tầm Vu',
          'Thanh Phu Long Commune': 'Xã Thanh Phú Long',
          'Thanh Vinh Dong Commune': 'Xã Thanh Vĩnh Đông',
          'Thuan My Commune': 'Xã Thuận Mỹ',
          'Vinh Cong Commune': 'Xã Vĩnh Công',
        },
      },
    },
  },
  'Dong Nai': {
    name: 'Đồng Nai',
    districts: {
      'Bien Hoa City': {
        name: 'Thành phố Biên Hòa',
        wards: {
          'An Binh Ward': 'Phường An Bình',
          'An Hoa Commune': 'Xã An Hòa',
          'Bau Hang Ward': 'Phường Bàu Hang',
          'Binh Da Ward': 'Phường Bình Đa',
          'Buu Hoa Ward': 'Phường Bửu Hòa',
          'Buu Long Ward': 'Phường Bửu Long',
          'Hiep Hoa Commune': 'Xã Hiệp Hòa',
          'Ho Can Ward': 'Phường Hố Cạn',
          'Ho Nai Ward': 'Phường Hố Nai',
          'Hoa An Ward': 'Phường Hóa An',
          'Hoa Binh Ward': 'Phường Hòa Bình',
          'Long Binh Ward': 'Phường Long Bình',
          'Long Binh Tan Ward': 'Phường Long Bình Tân',
          'Long Hung Commune': 'Xã Long Hưng',
          'Phuoc Tan Commune': 'Xã Phước Tân',
          'Quang Vinh Ward': 'Phường Quang Vinh',
          'Quyet Thang Ward': 'Phường Quyết Thắng',
          'Tam Hiep Ward': 'Phường Tam Hiệp',
          'Tam Hoa Ward': 'Phường Tam Hòa',
          'Tam Phuoc Commune': 'Xã Tam Phước',
          'Tan Bien Ward': 'Phường Tân Biên',
          'Tan Hanh Commune': 'Xã Tân Hạnh',
          'Tan Hiep Ward': 'Phường Tân Hiệp',
          'Tan Hoa Ward': 'Phường Tân Hòa',
          'Tan Long Ward': 'Phường Tân Long',
          'Tan Mai Ward': 'Phường Tân Mai',
          'Tan Phong Ward': 'Phường Tân Phong',
          'Tan Tien Ward': 'Phường Tân Tiến',
          'Tan Van Ward': 'Phường Tân Vạn',
          'Thanh Binh Ward': 'Phường Thanh Bình',
          'Thong Nhat Ward': 'Phường Thống Nhất',
          'Trang Dai Ward': 'Phường Trảng Dài',
          'Trung Dung Ward': 'Phường Trung Dũng',
        },
      },
      'Long Khanh City': {
        name: 'Thành phố Long Khánh',
        wards: {
          'Bao Hoa Commune': 'Xã Bảo Hoà',
          'Bao Quang Commune': 'Xã Bảo Quang',
          'Bao Vinh Commune': 'Xã Bảo Vinh',
          'Bau Sen Commune': 'Xã Bàu Sen',
          'Bau Tram Commune': 'Xã Bàu Trâm',
          'Binh Loc Commune': 'Xã Bình Lộc',
          'Hang Gon Commune': 'Xã Hàng Gòn',
          'Phu Binh Ward': 'Phường Phú Bình',
          'Suoi Tre Commune': 'Xã Suối Tre',
          'Xuan An Ward': 'Phường Xuân An',
          'Xuan Binh Ward': 'Phường Xuân Bình',
          'Xuan Hoa Ward': 'Phường Xuân Hòa',
          'Xuan Lap Commune': 'Xã Xuân Lập',
          'Xuan Tan Commune': 'Xã Xuân Tân',
          'Xuan Thanh Ward': 'Phường Xuân Thanh',
          'Xuan Trung Ward': 'Phường Xuân Trung',
        },
      },
      'Tan Phu District': {
        name: 'Quận Tân Phú',
        wards: {
          'Dak Lua Commune': 'Xã Đăk Lua',
          'Nam Cat Tien Commune': 'Xã Nam Cát Tiên',
          'Nui Tuong Commune': 'Xã Núi Tượng',
          'Phu An Commune': 'Xã Phú An',
          'Phu Binh Commune': 'Xã Phú Bình',
          'Phu Dien Commune': 'Xã Phú Điền',
          'Phu Lam Commune': 'Xã Phú Lâm',
          'Phu Lap Commune': 'Xã Phú Lập',
          'Phu Loc Commune': 'Xã Phú Lộc',
          'Phu Son Commune': 'Xã Phú Sơn',
          'Phu Thanh Commune': 'Xã Phú Thanh',
          'Phu Thinh Commune': 'Xã Phú Thịnh',
          'Phu Trung Commune': 'Xã Phú Trung',
          'Phu Xuan Commune': 'Xã Phú Xuân',
          'Ta Lai Commune': 'Xã Tà Lài',
          'Tan Phu Town': 'Thị trấn Tân Phú',
          'Thanh Son Commune': 'Xã Thanh Sơn',
          'Tra Co Commune': 'Xã Trà Cổ',
        },
      },
      'Vinh Cuu District': {
        name: 'Huyện Vĩnh Cửu',
        wards: {
          'Binh Hoa Commune': 'Xã Bình Hòa',
          'Binh Loi Commune': 'Xã Bình Lợi',
          'Hieu Liem Commune': 'Xã Hiếu Liêm',
          'Ma Da Commune': 'Xã Mã Đà',
          'Phu Ly Commune': 'Xã Phú Lý',
          'Tan An Commune': 'Xã Tân An',
          'Tan Binh Commune': 'Xã Tân Bình',
          'Thanh Phu Commune': 'Xã Thạnh Phú',
          'Thien Tan Commune': 'Xã Thiện Tân',
          'Tri An Commune': 'Xã Trị An',
          'Vinh An Town': 'Thị trấn Vĩnh An',
          'Vinh Tan Commune': 'Xã Vĩnh Tân',
        },
      },
      'Dinh Quan District': {
        name: 'Huyện Định Quán',
        wards: {
          'Dinh Quan Town': 'Thị trấn Định Quán',
          'Gia Canh Commune': 'Xã Gia Canh',
          'La Nga Commune': 'Xã La Ngà',
          'Ngoc Dinh Commune': 'Xã Ngọc Định',
          'Phu Cuong Commune': 'Xã Phú Cường',
          'Phu Hoa Commune': 'Xã Phú Hoà',
          'Phu Loi Commune': 'Xã Phú Lợi',
          'Phu Ngoc Commune': 'Xã Phú Ngọc',
          'Phu Tan Commune': 'Xã Phú Tân',
          'Phu Tuc Commune': 'Xã Phú Túc',
          'Phu Vinh Commune': 'Xã Phú Vinh',
          'Suoi Nho Commune': 'Xã Suối Nho',
          'Thanh Son Commune': 'Xã Thanh Sơn',
          'Tuc Trung Commune': 'Xã Túc Trưng',
        },
      },
      'Trang Bom District': {
        name: 'Huyện Trảng Bom',
        wards: {
          'An Vien Commune': 'Xã An Viễn',
          'Bac Son Commune': 'Xã Bắc Sơn',
          'Bau Ham Commune': 'Xã Bàu Hàm',
          'Binh Minh Commune': 'Xã Bình Minh',
          'Cay Gao Commune': 'Xã Cây Gáo',
          'Doi 61 Commune': 'Xã Đồi 61',
          'Dong Hoa Commune': 'Xã Đông Hòa',
          'Giang Dien Commune': 'Xã Giang Điền',
          'Ho Nai 3 Commune': 'Xã Hố Nai 3',
          'Hoa Trung Commune': 'Xã Hòa Trung',
          'Hung Thinh Commune': 'Xã Hưng Thịnh',
          'Quang Tien Commune': 'Xã Quảng Tiến',
          'Song Thao Commune': 'Xã Sông Thao',
          'Song Trau Commune': 'Xã Sông Trầu',
          'Tay Hoa Commune': 'Xã Tây Hòa',
          'Thanh Binh Commune': 'Xã Thanh Bình',
          'Trang Bom Town': 'Thị trấn Trảng Bom',
          'Trung Hoa Commune': 'Xã Trung Hòa',
        },
      },
      'Thong Nhat District': {
        name: 'Huyện Thống Nhất',
        wards: {
          'Bau Ham 2 Commune': 'Xã Bàu Hàm 2',
          'Dau Giay Town': 'Thị trấn Dầu Giây',
          'Gia Kiem Commune': 'Xã Gia Kiệm',
          'Gia Tan 1 Commune': 'Xã Gia Tân 1',
          'Gia Tan 2 Commune': 'Xã Gia Tân 2',
          'Gia Tan 3 Commune': 'Xã Gia Tân 3',
          'Hung Loc Commune': 'Xã Hưng Lộc',
          'Lo 25 Commune': 'Xã Lộ 25',
          'Quang Trung Commune': 'Xã Quang Trung',
          'Xuan Thanh Commune': 'Xã Xuân Thạnh',
          'Xuan Thien Commune': 'Xã Xuân Thiện',
        },
      },
      'Cam My District': {
        name: 'Huyện Cẩm Mỹ',
        wards: {
          'Bao Binh Commune': 'Xã Bảo Bình',
          'Lam San Commune': 'Xã Lâm San',
          'Long Giao Town': 'Thị trấn Long Giao',
          'Nhan Nghia Commune': 'Xã Nhân Nghĩa',
          'Song Nhan Commune': 'Xã Sông Nhạn',
          'Song Ray Commune': 'Xã Sông Ray',
          'Thua Duc Commune': 'Xã Thừa Đức',
          'Xuan Bao Commune': 'Xã Xuân Bảo',
          'Xuan Dong Commune': 'Xã Xuân Đông',
          'Xuan Duong Commune': 'Xã Xuân Đường',
          'Xuan My Commune': 'Xã Xuân Mỹ',
          'Xuan Que Commune': 'Xã Xuân Quế',
          'Xuan Tay Commune': 'Xã Xuân Tây',
        },
      },
      'Long Thanh District': {
        name: 'Huyện Long Thành',
        wards: {
          'An Phuoc Commune': 'Xã An Phước',
          'Bau Can Commune': 'Xã Bàu Cạn',
          'Binh An Commune': 'Xã Bình An',
          'Binh Son Commune': 'Xã Bình Sơn',
          'Cam Duong Commune': 'Xã Cẩm Đường',
          'Loc An Commune': 'Xã Lộc An',
          'Long An Commune': 'Xã Long An',
          'Long Duc Commune': 'Xã Long Đức',
          'Long Phuoc Commune': 'Xã Long Phước',
          'Long Thanh Town': 'Thị trấn Long Thành',
          'Phuoc Binh Commune': 'Xã Phước Bình',
          'Phuoc Tan Commune': 'Xã Phước Tân',
          'Phuoc Thai Commune': 'Xã Phước Thái',
          'Suoi Trau Commune': 'Xã Suối Trầu',
          'Tam An Commune': 'Xã Tam An',
          'Tan Hiep Commune': 'Xã Tân Hiệp',
        },
      },
      'Xuan Loc District': {
        name: 'Huyện Xuân Lộc',
        wards: {
          'Bao Hoa Commune': 'Xã Bảo Hòa',
          'Gia Ray Town': 'Thị trấn Gia Ray',
          'Lang Minh Commune': 'Xã Lang Minh',
          'Suoi Cao Commune': 'Xã Suối Cao',
          'Suoi Cat Commune': 'Xã Suối Cát',
          'Xuan Bac Commune': 'Xã Xuân Bắc',
          'Xuan Dinh Commune': 'Xã Xuân Định',
          'Xuan Hiep Commune': 'Xã Xuân Hiệp',
          'Xuan Hoa Commune': 'Xã Xuân Hòa',
          'Xuan Hung Commune': 'Xã Xuân Hưng',
          'Xuan Phu Commune': 'Xã Xuân Phú',
          'Xuan Tam Commune': 'Xã Xuân Tâm',
          'Xuan Thanh Commune': 'Xã Xuân Thành',
          'Xuan Tho Commune': 'Xã Xuân Thọ',
          'Xuan Truong Commune': 'Xã Xuân Trường',
        },
      },
      'Nhon Trach District': {
        name: 'Huyện Nhơn Trạch',
        wards: {
          'Dai Phuoc Commune': 'Xã Đại Phước',
          'Hiep Phuoc Commune': 'Xã Hiệp Phước',
          'Long Tan Commune': 'Xã Long Tân',
          'Long Tho Commune': 'Xã Long Thọ',
          'Phu Dong Commune': 'Xã Phú Đông',
          'Phu Hoi Commune': 'Xã Phú Hội',
          'Phu Huu Commune': 'Xã Phú Hữu',
          'Phu Thanh Commune': 'Xã Phú Thạnh',
          'Phuoc An Commune': 'Xã Phước An',
          'Phuoc Khanh Commune': 'Xã Phước Khánh',
          'Phuoc Thien Commune': 'Xã Phước Thiền',
          'Vinh Thanh Commune': 'Xã Vĩnh Thanh',
        },
      },
    },
  },
};
