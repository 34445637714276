import { message } from 'antd';
import { fork, put, take, call } from 'redux-saga/effects';
import ActionTypes from './constants';
import { addStore } from 'utils/apollo';
import { createStore } from './action';
import { refetchBuyer } from 'containers/MainLayout/actions';
import translations from 'translations';
import utilsMessages from 'utils/messages';

function* createStoreFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.CREATE_STORE_REQUEST);
    const response = yield call(addStore, { input: payload });
    if (!response.errors) {
      yield put(createStore.success(response));
      yield put(refetchBuyer());
      message.success(translations(utilsMessages.createStoreSuccessfully));
    } else {
      yield put(createStore.failure(response.errors));
      message.error(translations(utilsMessages.createStoreFailure));
    }
  }
}

export default function* createStorePopupSaga() {
  yield fork(createStoreFlow);
}
