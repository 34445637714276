/*
 * MarketPage Messages
 *
 * This contains all the text for the MarketPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HomePage';

export default defineMessages({
  kamereo: {
    id: `${scope}.kamereo`,
    defaultMessage: 'KAMEREO',
  },
  dontShowThisAgain: {
    id: `${scope}.dontShowThisAgain`,
    defaultMessage: 'Không hiển thị thông tin này lần nữa',
  },
  homePage: {
    id: `${scope}.homePage`,
    defaultMessage: 'Trang chủ',
  },
});
