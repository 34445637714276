import gql from 'graphql-tag';

export default gql`
  query user($email: String, $id: ID) {
    user(email: $email, id: $id) {
      id
      name
      email
      status
      telephone
      imageUrl
      permissions {
        action
      }
      userGroups {
        groupId
        name
        permissions {
          action
        }
      }
    }
  }
`;
