import {
  notificationMarkAsRead,
  notificationTokenRegister,
  notificationTokenUnregister,
  getNotificationMessages,
} from 'utils/apollo';
import {
  notificationRegisterTokenAsyncAction,
  notificationUnregisterTokenAsyncAction,
  markNotificationAsReadAsyncAction,
  notificationsAsyncAction,
  setHasUnreadNotification,
} from './actions';
import { selectNotificationSubmitting, selectNotificationTokenId } from './selectors';
import { call, put, select, take } from 'redux-saga/effects';
import { NotificationMarkAsReadInput, NotificationMessage, ReadStatus } from 'types/schema';

import ActionTypes from './constants';

export function* registerToken() {
  while (true) {
    const { payload } = yield take(ActionTypes.NOTIFICATION_REGISTER_TOKEN);
    const isSubmitting = yield select(selectNotificationSubmitting()) || false;
    if (!isSubmitting) {
      yield put(notificationRegisterTokenAsyncAction.request());
      const response = yield call(notificationTokenRegister, { input: payload });
      if (!response.errors) {
        window.localStorage.setItem('notificationId', response.id);
        yield put(notificationRegisterTokenAsyncAction.success(response));
      } else {
        yield put(notificationRegisterTokenAsyncAction.failure(response.errors));
      }
    }
  }
}

export function* unregisterToken() {
  const notificationId = (yield select(selectNotificationTokenId())) || window.localStorage.getItem('notificationId');

  window.localStorage.removeItem('notificationId');
  if (notificationId) {
    const response = yield call(notificationTokenUnregister, { input: { id: notificationId } });
    if (!response.errors) {
      yield put(notificationUnregisterTokenAsyncAction.success(response));
    } else {
      yield put(notificationUnregisterTokenAsyncAction.failure(response.errors));
    }
  }
}

export function* markNotificationAsRead() {
  while (true) {
    const { payload } = yield take(ActionTypes.MARK_NOTIFICATION_AS_READ);
    const input: NotificationMarkAsReadInput = {
      messageId: payload.messageId,
      readStatus: ReadStatus.IsRead,
    };
    const response = yield call(notificationMarkAsRead, { input: input });
    if (!response.errors) {
      yield put(markNotificationAsReadAsyncAction.success(input));
    } else {
      yield put(markNotificationAsReadAsyncAction.failure(response.errors));
    }
  }
}

export function* notificationsFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.NOTIFICATIONS);
    const response = yield call(getNotificationMessages, { pagination: payload });
    if (!response.errors) {
      yield put(notificationsAsyncAction.success(response));
    } else {
      yield put(notificationsAsyncAction.failure(response.errors));
    }
  }
}

export function* checkHasUnreadNotification() {
  const response = yield call(getNotificationMessages, { pagination: { page: 0, size: 20 } });
  if (!response.errors) {
    const hasUnreadNotification = response.data.findIndex(
      (item: NotificationMessage) => item.readStatus === ReadStatus.NotRead,
    );
    yield put(setHasUnreadNotification(hasUnreadNotification !== -1));
  } else {
    yield put(setHasUnreadNotification(false));
  }
}
