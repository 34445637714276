import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import utilMessages from 'utils/messages';
import { FormattedMessage } from 'react-intl';

export const NoteSm = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  color: #8c8c8c;
  margin-top: 4px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const NoteAction = styled.div`
  flex: 1;
  display: flex;
  align-items: center !important;
  flex-direction: row;
  gap: 10px;
`;

const AddNoteButton = styled(Button)`
  color: #2f904f;
  font-size: 12px !important;
  padding: 0 !important;
`;

const DeleteNoteButton = styled(Button)`
  color: #f5222d;
  font-size: 12px !important;
  padding: 0 !important;
`;

interface IProps {
  isShowNote?: boolean;
  note?: string | ReactNode;
  onClickOpenNote: () => void;
  onClickDeleteNote: () => void;
}

const ProductNote: FC<IProps> = (props) => {
  const { isShowNote = true, note, onClickOpenNote, onClickDeleteNote } = props;

  if (!!note) {
    return (
      <NoteAction>
        {isShowNote && (typeof note === 'string' ? <NoteSm>{note}</NoteSm> : note)}
        <AddNoteButton type="link" size="small" onClick={onClickOpenNote}>
          <FormattedMessage {...utilMessages.edit} />
        </AddNoteButton>
        <DeleteNoteButton type="link" size="small" onClick={onClickDeleteNote}>
          <FormattedMessage {...utilMessages.remove} />
        </DeleteNoteButton>
      </NoteAction>
    );
  }
  return (
    <NoteAction>
      <AddNoteButton type="link" size="small" onClick={onClickOpenNote}>
        <FormattedMessage {...utilMessages.note} />
      </AddNoteButton>
    </NoteAction>
  );
};

export default ProductNote;
