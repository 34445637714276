/**
 * Create the store with dynamic reducers
 */

import { legacy_createStore as createStore, applyMiddleware, compose, Middleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createRootReducer from './reducers';
import { crashReporter } from './crashReporter';
import SplitIO from '@splitsoftware/splitio/types/splitio';
import { initSplitSdk } from '@splitsoftware/splitio-redux';
import split from './split/';

const sagaMiddleware = createSagaMiddleware();

const sdkBrowserConfig: SplitIO.IBrowserSettings = {
  core: { authorizationKey: String(process.env.SPLITIO_API_KEY), key: 'buyer-web' },
};

const multiActionMiddleware: Middleware = ({ dispatch }) => (next) => (action) => {
  if (Array.isArray(action)) {
    action.forEach((a) => dispatch(a));
  } else {
    next(action);
  }
}

declare interface IWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any; // redux-dev-tools definitions not needed
}
declare const window: IWindow;

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state

  const middlewares = [crashReporter, multiActionMiddleware, sagaMiddleware, routerMiddleware(history)];

  const enhancers = [applyMiddleware(...middlewares)];

  // tslint:disable-next-line:max-line-length
  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.APP_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const store = createStore(createRootReducer(), initialState, composeEnhancers(...enhancers)) as any;

  if (process.env.SPLITIO_ON === 'true') {
    sagaMiddleware.run(split.saga);
  }
  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module['hot']) {
    module['hot'].accept('./reducers', () => {
      store.replaceReducer(createRootReducer(store.injectedReducers));
    });
  }

  if (process.env.SPLITIO_ON === 'true') {
    initSplitSdk({ config: sdkBrowserConfig })(store.dispatch);
  }
  return store;
}
