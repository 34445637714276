import gql from 'graphql-tag';

export default gql`
  query userGroup($id: ID) {
    userGroup(id: $id) {
      groupId
      name
      active
      isSystemCreated
      permissions {
        action
      }
      members {
        id
        name
        email
      }
    }
  }
`;
