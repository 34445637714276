import { Col, Form, Modal, Row, Input as AInput } from 'antd';
import Address from 'components/Address';
import React, { FC, useMemo, useEffect } from 'react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';

import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch, compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styles/styled-components';
import { ApplicationRootState } from 'types';
import utilsMessages from 'utils/messages';
import orderMessage from '../../containers/OrdersPage/messages';
import storeMessage from '../../components/StoreForm/messages';

import createStoreBanner from './createStoreThumbnail.png';
import Input from 'components/TrackingComponents/Input';

import { selectBuyer } from 'containers/MainLayout/selectors';
import selectCreateStorePopupComponent from './selectors';
import { createStore } from './action';
import reducer from './reducer';
import saga from './saga';
import translations from 'translations';
import SVGIcon from '../SVGIcon/SVGIcon';
import LightBuldCircle from './LightbulbCircle.svg';

// import LocationFormSection from '../../components/LocationFormSection';

interface IStateProps {
  buyer: any;
  createStorePopupState: any;
}

interface IDispatchProps {
  createStoreInfo: (payload: any) => void;
}

interface IOwnProps {}

const PopupCreateStore = styled(Modal)`
  width: 100% !important;
  max-width: 652px;
  .ant-modal-content {
    padding: 24px !important;
  }
`;

const CreateStoreBannerImage = styled.div`
  background-image: url(${createStoreBanner});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding-top: 30%;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 12px !important;
`;
const TitleWrapper = styled.div`
  text-align: center;
  margin: 24px 0;
`;
const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 500;
`;
const FormSubTitle = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
const Note = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background: #e7f9ff;
  padding: 6px 4px;
  border-radius: 3px;
`;
const NoteText = styled.div`
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
`;

const AntdInput = styled(AInput)<any>``;

type Props = IStateProps & IDispatchProps & IOwnProps;
const CreateStorePopup: FC<Props> = (props) => {
  const { buyer, createStoreInfo, createStorePopupState } = props;
  const [form] = Form.useForm();

  const isOpenPopup = useMemo(() => {
    return buyer?.id && !buyer.stores?.length;
  }, [buyer]);

  const formatPhoneNumber = () => {
    if (form.getFieldError('telephone').length || !form.getFieldValue('telephone')) {
      return;
    }
    const regex = /[()+\-.\s]/g;
    const phoneNumberValue = form.getFieldValue('telephone');
    form.setFieldValue('telephone', phoneNumberValue.replace(regex, ''));
  };

  useEffect(() => {
    if (buyer.id && isOpenPopup) {
      form.setFieldsValue({
        name: buyer.name || undefined,
        telephone: buyer.telephone || undefined,
      });
    }
  }, [buyer.id, isOpenPopup]);
  return (
    <PopupCreateStore
      open={isOpenPopup}
      closeIcon={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ style: { width: '100%', margin: '0px' } }}
      okText={<FormattedMessage {...utilsMessages.finish} />}
      onOk={() => form.submit()}
      confirmLoading={createStorePopupState?.loading}
    >
      <CreateStoreBannerImage />
      <TitleWrapper>
        <FormTitle>
          <FormattedMessage {...utilsMessages.storeConfirm} />
        </FormTitle>
        <FormSubTitle>
          <FormattedMessage {...utilsMessages.storeConfimSubText} />
        </FormSubTitle>
      </TitleWrapper>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={(values) => {
          delete values.provinceId;
          delete values.districtId;
          delete values.addressDetail;
          createStoreInfo(values);
        }}
      >
        <Row gutter={12}>
          <Col span={12}>
            <FormattedMessage {...orderMessage.storeName}>
              {(storeName: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: storeName }}>
                  {(fieldRequired) => (
                    <FormattedMessage {...utilsMessages.storeNamePlaceHolder}>
                      {(storeNamePlaceHolder) => (
                        <FormItem
                          name="name"
                          colon={false}
                          required={false}
                          label={storeName}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: fieldRequired,
                            },
                          ]}
                        >
                          <Input
                            InputComponent={AntdInput}
                            trackingCategory="Create Store Form | Input"
                            trackingAction="Enter store name"
                            type="text"
                            placeHolder={storeNamePlaceHolder}
                          />
                        </FormItem>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
          <Col span={12}>
            <FormattedMessage {...utilsMessages.phone}>
              {(phone: string) => (
                <FormattedMessage {...utilsMessages.fieldRequired} values={{ field: phone }}>
                  {(fieldRequired) => (
                    <FormattedMessage {...utilsMessages.phonePlaceHolder}>
                      {(phonePlaceHolder) => (
                        <Form.Item
                          name="telephone"
                          label={phone}
                          colon={false}
                          rules={[
                            { required: true, message: fieldRequired },
                            {
                              pattern: /^(?=(?:[()+\-.\s]*\d){9,12}$)[()+\-.,\s\d]*$/,
                              message: translations(storeMessage.invalidPhoneNumber),
                            },
                          ]}
                        >
                          <Input
                            InputComponent={AntdInput}
                            trackingCategory="Create Store Form | Input"
                            trackingAction="Enter Phone"
                            type="text"
                            onBlur={formatPhoneNumber}
                            placeHolder={phonePlaceHolder}
                          />
                        </Form.Item>
                      )}
                    </FormattedMessage>
                  )}
                </FormattedMessage>
              )}
            </FormattedMessage>
          </Col>
        </Row>
        <Address
          form={form}
          fieldNameFullText={{
            city: ['location', 'city'],
            district: ['location', 'district'],
            addressDetail: ['location', 'address'],
            ward: ['location', 'ward'],
          }}
          fieldName={{
            city: 'provinceId',
            district: 'districtId',
            addressDetail: 'addressDetail',
            ward: 'wardId',
          }}
          provinceValue={buyer?.fullAddress?.provinceCode}
          districtValue={buyer?.fullAddress?.districtCode}
          wardValue={buyer?.fullAddress?.wardCode}
          addressDetailValue={buyer?.location?.address}
          regions={buyer?.region && [buyer?.region]}
        />
        <Note>
          <SVGIcon src={LightBuldCircle} />
          <NoteText>
            {translations(utilsMessages.storeNoteText).replace(
              '{region}',
              translations(utilsMessages[buyer?.region || '']),
            )}
          </NoteText>
        </Note>
      </Form>
    </PopupCreateStore>
  );
};

const mapStateToProps = createStructuredSelector<ApplicationRootState, IStateProps>({
  buyer: selectBuyer(),
  createStorePopupState: selectCreateStorePopupComponent(),
});

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => ({
  createStoreInfo: (payload: any) => dispatch(createStore.request(payload)),
});

const withConnect = connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<IOwnProps>({ key: 'createStorePopupComponent', reducer: reducer });

const withSaga = injectSaga<IOwnProps>({ key: 'createStorePopupComponent', saga: saga });

export default compose(withConnect, withReducer, withSaga)(CreateStorePopup);
