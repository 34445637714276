import gql from 'graphql-tag';

export default gql`
  mutation cartPutItemLight($input: CartPutItemInput!) {
    cartPutItemLight(input: $input) {
      success
      userErrors {
        field
        message
      }
      cart {
        id
        buyer {
          id
        }
        store {
          id
        }
        total
        carts {
          items {
            item {
              id
              vat
              note
              price
              name
              imageUrl
              uom
              uomLocal
            }
            total
            orderedQty
          }
          supplier {
            id
            name
            moq
            private
          }
          total
        }
      }
    }
  }
`;
