import { action, createAsyncAction } from 'typesafe-actions';
import ActionTypes from './constants';

export const fetchProvince = createAsyncAction(
  ActionTypes.FETCH_PROVINCE_REQUEST,
  ActionTypes.FETCH_PROVINCE_SUCCESS,
  ActionTypes.FETCH_PROVINCE_FAILURE,
)<void, {}, Error>();

export const fetchDistrict = createAsyncAction(
  ActionTypes.FETCH_DISTRICT_REQUEST,
  ActionTypes.FETCH_DISTRICT_SUCCESS,
  ActionTypes.FETCH_DISTRICT_FAILURE,
)<void, {}, Error>();

export const fetchWards = createAsyncAction(
  ActionTypes.FETCH_WARDS_REQUEST,
  ActionTypes.FETCH_WARDS_SUCCESS,
  ActionTypes.FETCH_WARDS_FAILURE,
)<void, {}, Error>();

export const getDistrictByProvince = (values: { provinceId: string }) =>
  action(ActionTypes.GET_DISTRICT_BY_PROVINCE, values);
export const getWardsByDistrict = (values: { districtId: string }) => action(ActionTypes.GET_WARDS_BY_DISTRICT, values);
