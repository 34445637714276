import React, { PropsWithChildren } from 'react';
import styled from 'styles/styled-components';

type Props = PropsWithChildren<{ asComponent?: string }>;

const VisuallyHidden = ({ asComponent, children, ...delegated }: Props) => {
  return (
    <Wrapper as={asComponent} {...delegated}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
`;

export default VisuallyHidden;
