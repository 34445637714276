import React from 'react';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setViewportWidth } from 'containers/MainLayout/actions';

export const Viewport = React.createContext({ width: 992 });

interface ViewportProviderState {
  width: number;
}

interface DispatchProps {
  setViewportWidth: (width: number) => void;
}

class ViewportProvider extends React.Component<DispatchProps, ViewportProviderState> {
  constructor(props) {
    super(props);
    this.state = {
      width: window.document.body.clientWidth,
    };
  }

  public updateDimensions = debounce(() => {
    const width = window.document.body.clientWidth;
    this.setState({ width: width });
    this.props.setViewportWidth(width);
  }, 300);

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public render() {
    const width = this.state.width;
    return <Viewport.Provider value={{ width: width }}>{this.props.children}</Viewport.Provider>;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  setViewportWidth: (width) => dispatch(setViewportWidth(width)),
});

export default connect(null, mapDispatchToProps)(ViewportProvider);
