import { call, fork, take, takeEvery } from 'redux-saga/effects';
import ActionTypes from './constants';
import { getProvince, getDistricts, getWards } from '../../utils/apollo';

function* getProvinceData({ payload: { cb, regions } }) {
  const response = yield call(getProvince, { pagination: { page: 1, size: 100 }, regions: regions });
  if (!response.errors) {
    // yield put(fetchProvince.success({ value: response.data, instanceId: instanceId }));
    if (cb) {
      cb(response.data);
    }
  }
}

function* getDistrictData() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_DISTRICT_BY_PROVINCE);
    const response = yield call(getDistricts, {
      pagination: { page: 1, size: 100 },
      provinceCodes: [payload.provinceId],
    });
    if (!response.errors) {
      // yield put(fetchDistrict.success({ instanceId: payload.instanceId, value: response.data }));
      if (payload.cb) {
        payload.cb(response.data);
      }
    }
  }
}

function* getWardsData() {
  while (true) {
    const { payload } = yield take(ActionTypes.GET_WARDS_BY_DISTRICT);
    const response = yield call(getWards, { pagination: { page: 1, size: 100 }, districtCodes: [payload.districtId] });
    if (!response.errors) {
      // yield put(fetchWards.success({ instanceId: payload.instanceId, value: response.data }));
      if (payload.cb) {
        payload.cb(response.data);
      }
    }
  }
}

export default function* addressComponentSaga() {
  // yield fork(getProvinceData);
  yield fork(getDistrictData);
  yield fork(getWardsData);
  yield takeEvery(ActionTypes.FETCH_PROVINCE_REQUEST, getProvinceData);
}
