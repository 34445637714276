import { Language as GeneratedLanguage } from 'types/schema';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const KAMEREO_SUPPLIER_ID = 654;
export const PHONE_NUMBER = 'tel:+84812463727';

export enum ExtendedLanguage {
  Alternate = GeneratedLanguage.En,
}

export const LANGUAGES = {
  ...GeneratedLanguage,
  ...ExtendedLanguage,
};
