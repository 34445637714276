import gql from 'graphql-tag';

export default gql`
  query productSearch($sort: [ProductSort!]!, $filter: ProductFilter, $pagination: Pagination!) {
    productSearch(sort: $sort, filter: $filter, pagination: $pagination) {
      totalResults
      totalPage
      data {
        id
        supplier {
          id
          name
          private
          offMarket
          imageUrl
          location {
            city
            district
            address
          }
        }
        imageUrl
        note
        supplierInternalCode
        origin
        preservation
        vat
        uom
        uomLocal
        price
        originalPrice
        category {
          name
          parent {
            name
          }
        }
        inStock
        name
        description
        isFavorite
        tags
        allowAddToCart
        private
        allowPurchase
      }
    }
  }
`;
