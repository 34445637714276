import gql from 'graphql-tag';

export default gql`
  mutation deliveryNoteUpdate($id: ID!, $text: String!) {
    deliveryNoteUpdate(id: $id, text: $text) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
