import gql from 'graphql-tag';

export default gql`
  query districtSearch(
    $pagination: Pagination!
    $provinceCodes: [String]
    $districtCodes: [String]
    $orderBy: [OrderBy]
  ) {
    districtSearch(
      pagination: $pagination
      provinceCodes: $provinceCodes
      districtCodes: $districtCodes
      orderBy: $orderBy
    ) {
      total
      data {
        code
        name
        nameEn
        fullName
        fullNameEn
        provinceCode
      }
    }
  }
`;
