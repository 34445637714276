import gql from 'graphql-tag';

export default gql`
  query order($id: ID!) {
    order(id: $id) {
      id
      total
      items {
        total
        price
        note
        orderedPrice
        product {
          id
          name
          note
          price
          vat
          uomLocal
          uom
          inStock
          supplierInternalCode
        }
        deliveryQuantity
        orderedQuantity
        deliveredNote

        buyerNote
      }
      orderDate
      deliveryTime {
        start
        end
      }
      status
      statusText
      note
      buyer {
        id
        name
      }
      store {
        id
        name
      }
      supplier {
        id
        name
        imageUrl
        location {
          address
          district
          city
        }
      }
      creator {
        id
        name
      }
      paymentMethod
      declineReason
      redelivery
      orderType
    }
  }
`;
