const formatMoney = (money: number) => {
  return money ? money.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,') : 0;
};

const formattedMoneyToNumber = (money: string) => {
  return Number(money.replace(/,/g, ''));
};

export default formatMoney;
export { formatMoney, formattedMoneyToNumber };
