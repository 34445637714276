/*
 * Side nav Messages
 *
 * This contains all the text for the Side nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.QuantityInput';

export default defineMessages({
  inputQuantity: {
    id: `${scope}.inputQuantity`,
    defaultMessage: 'Nhập số lượng',
  },
  invalidQuantity: {
    id: `${scope}.invalidQuantity`,
    defaultMessage: 'Số lượng không đúng định dạng.',
  },
  notAllowedDecimal: {
    id: `${scope}.notAllowedDecimal`,
    defaultMessage: 'Sản phẩm này không thể nhập số thập phân',
  },
});
