export default {
  'Ho Chi Minh': {
    name: 'Ho Chi Minh',
    districts: {
      'District 1': {
        name: 'District 1',
        wards: {
          'Ben Nghe Ward': 'Ben Nghe Ward',
          'Ben Thanh Ward': 'Ben Thanh Ward',
          'Cau Kho Ward': 'Cau Kho Ward',
          'Cau Ong Lanh Ward': 'Cau Ong Lanh Ward',
          'Co Giang Ward': 'Co Giang Ward',
          'Da Kao Ward': 'Da Kao Ward',
          'Nguyen Cu Trinh Ward': 'Nguyen Cu Trinh Ward',
          'Nguyen Thai Binh Ward': 'Nguyen Thai Binh Ward',
          'Pham Ngu Lao Ward': 'Pham Ngu Lao Ward',
          'Tan Dinh Ward': 'Tan Dinh Ward',
        },
      },
      'District 2': {
        name: 'District 2',
        wards: {
          'Thanh My Loi Ward': 'Thanh My Loi Ward',
          'An Khanh Ward': 'An Khanh Ward',
          'An Loi Dong Ward': 'An Loi Dong Ward',
          'An Phu Ward': 'An Phu Ward',
          'Binh An Ward': 'Binh An Ward',
          'Binh Khanh Ward': 'Binh Khanh Ward',
          'Binh Trung Dong Ward': 'Binh Trung Dong Ward',
          'Binh Trung Tay Ward': 'Binh Trung Tay Ward',
          'Cat Lai Ward': 'Cat Lai Ward',
          'Thao Dien Ward': 'Thao Dien Ward',
          'Thu Thiem Ward': 'Thu Thiem Ward',
        },
      },
      'District 3': {
        name: 'District 3',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 4': {
        name: 'District 4',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 16': 'Ward 16',
          'Ward 18': 'Ward 18',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 5': {
        name: 'District 5',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 6': {
        name: 'District 6',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 7': {
        name: 'District 7',
        wards: {
          'Binh Thuan Ward': 'Binh Thuan Ward',
          'Phu My Ward': 'Phu My Ward',
          'Phu Thuan Ward': 'Phu Thuan Ward',
          'Tan Hung Ward': 'Tan Hung Ward',
          'Tan Kieng Ward': 'Tan Kieng Ward',
          'Tan Phong Ward': 'Tan Phong Ward',
          'Tan Phu Ward': 'Tan Phu Ward',
          'Tan Quy Ward': 'Tan Quy Ward',
          'Tan Thuan Dong Ward': 'Tan Thuan Dong Ward',
          'Tan Thuan Tay Ward': 'Tan Thuan Tay Ward',
        },
      },
      'District 8': {
        name: 'District 8',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 16': 'Ward 16',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 9': {
        name: 'District 9',
        wards: {
          'Hiep Phu Ward': 'Hiep Phu Ward',
          'Long Binh Ward': 'Long Binh Ward',
          'Long Phuoc Ward': 'Long Phuoc Ward',
          'Long Thanh My Ward': 'Long Thanh My Ward',
          'Long Truong Ward': 'Long Truong Ward',
          'Phu Huu Ward': 'Phu Huu Ward',
          'Phuoc Binh Ward': 'Phuoc Binh Ward',
          'Phuoc Long A Ward': 'Phuoc Long A Ward',
          'Phuoc Long B Ward': 'Phuoc Long B Ward',
          'Tan Phu Ward': 'Tan Phu Ward',
          'Tang Nhon Phu A Ward': 'Tang Nhon Phu A Ward',
          'Tang Nhon Phu B Ward': 'Tang Nhon Phu B Ward',
          'Truong Thanh Ward': 'Truong Thanh Ward',
        },
      },
      'District 10': {
        name: 'District 10',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 11': {
        name: 'District 11',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 16': 'Ward 16',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'District 12': {
        name: 'District 12',
        wards: {
          'An Phu Dong Ward': 'An Phu Dong Ward',
          'Dong Hung Thuan Ward': 'Dong Hung Thuan Ward',
          'Hiep Thanh Ward': 'Hiep Thanh Ward',
          'Tan Chanh Hiep Ward': 'Tan Chanh Hiep Ward',
          'Tan Hung Thuan Ward': 'Tan Hung Thuan Ward',
          'Tan Thoi Hiep Ward': 'Tan Thoi Hiep Ward',
          'Tan Thoi Nhat Ward': 'Tan Thoi Nhat Ward',
          'Thanh Loc Ward': 'Thanh Loc Ward',
          'Thanh Xuan Ward': 'Thanh Xuan Ward',
          'Thoi An Ward': 'Thoi An Ward',
          'Trung My Tay Ward': 'Trung My Tay Ward',
        },
      },
      'Binh Tan District': {
        name: 'Binh Tan District',
        wards: {
          'An Lac Ward': 'An Lac Ward',
          'An Lac A Ward': 'An Lac A Ward',
          'Binh Hung Hoa Ward': 'Binh Hung Hoa Ward',
          'Binh Hung Hoa A Ward': 'Binh Hung Hoa A Ward',
          'Binh Hung Hoa B Ward': 'Binh Hung Hoa B Ward',
          'Binh Tri Dong Ward': 'Binh Tri Dong Ward',
          'Binh Tri Dong A Ward': 'Binh Tri Dong A Ward',
          'Binh Tri Dong B Ward': 'Binh Tri Dong B Ward',
          'Tan Tao Ward': 'Tan Tao Ward',
          'Tan Tao A Ward': 'Tan Tao A Ward',
        },
      },
      'Binh Thanh District': {
        name: 'Binh Thanh District',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 17': 'Ward 17',
          'Ward 19': 'Ward 19',
          'Ward 2': 'Ward 2',
          'Ward 21': 'Ward 21',
          'Ward 22': 'Ward 22',
          'Ward 24': 'Ward 24',
          'Ward 25': 'Ward 25',
          'Ward 26': 'Ward 26',
          'Ward 27': 'Ward 27',
          'Ward 28': 'Ward 28',
          'Ward 3': 'Ward 3',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
        },
      },
      'Go Vap District': {
        name: 'Go Vap District',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 16': 'Ward 16',
          'Ward 17': 'Ward 17',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'Phu Nhuan District': {
        name: 'Phu Nhuan District',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 17': 'Ward 17',
          'Ward 2': 'Ward 2',
          'Ward 25': 'Ward 25',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'Tan Binh District': {
        name: 'Tan Binh District',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 10': 'Ward 10',
          'Ward 11': 'Ward 11',
          'Ward 12': 'Ward 12',
          'Ward 13': 'Ward 13',
          'Ward 14': 'Ward 14',
          'Ward 15': 'Ward 15',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'Ward 8': 'Ward 8',
          'Ward 9': 'Ward 9',
        },
      },
      'Tan Phu District': {
        name: 'Tan Phu District',
        wards: {
          'Hiep Tan Ward': 'Hiep Tan Ward',
          'Hoa Thanh Ward': 'Hoa Thanh Ward',
          'Phu Thanh Ward': 'Phu Thanh Ward',
          'Phu Tho Hoa Ward': 'Phu Tho Hoa Ward',
          'Phu Trung Ward': 'Phu Trung Ward',
          'Son Ky Ward': 'Son Ky Ward',
          'Tan Quy Ward': 'Tan Quy Ward',
          'Tan Son Nhi Ward': 'Tan Son Nhi Ward',
          'Tan Thanh Ward': 'Tan Thanh Ward',
          'Tan Thoi Hoa Ward': 'Tan Thoi Hoa Ward',
          'Tay Thanh Ward': 'Tay Thanh Ward',
        },
      },
      'Thu Duc District': {
        name: 'Thu Duc District',
        wards: {
          'Binh Chieu Ward': 'Binh Chieu Ward',
          'Binh Tho Ward': 'Binh Tho Ward',
          'Hiep Binh Chanh Ward': 'Hiep Binh Chanh Ward',
          'Hiep Binh Phuoc Ward': 'Hiep Binh Phuoc Ward',
          'Linh Chieu Ward': 'Linh Chieu Ward',
          'Linh Dong Ward': 'Linh Dong Ward',
          'Linh Tay Ward': 'Linh Tay Ward',
          'Linh Trung Ward': 'Linh Trung Ward',
          'Linh Xuan Ward': 'Linh Xuan Ward',
          'Tam Binh Ward': 'Tam Binh Ward',
          'Tam Phu Ward': 'Tam Phu Ward',
          'Truong Tho Ward': 'Truong Tho Ward',
        },
      },
      'Nha Be District': {
        name: 'Nha Be District',
        wards: {
          'Hiep Phuoc Ward': 'Hiep Phuoc Ward',
          'Long Thoi Commune': 'Long Thoi Commune',
          'Nha Be Town': 'Nha Be Town',
          'Nhon Duc Commune': 'Nhon Duc Commune',
          'Phu Xuan Commune': 'Phu Xuan Commune',
          'Phuoc Kien Commune': 'Phuoc Kien Commune',
          'Phuoc Loc Commune': 'Phuoc Loc Commune',
        },
      },
      'Binh Chanh District': {
        name: 'Binh Chanh District',
        wards: {
          'An Phu Tay Commune': 'An Phu Tay Commune',
          'Binh Chanh Commune': 'Binh Chanh Commune',
          'Binh Hung Commune': 'Binh Hung Commune',
          'Binh Loi Commune': 'Binh Loi Commune',
          'Da Phuoc Commune': 'Da Phuoc Commune',
          'Hung Long Commune': 'Hung Long Commune',
          'Le Minh Xuan Commune': 'Le Minh Xuan Commune',
          'Pham Van Hai Commune': 'Pham Van Hai Commune',
          'Phong Phu Commune': 'Phong Phu Commune',
          'Quy Duc Commune': 'Quy Duc Commune',
          'Tan Kien Commune': 'Tan Kien Commune',
          'Tan Nhut Commune': 'Tan Nhut Commune',
          'Tan Quy Tay Commune': 'Tan Quy Tay Commune',
          'Tan Tuc Town': 'Tan Tuc Town',
          'Vinh Loc A Commune': 'Vinh Loc A Commune',
          'Vinh Loc B Commune': 'Vinh Loc B Commune',
        },
      },
      'Hoc Mon District': {
        name: 'Hoc Mon District',
        wards: {
          'Dong Thanh Commune': 'Dong Thanh Commune',
          'Hoc Mon Ward': 'Hoc Mon Ward',
          'Ba Diem Ward': 'Ba Diem Ward',
          'Nhi Binh Commune': 'Nhi Binh Commune',
          'Tan Hiep Commune': 'Tan Hiep Commune',
          'Tan Thoi Nhi Commune': 'Tan Thoi Nhi Commune',
          'Tan Xuan Commune': 'Tan Xuan Commune',
          'Thoi Tam Thon Commune': 'Thoi Tam Thon Commune',
          'Trung Chanh Commune': 'Trung Chanh Commune',
          'Xuan Thoi Dong Commune': 'Xuan Thoi Dong Commune',
          'Xuan Thoi Son Commune': 'Xuan Thoi Son Commune',
          'Xuan Thoi Thuong Commune': 'Xuan Thoi Thuong Commune',
        },
      },
      'Can Gio District': {
        name: 'Can Gio District',
        wards: {
          'An Thoi Dong Commune': 'An Thoi Dong Commune',
          'Binh Khanh Commune': 'Binh Khanh Commune',
          'Can Thanh Ward': 'Can Thanh Ward',
          'Long Hoa Commune': 'Long Hoa Commune',
          'Ly Nhon Commune': 'Ly Nhon Commune',
          'Tam Thon Hiep Commune': 'Tam Thon Hiep Commune',
          'Thanh An Commune': 'Thanh An Commune',
        },
      },
      'Cu Chi District': {
        name: 'Cu Chi District',
        wards: {
          'An Nhon Tay Commune': 'An Nhon Tay Commune',
          'An Phu Commune': 'An Phu Commune',
          'An Phu Trung Commune': 'An Phu Trung Commune',
          'Binh My Commune': 'Binh My Commune',
          'Cu Chi Town': 'Cu Chi Town',
          'Hoa Phu Commune': 'Hoa Phu Commune',
          'Nhuan Duc Commune': 'Nhuan Duc Commune',
          'Pham Van Coi Commune': 'Pham Van Coi Commune',
          'Phu Hoa Dong Commune': 'Phu Hoa Dong Commune',
          'Phu My Hung Commune': 'Phu My Hung Commune',
          'Phuoc Hiep Commune': 'Phuoc Hiep Commune',
          'Phuoc Thanh Commune': 'Phuoc Thanh Commune',
          'Phuoc Vinh An Commune': 'Phuoc Vinh An Commune',
          'Tan An Hoi Commune': 'Tan An Hoi Commune',
          'Tan Phu Trung Commune': 'Tan Phu Trung Commune',
          'Tan Thanh Dong Commune': 'Tan Thanh Dong Commune',
          'Tan Thanh Tay Commune': 'Tan Thanh Tay Commune',
          'Tan Thong Hoi Commune': 'Tan Thong Hoi Commune',
          'Thai My Commune': 'Thai My Commune',
          'Trung An Commune': 'Trung An Commune',
          'Trung Lap Ha Commune': 'Trung Lap Ha Commune',
          'Trung Lap Thuong Commune': 'Trung Lap Thuong Commune',
        },
      },
    },
  },
  'Binh Duong': {
    name: 'Binh Duong',
    districts: {
      'Thu Dau Mot City': {
        name: 'Thu Dau Mot City',
        wards: {
          'Chanh My Commune': 'Chanh My Commune',
          'Chanh Nghia Ward': 'Chanh Nghia Ward',
          'Dinh Hoa Ward': 'Dinh Hoa Ward',
          'Hiep An Ward': 'Hiep An Ward',
          'Hiep Thanh Ward': 'Hiep Thanh Ward',
          'Hoa Phu Ward': 'Hoa Phu Ward',
          'Phu Cuong Ward': 'Phu Cuong Ward',
          'Phu Hoa Ward': 'Phu Hoa Ward',
          'Phu Loi Ward': 'Phu Loi Ward',
          'Phu My Ward': 'Phu My Ward',
          'Phu Tan Ward': 'Phu Tan Ward',
          'Phu Tho Ward': 'Phu Tho Ward',
          'Tan An Commune': 'Tan An Commune',
          'Tuong Binh Hiep Commune': 'Tuong Binh Hiep Commune',
        },
      },
      'Bau Bang District': {
        name: 'Bau Bang District',
        wards: {
          'Cay Truong II Commune': 'Cay Truong II Commune',
          'Hung Hoa Commune': 'Hung Hoa Commune',
          'Lai Hung Commune': 'Lai Hung Commune',
          'Lai Uyen Commune': 'Lai Uyen Commune',
          'Long Nguyen Commune': 'Long Nguyen Commune',
          'Tan Hung Commune': 'Tan Hung Commune',
          'Tru Van Tho Commune': 'Tru Van Tho Commune',
        },
      },
      'Dau Tieng District': {
        name: 'Dau Tieng District',
        wards: {
          'An Lap Commune': 'An Lap Commune',
          'Dau Tieng Ward': 'Dau Tieng Ward',
          'Dinh  Hiep Commune': 'Dinh  Hiep Commune',
          'Dinh An Commune': 'Dinh An Commune',
          'Dinh Thanh Commune': 'Dinh Thanh Commune',
          'Long Hoa Commune': 'Long Hoa Commune',
          'Long Tan Commune': 'Long Tan Commune',
          'Minh Hoa Commune': 'Minh Hoa Commune',
          'Minh Tan Commune': 'Minh Tan Commune',
          'Minh Thanh Commune': 'Minh Thanh Commune',
          'Thanh An Commune': 'Thanh An Commune',
          'Thanh Tuyen Commune': 'Thanh Tuyen Commune',
        },
      },
      'Ben Cat Town': {
        name: 'Ben Cat Town',
        wards: {
          'An Dien Commune': 'An Dien Commune',
          'An Tay Commune': 'An Tay Commune',
          'Cay Truong Commune': 'Cay Truong Commune',
          'Chanh Phu Hoa Commune': 'Chanh Phu Hoa Commune',
          'Hoa Loi Ward': 'Hoa Loi Ward',
          'My Phuoc Ward': 'My Phuoc Ward',
          'Phu An Commune': 'Phu An Commune',
          'Tan Dinh Commune': 'Tan Dinh Commune',
          'Thoi Hoa Commune': 'Thoi Hoa Commune',
        },
      },
      'Phu Giao District': {
        name: 'Phu Giao District',
        wards: {
          'An Binh Commune': 'An Binh Commune',
          'An Linh Commune': 'An Linh Commune',
          'An Long Commune': 'An Long Commune',
          'An Thai Commune': 'An Thai Commune',
          'Phuoc Hoa Commune': 'Phuoc Hoa Commune',
          'Phuoc Sang Commune': 'Phuoc Sang Commune',
          'Phuoc Vinh Ward': 'Phuoc Vinh Ward',
          'Tam Lap Commune': 'Tam Lap Commune',
          'Tan Hiep Commune': 'Tan Hiep Commune',
          'Tan Long Commune': 'Tan Long Commune',
          'Vinh Hoa Commune': 'Vinh Hoa Commune',
        },
      },
      'Tan Uyen Town': {
        name: 'Tan Uyen Town',
        wards: {
          'Bach Dang Commune': 'Bach Dang Commune',
          'Hoi Nghia Commune': 'Hoi Nghia Commune',
          'Khanh Binh Commune': 'Khanh Binh Commune',
          'Phu Chanh Commune': 'Phu Chanh Commune',
          'Tan Hiep Commune': 'Tan Hiep Commune',
          'Tan Phuoc Khanh Ward': 'Tan Phuoc Khanh Ward',
          'Tan Vinh Hiep Commune': 'Tan Vinh Hiep Commune',
          'Thai Hoa Ward': 'Thai Hoa Ward',
          'Thanh Hoi Commune': 'Thanh Hoi Commune',
          'Thanh Phuoc Commune': 'Thanh Phuoc Commune',
          'Uyen Hung Ward': 'Uyen Hung Ward',
          'Vinh Tan Commune': 'Vinh Tan Commune',
        },
      },
      'Di An City': {
        name: 'Di An City',
        wards: {
          'An Binh Ward': 'An Binh Ward',
          'Binh An Ward': 'Binh An Ward',
          'Binh Thang Ward': 'Binh Thang Ward',
          'Di An Ward': 'Di An Ward',
          'Dong Hoa Ward': 'Dong Hoa Ward',
          'Tan Binh Ward': 'Tan Binh Ward',
          'Tan Dong Hiep Ward': 'Tan Dong Hiep Ward',
        },
      },
      'Thuan An City': {
        name: 'Thuan An City',
        wards: {
          'An Phu Ward': 'An Phu Ward',
          'An Son Commune': 'An Son Commune',
          'An Thanh Ward': 'An Thanh Ward',
          'Binh Chuan Ward': 'Binh Chuan Ward',
          'Binh Hoa Ward': 'Binh Hoa Ward',
          'Binh Nham Commune': 'Binh Nham Commune',
          'Hung Dinh Commune': 'Hung Dinh Commune',
          'Lai Thieu Ward': 'Lai Thieu Ward',
          'Thuan Giao Ward': 'Thuan Giao Ward',
          'Vinh Phu Ward': 'Vinh Phu Ward',
        },
      },
      'Bac Tan Uyen District': {
        name: 'Bac Tan Uyen District',
        wards: {
          'Binh My Commune': 'Binh My Commune',
          'Dat Cuoc Commune': 'Dat Cuoc Commune',
          'Hieu Liem Commune': 'Hieu Liem Commune',
          'Lac An Commune': 'Lac An Commune',
          'Tan Binh Commune': 'Tan Binh Commune',
          'Tan Dinh Commune': 'Tan Dinh Commune',
          'Tan Lap Commune': 'Tan Lap Commune',
          'Tan My Commune': 'Tan My Commune',
          'Tan Thanh Commune': 'Tan Thanh Commune',
          'Thuong Tan Commune': 'Thuong Tan Commune',
        },
      },
    },
  },
  'Long An': {
    name: 'Long An',
    districts: {
      'Tan An City': {
        name: 'Tan An City',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Ward 4': 'Ward 4',
          'Ward 5': 'Ward 5',
          'Ward 6': 'Ward 6',
          'Ward 7': 'Ward 7',
          'An Vinh Ngai Commune': 'An Vinh Ngai Commune',
          'Binh Tam Commune': 'Binh Tam Commune',
          'Huong Tho Phu Commune': 'Huong Tho Phu Commune',
          'Khanh Hau Ward': 'Khanh Hau Ward',
          'Loi Binh Nhon Commune': 'Loi Binh Nhon Commune',
          'Nhon Thanh Trung Commune': 'Nhon Thanh Trung Commune',
          'Tan Khanh Ward': 'Tan Khanh Ward',
        },
      },
      'Kien Tuong Town': {
        name: 'Kien Tuong Town',
        wards: {
          'Ward 1': 'Ward 1',
          'Ward 2': 'Ward 2',
          'Ward 3': 'Ward 3',
          'Binh Hiep Commune': 'Binh Hiep Commune',
          'Binh Tan Commune': 'Binh Tan Commune',
          'Thanh Hung Commune': 'Thanh Hung Commune',
          'Thanh Tri Commune': 'Thanh Tri Commune',
          'Tuyen Thanh Commune': 'Tuyen Thanh Commune',
        },
      },
      'Tan Hung District': {
        name: 'Tan Hung District',
        wards: {
          'Binh Thanh Town': 'Binh Thanh Town',
          'Hung Dien Commune': 'Hung Dien Commune',
          'Hung Dien B Commune': 'Hung Dien B Commune',
          'Hung Ha Commune': 'Hung Ha Commune',
          'Hung Thanh Commune': 'Hung Thanh Commune',
          'Tan Hung Town': 'Tan Hung Town',
          'Thanh Hung Commune': 'Thanh Hung Commune',
          'Vinh Buu Commune': 'Vinh Buu Commune',
          'Vinh Chau A Commune': 'Vinh Chau A Commune',
          'Vinh Chau B Commune': 'Vinh Chau B Commune',
          'Vinh Dai Commune': 'Vinh Dai Commune',
          'Vinh Loi Commune': 'Vinh Loi Commune',
          'Vinh Thanh Commune': 'Vinh Thanh Commune',
        },
      },
      'Vinh Hung District': {
        name: 'Vinh Hung District',
        wards: {
          'Hung Dien A Commune': 'Hung Dien A Commune',
          'Khanh Hung Commune': 'Khanh Hung Commune',
          'Thai Binh Trung Commune': 'Thai Binh Trung Commune',
          'Thai Tri Commune': 'Thai Tri Commune',
          'Tuyen Binh Commune': 'Tuyen Binh Commune',
          'Tuyen Binh Tay Commune': 'Tuyen Binh Tay Commune',
          'Vinh Binh Commune': 'Vinh Binh Commune',
          'Vinh Hung Town': 'Vinh Hung Town',
          'Vinh Thuan Commune': 'Vinh Thuan Commune',
          'Vinh Tri Commune': 'Vinh Tri Commune',
        },
      },
      'Moc Hoa District': {
        name: 'Moc Hoa District',
        wards: {
          'Binh Hoa Dong Commune': 'Binh Hoa Dong Commune',
          'Binh Hoa Tay Commune': 'Binh Hoa Tay Commune',
          'Binh Hoa Trung Commune': 'Binh Hoa Trung Commune',
          'Binh Phong Thanh Commune': 'Binh Phong Thanh Commune',
          'Binh Thanh Commune': 'Binh Thanh Commune',
          'Tan Lap Commune': 'Tan Lap Commune',
          'Tan Thanh Commune': 'Tan Thanh Commune',
        },
      },
      'Tan Thanh District': {
        name: 'Tan Thanh District',
        wards: {
          'Bac Hoa Commune': 'Bac Hoa Commune',
          'Hau Thanh Dong Commune': 'Hau Thanh Dong Commune',
          'Hau Thanh Tay Commune': 'Hau Thanh Tay Commune',
          'Kien Binh Commune': 'Kien Binh Commune',
          'Nhon Hoa Commune': 'Nhon Hoa Commune',
          'Nhon Hoa Lap Commune': 'Nhon Hoa Lap Commune',
          'Nhon Ninh Commune': 'Nhon Ninh Commune',
          'Tan Binh Commune': 'Tan Binh Commune',
          'Tan Hoa Commune': 'Tan Hoa Commune',
          'Tan Lap Commune': 'Tan Lap Commune',
          'Tan Ninh Commune': 'Tan Ninh Commune',
          'Tan Thanh Commune': 'Tan Thanh Commune',
          'Tan Thanh Town': 'Tan Thanh Town',
        },
      },
      'Thanh Hoa District': {
        name: 'Thanh Hoa District',
        wards: {
          'Tan Dong Commune': 'Tan Dong Commune',
          'Tan Hiep Commune': 'Tan Hiep Commune',
          'Tan Tay Commune': 'Tan Tay Commune',
          'Thanh An Commune': 'Thanh An Commune',
          'Thanh Hoa Town': 'Thanh Hoa Town',
          'Thanh Phu Commune': 'Thanh Phu Commune',
          'Thanh Phuoc Commune': 'Thanh Phuoc Commune',
          'Thuan Binh Commune': 'Thuan Binh Commune',
          'Thuan Nghia Hoa Commune': 'Thuan Nghia Hoa Commune',
          'Thuy Dong Commune': 'Thuy Dong Commune',
          'Thuy Tay Commune': 'Thuy Tay Commune',
        },
      },
      'Duc Hue District': {
        name: 'Duc Hue District',
        wards: {
          'Binh Hoa Bac Commune': 'Binh Hoa Bac Commune',
          'Binh Hoa Hung Commune': 'Binh Hoa Hung Commune',
          'Binh Hoa Nam Commune': 'Binh Hoa Nam Commune',
          'Binh Thanh Commune': 'Binh Thanh Commune',
          'Dong Thanh Town': 'Dong Thanh Town',
          'My Binh Commune': 'My Binh Commune',
          'My Quy Dong Commune': 'My Quy Dong Commune',
          'My Quy Tay Commune': 'My Quy Tay Commune',
          'My Thanh Bac Commune': 'My Thanh Bac Commune',
          'My Thanh Dong Commune': 'My Thanh Dong Commune',
          'My Thanh Tay Commune': 'My Thanh Tay Commune',
        },
      },
      'Duc Hoa District': {
        name: 'Duc Hoa District',
        wards: {
          'An Ninh Dong Commune': 'An Ninh Dong Commune',
          'An Ninh Tay Commune': 'An Ninh Tay Commune',
          'Duc Hoa Town': 'Duc Hoa Town',
          'Duc Hoa Dong Commune': 'Duc Hoa Dong Commune',
          'Duc Hoa Ha Commune': 'Duc Hoa Ha Commune',
          'Duc Hoa Thuong Commune': 'Duc Hoa Thuong Commune',
          'Duc Lap Ha Commune': 'Duc Lap Ha Commune',
          'Duc Lap Thuong Commune': 'Duc Lap Thuong Commune',
          'Hau Nghia Town': 'Hau Nghia Town',
          'Hiep Hoa Town': 'Hiep Hoa Town',
          'Hiep Hoa Commune': 'Hiep Hoa Commune',
          'Hoa Khanh Dong Commune': 'Hoa Khanh Dong Commune',
          'Hoa Khanh Nam Commune': 'Hoa Khanh Nam Commune',
          'Hoa Khanh Tay Commune': 'Hoa Khanh Tay Commune',
          'Huu Thanh Commune': 'Huu Thanh Commune',
          'Loc Giang Commune': 'Loc Giang Commune',
          'My Hanh Bac Commune': 'My Hanh Bac Commune',
          'My Hanh Nam Commune': 'My Hanh Nam Commune',
          'Tan My Commune': 'Tan My Commune',
          'Tan Phu Commune': 'Tan Phu Commune',
        },
      },
      'Ben Luc District': {
        name: 'Ben Luc District',
        wards: {
          'An Thanh Commune': 'An Thanh Commune',
          'Ben Luc Town': 'Ben Luc Town',
          'Binh Duc Commune': 'Binh Duc Commune',
          'Long Hiep Commune': 'Long Hiep Commune',
          'Luong Binh Commune': 'Luong Binh Commune',
          'Luong Hoa Commune': 'Luong Hoa Commune',
          'My Yen Commune': 'My Yen Commune',
          'Nhut Chanh Commune': 'Nhut Chanh Commune',
          'Phuoc Loi Commune': 'Phuoc Loi Commune',
          'Tan Buu Commune': 'Tan Buu Commune',
          'Tan Hoa Commune': 'Tan Hoa Commune',
          'Thanh Duc Commune': 'Thanh Duc Commune',
          'Thanh Hoa Commune': 'Thanh Hoa Commune',
          'Thanh Loi Commune': 'Thanh Loi Commune',
          'Thanh Phu Commune': 'Thanh Phu Commune',
        },
      },
      'Thu Thua District': {
        name: 'Thu Thua District',
        wards: {
          'Binh An Commune': 'Binh An Commune',
          'Binh Thanh Commune': 'Binh Thanh Commune',
          'Long Thanh Commune': 'Long Thanh Commune',
          'Long Thuan Commune': 'Long Thuan Commune',
          'My An Commune': 'My An Commune',
          'My Lac Commune': 'My Lac Commune',
          'My Phu Commune': 'My Phu Commune',
          'My Thanh Commune': 'My Thanh Commune',
          'Nhi Thanh Commune': 'Nhi Thanh Commune',
          'Tan Lap Commune': 'Tan Lap Commune',
          'Tan Thanh Commune': 'Tan Thanh Commune',
          'Thu Thua Commune': 'Thu Thua Commune',
        },
      },
      'Tan Tru District': {
        name: 'Tan Tru District',
        wards: {
          'An Nhut Tan Commune': 'An Nhut Tan Commune',
          'Binh Lang Commune': 'Binh Lang Commune',
          'Binh Tinh Commune': 'Binh Tinh Commune',
          'Binh Trinh Dong Commune': 'Binh Trinh Dong Commune',
          'Duc Tan Commune': 'Duc Tan Commune',
          'Lac Tan Commune': 'Lac Tan Commune',
          'My Binh Commune': 'My Binh Commune',
          'Nhut Ninh Commune': 'Nhut Ninh Commune',
          'Que My Thanh Commune': 'Que My Thanh Commune',
          'Tan Phuoc Tay Commune': 'Tan Phuoc Tay Commune',
          'Tan Tru Town': 'Tan Tru Town',
        },
      },
      'Can Duoc District': {
        name: 'Can Duoc District',
        wards: {
          'Can Duoc Town': 'Can Duoc Town',
          'Long Cang Commune': 'Long Cang Commune',
          'Long Dinh Commune': 'Long Dinh Commune',
          'Long Hoa Commune': 'Long Hoa Commune',
          'Long Huu Dong Commune': 'Long Huu Dong Commune',
          'Long Huu Tay Commune': 'Long Huu Tay Commune',
          'Long Khe Commune': 'Long Khe Commune',
          'Long Son Commune': 'Long Son Commune',
          'Long Thanh Commune': 'Long Thanh Commune',
          'Long Trach Commune': 'Long Trach Commune',
          'My Le Commune': 'My Le Commune',
          'Phuoc Dong Commune': 'Phuoc Dong Commune',
          'Phuoc Tuy Commune': 'Phuoc Tuy Commune',
          'Phuoc Van Commune': 'Phuoc Van Commune',
          'Tan An Commune': 'Tan An Commune',
          'Tan Chanh Commune': 'Tan Chanh Commune',
          'Tan Lan Commune': 'Tan Lan Commune',
          'Tan Trach Commune': 'Tan Trach Commune',
        },
      },
      'Can Giuoc District': {
        name: 'Can Giuoc District',
        wards: {
          'Can Giuoc Town': 'Can Giuoc Town',
          'Dong Thanh Commune': 'Dong Thanh Commune',
          'Long An Commune': 'Long An Commune',
          'Long Hau Commune': 'Long Hau Commune',
          'Long Phung Commune': 'Long Phung Commune',
          'Long Thuong Commune': 'Long Thuong Commune',
          'My Loc Commune': 'My Loc Commune',
          'Phuoc Hau Commune': 'Phuoc Hau Commune',
          'Phuoc Lai Commune': 'Phuoc Lai Commune',
          'Phuoc Lam Commune': 'Phuoc Lam Commune',
          'Phuoc Ly Commune': 'Phuoc Ly Commune',
          'Phuoc Vinh Dong Commune': 'Phuoc Vinh Dong Commune',
          'Phuoc Vinh Tay Commune': 'Phuoc Vinh Tay Commune',
          'Tan Kim Commune': 'Tan Kim Commune',
          'Tan Tap Commune': 'Tan Tap Commune',
          'Thuan Thanh Commune': 'Thuan Thanh Commune',
          'Truong Binh Commune': 'Truong Binh Commune',
        },
      },
      'Chau Thanh District': {
        name: 'Chau Thanh District',
        wards: {
          'An Luc Long Commune': 'An Luc Long Commune',
          'Binh Quoi Commune': 'Binh Quoi Commune',
          'Duong Xuan Hoi Commune': 'Duong Xuan Hoi Commune',
          'Hiep Thanh Commune': 'Hiep Thanh Commune',
          'Hoa Phu Commune': 'Hoa Phu Commune',
          'Long Tri Commune': 'Long Tri Commune',
          'Phu Ngai Tri Commune': 'Phu Ngai Tri Commune',
          'Phuoc Tan Hung Commune': 'Phuoc Tan Hung Commune',
          'Tam Vu Town': 'Tam Vu Town',
          'Thanh Phu Long Commune': 'Thanh Phu Long Commune',
          'Thanh Vinh Dong Commune': 'Thanh Vinh Dong Commune',
          'Thuan My Commune': 'Thuan My Commune',
          'Vinh Cong Commune': 'Vinh Cong Commune',
        },
      },
    },
  },
  'Dong Nai': {
    name: 'Dong Nai',
    districts: {
      'Bien Hoa City': {
        name: 'Bien Hoa City',
        wards: {
          'An Binh Ward': 'An Binh Ward',
          'An Hoa Commune': 'An Hoa Commune',
          'Bau Hang Ward': 'Bau Hang Ward',
          'Binh Da Ward': 'Binh Da Ward',
          'Buu Hoa Ward': 'Buu Hoa Ward',
          'Buu Long Ward': 'Buu Long Ward',
          'Hiep Hoa Commune': 'Hiep Hoa Commune',
          'Ho Can Ward': 'Ho Can Ward',
          'Ho Nai Ward': 'Ho Nai Ward',
          'Hoa An Ward': 'Hoa An Ward',
          'Hoa Binh Ward': 'Hoa Binh Ward',
          'Long Binh Ward': 'Long Binh Ward',
          'Long Binh Tan Ward': 'Long Binh Tan Ward',
          'Long Hung Commune': 'Long Hung Commune',
          'Phuoc Tan Commune': 'Phuoc Tan Commune',
          'Quang Vinh Ward': 'Quang Vinh Ward',
          'Quyet Thang Ward': 'Quyet Thang Ward',
          'Tam Hiep Ward': 'Tam Hiep Ward',
          'Tam Hoa Ward': 'Tam Hoa Ward',
          'Tam Phuoc Commune': 'Tam Phuoc Commune',
          'Tan Bien Ward': 'Tan Bien Ward',
          'Tan Hanh Commune': 'Tan Hanh Commune',
          'Tan Hiep Ward': 'Tan Hiep Ward',
          'Tan Hoa Ward': 'Tan Hoa Ward',
          'Tan Long Ward': 'Tan Long Ward',
          'Tan Mai Ward': 'Tan Mai Ward',
          'Tan Phong Ward': 'Tan Phong Ward',
          'Tan Tien Ward': 'Tan Tien Ward',
          'Tan Van Ward': 'Tan Van Ward',
          'Thanh Binh Ward': 'Thanh Binh Ward',
          'Thong Nhat Ward': 'Thong Nhat Ward',
          'Trang Dai Ward': 'Trang Dai Ward',
          'Trung Dung Ward': 'Trung Dung Ward',
        },
      },
      'Long Khanh City': {
        name: 'Long Khanh City',
        wards: {
          'Bao Hoa Commune': 'Bao Hoa Commune',
          'Bao Quang Commune': 'Bao Quang Commune',
          'Bao Vinh Commune': 'Bao Vinh Commune',
          'Bau Sen Commune': 'Bau Sen Commune',
          'Bau Tram Commune': 'Bau Tram Commune',
          'Binh Loc Commune': 'Binh Loc Commune',
          'Hang Gon Commune': 'Hang Gon Commune',
          'Phu Binh Ward': 'Phu Binh Ward',
          'Suoi Tre Commune': 'Suoi Tre Commune',
          'Xuan An Ward': 'Xuan An Ward',
          'Xuan Binh Ward': 'Xuan Binh Ward',
          'Xuan Hoa Ward': 'Xuan Hoa Ward',
          'Xuan Lap Commune': 'Xuan Lap Commune',
          'Xuan Tan Commune': 'Xuan Tan Commune',
          'Xuan Thanh Ward': 'Xuan Thanh Ward',
          'Xuan Trung Ward': 'Xuan Trung Ward',
        },
      },
      'Tan Phu District': {
        name: 'Tan Phu District',
        wards: {
          'Dak Lua Commune': 'Dak Lua Commune',
          'Nam Cat Tien Commune': 'Nam Cat Tien Commune',
          'Nui Tuong Commune': 'Nui Tuong Commune',
          'Phu An Commune': 'Phu An Commune',
          'Phu Binh Commune': 'Phu Binh Commune',
          'Phu Dien Commune': 'Phu Dien Commune',
          'Phu Lam Commune': 'Phu Lam Commune',
          'Phu Lap Commune': 'Phu Lap Commune',
          'Phu Loc Commune': 'Phu Loc Commune',
          'Phu Son Commune': 'Phu Son Commune',
          'Phu Thanh Commune': 'Phu Thanh Commune',
          'Phu Thinh Commune': 'Phu Thinh Commune',
          'Phu Trung Commune': 'Phu Trung Commune',
          'Phu Xuan Commune': 'Phu Xuan Commune',
          'Ta Lai Commune': 'Ta Lai Commune',
          'Tan Phu Town': 'Tan Phu Town',
          'Thanh Son Commune': 'Thanh Son Commune',
          'Tra Co Commune': 'Tra Co Commune',
        },
      },
      'Vinh Cuu District': {
        name: 'Vinh Cuu District',
        wards: {
          'Binh Hoa Commune': 'Binh Hoa Commune',
          'Binh Loi Commune': 'Binh Loi Commune',
          'Hieu Liem Commune': 'Hieu Liem Commune',
          'Ma Da Commune': 'Ma Da Commune',
          'Phu Ly Commune': 'Phu Ly Commune',
          'Tan An Commune': 'Tan An Commune',
          'Tan Binh Commune': 'Tan Binh Commune',
          'Thanh Phu Commune': 'Thanh Phu Commune',
          'Thien Tan Commune': 'Thien Tan Commune',
          'Tri An Commune': 'Tri An Commune',
          'Vinh An Town': 'Vinh An Town',
          'Vinh Tan Commune': 'Vinh Tan Commune',
        },
      },
      'Dinh Quan District': {
        name: 'Dinh Quan District',
        wards: {
          'Dinh Quan Town': 'Dinh Quan Town',
          'Gia Canh Commune': 'Gia Canh Commune',
          'La Nga Commune': 'La Nga Commune',
          'Ngoc Dinh Commune': 'Ngoc Dinh Commune',
          'Phu Cuong Commune': 'Phu Cuong Commune',
          'Phu Hoa Commune': 'Phu Hoa Commune',
          'Phu Loi Commune': 'Phu Loi Commune',
          'Phu Ngoc Commune': 'Phu Ngoc Commune',
          'Phu Tan Commune': 'Phu Tan Commune',
          'Phu Tuc Commune': 'Phu Tuc Commune',
          'Phu Vinh Commune': 'Phu Vinh Commune',
          'Suoi Nho Commune': 'Suoi Nho Commune',
          'Thanh Son Commune': 'Thanh Son Commune',
          'Tuc Trung Commune': 'Tuc Trung Commune',
        },
      },
      'Trang Bom District': {
        name: 'Trang Bom District',
        wards: {
          'An Vien Commune': 'An Vien Commune',
          'Bac Son Commune': 'Bac Son Commune',
          'Bau Ham Commune': 'Bau Ham Commune',
          'Binh Minh Commune': 'Binh Minh Commune',
          'Cay Gao Commune': 'Cay Gao Commune',
          'Doi 61 Commune': 'Doi 61 Commune',
          'Dong Hoa Commune': 'Dong Hoa Commune',
          'Giang Dien Commune': 'Giang Dien Commune',
          'Ho Nai 3 Commune': 'Ho Nai 3 Commune',
          'Hoa Trung Commune': 'Hoa Trung Commune',
          'Hung Thinh Commune': 'Hung Thinh Commune',
          'Quang Tien Commune': 'Quang Tien Commune',
          'Song Thao Commune': 'Song Thao Commune',
          'Song Trau Commune': 'Song Trau Commune',
          'Tay Hoa Commune': 'Tay Hoa Commune',
          'Thanh Binh Commune': 'Thanh Binh Commune',
          'Trang Bom Town': 'Trang Bom Town',
          'Trung Hoa Commune': 'Trung Hoa Commune',
        },
      },
      'Thong Nhat District': {
        name: 'Thong Nhat District',
        wards: {
          'Bau Ham 2 Commune': 'Bau Ham 2 Commune',
          'Dau Giay Town': 'Dau Giay Town',
          'Gia Kiem Commune': 'Gia Kiem Commune',
          'Gia Tan 1 Commune': 'Gia Tan 1 Commune',
          'Gia Tan 2 Commune': 'Gia Tan 2 Commune',
          'Gia Tan 3 Commune': 'Gia Tan 3 Commune',
          'Hung Loc Commune': 'Hung Loc Commune',
          'Lo 25 Commune': 'Lo 25 Commune',
          'Quang Trung Commune': 'Quang Trung Commune',
          'Xuan Thanh Commune': 'Xuan Thanh Commune',
          'Xuan Thien Commune': 'Xuan Thien Commune',
        },
      },
      'Cam My District': {
        name: 'Cam My District',
        wards: {
          'Bao Binh Commune': 'Bao Binh Commune',
          'Lam San Commune': 'Lam San Commune',
          'Long Giao Town': 'Long Giao Town',
          'Nhan Nghia Commune': 'Nhan Nghia Commune',
          'Song Nhan Commune': 'Song Nhan Commune',
          'Song Ray Commune': 'Song Ray Commune',
          'Thua Duc Commune': 'Thua Duc Commune',
          'Xuan Bao Commune': 'Xuan Bao Commune',
          'Xuan Dong Commune': 'Xuan Dong Commune',
          'Xuan Duong Commune': 'Xuan Duong Commune',
          'Xuan My Commune': 'Xuan My Commune',
          'Xuan Que Commune': 'Xuan Que Commune',
          'Xuan Tay Commune': 'Xuan Tay Commune',
        },
      },
      'Long Thanh District': {
        name: 'Long Thanh District',
        wards: {
          'An Phuoc Commune': 'An Phuoc Commune',
          'Bau Can Commune': 'Bau Can Commune',
          'Binh An Commune': 'Binh An Commune',
          'Binh Son Commune': 'Binh Son Commune',
          'Cam Duong Commune': 'Cam Duong Commune',
          'Loc An Commune': 'Loc An Commune',
          'Long An Commune': 'Long An Commune',
          'Long Duc Commune': 'Long Duc Commune',
          'Long Phuoc Commune': 'Long Phuoc Commune',
          'Long Thanh Town': 'Long Thanh Town',
          'Phuoc Binh Commune': 'Phuoc Binh Commune',
          'Phuoc Tan Commune': 'Phuoc Tan Commune',
          'Phuoc Thai Commune': 'Phuoc Thai Commune',
          'Suoi Trau Commune': 'Suoi Trau Commune',
          'Tam An Commune': 'Tam An Commune',
          'Tan Hiep Commune': 'Tan Hiep Commune',
        },
      },
      'Xuan Loc District': {
        name: 'Xuan Loc District',
        wards: {
          'Bao Hoa Commune': 'Bao Hoa Commune',
          'Gia Ray Town': 'Gia Ray Town',
          'Lang Minh Commune': 'Lang Minh Commune',
          'Suoi Cao Commune': 'Suoi Cao Commune',
          'Suoi Cat Commune': 'Suoi Cat Commune',
          'Xuan Bac Commune': 'Xuan Bac Commune',
          'Xuan Dinh Commune': 'Xuan Dinh Commune',
          'Xuan Hiep Commune': 'Xuan Hiep Commune',
          'Xuan Hoa Commune': 'Xuan Hoa Commune',
          'Xuan Hung Commune': 'Xuan Hung Commune',
          'Xuan Phu Commune': 'Xuan Phu Commune',
          'Xuan Tam Commune': 'Xuan Tam Commune',
          'Xuan Thanh Commune': 'Xuan Thanh Commune',
          'Xuan Tho Commune': 'Xuan Tho Commune',
          'Xuan Truong Commune': 'Xuan Truong Commune',
        },
      },
      'Nhon Trach District': {
        name: 'Nhon Trach District',
        wards: {
          'Dai Phuoc Commune': 'Dai Phuoc Commune',
          'Hiep Phuoc Commune': 'Hiep Phuoc Commune',
          'Long Tan Commune': 'Long Tan Commune',
          'Long Tho Commune': 'Long Tho Commune',
          'Phu Dong Commune': 'Phu Dong Commune',
          'Phu Hoi Commune': 'Phu Hoi Commune',
          'Phu Huu Commune': 'Phu Huu Commune',
          'Phu Thanh Commune': 'Phu Thanh Commune',
          'Phuoc An Commune': 'Phuoc An Commune',
          'Phuoc Khanh Commune': 'Phuoc Khanh Commune',
          'Phuoc Thien Commune': 'Phuoc Thien Commune',
          'Vinh Thanh Commune': 'Vinh Thanh Commune',
        },
      },
    },
  },
};
