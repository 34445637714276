/**
 *
 * MainLayout
 *
 */

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';
import React from 'react';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import makeSelectMainLayout, {
  selectPendingApprovalMode,
  selectCountPendingOrder,
  selectBuyerWebVersion,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import {
  sideNavToggle,
  signout,
  setLang,
  notificationRegisterTokenAction,
  setSearchProductResults,
  buyerWebVersion,
} from './actions';
import { RootState, IMainLayoutStateProps, IMainLayoutDispatchProps } from './types';
import { NotificationRegisterInput } from 'types/schema';
import { replace } from 'connected-react-router';
import LoadingIndicator from 'components/LoadingIndicator';

const MainLayout = React.lazy(() => import(/* webpackPreload: true */ 'components/MainLayout'));

const mapStateToProps = createStructuredSelector<RootState, IMainLayoutStateProps>({
  mainLayout: makeSelectMainLayout(),
  isPenddingApproval: selectPendingApprovalMode(),
  countPendingOrder: selectCountPendingOrder(),
  buyerWebVersion: selectBuyerWebVersion(),
});

function mapDispatchToProps(dispatch: Dispatch): IMainLayoutDispatchProps {
  return {
    dispatch: dispatch,
    toggleSideNav: () => dispatch(sideNavToggle()),
    signout: () => dispatch(signout()),
    setLang: (data: any) => dispatch(setLang(data)),
    registerNotificationToken: (input: NotificationRegisterInput) => {
      dispatch(notificationRegisterTokenAction(input));
    },
    goHome: () => dispatch(replace('/')),
    setSearchProductResults: (payload: any) => dispatch(setSearchProductResults.request(payload)),
    clearSearchProductResults: () => dispatch(setSearchProductResults.success({})),
    fetchBuyerWebVersion: () => dispatch(buyerWebVersion()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'main', reducer: reducer });
const withSaga = injectSaga({ key: 'main', saga: saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)((props) => (
  <React.Suspense fallback={<LoadingIndicator />}>
    <MainLayout {...props} />
  </React.Suspense>
));
