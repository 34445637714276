import gql from 'graphql-tag';

export default gql`
  query addStoreDisabled {
    addStoreDisabled {
      addStoreDisable
      message
    }
  }
`;
