import Button from '../../Button/Button';
import styled from 'styled-components';

export const QuantityButton = styled(Button)`
  ${({ className }) => {
    if (className === 'minus') {
      return `
        border: 1px solid #F5F5F5;
        border-radius: 8px !important;
        span {
          color: #54a46e;
          display: flex;
          justify-content: center;
        }
        :disabled {
          span {
            color: rgba(0, 0, 0, 0.25);
          }
        }
    `;
    }

    return `
      border: 1px solid #43A047;
      border-radius: 8px !important;
      span {
        color: #ffffff;
        display: flex;
        justify-content: center;
        & > svg {
          width: 1.3em !important;
          height: 1.3em !important;
        }
      }
      && {
        background: #43A047;
      }
      :hover, :focus, :active {
        &:not(:disabled) {
          opacity: .7;
          border: 1px solid #43A047 !important;
          background: #43A047 !important;
        }
      }
      :disabled {
        span {
          color: rgba(0, 0, 0, 0.25);
        }
      }
    `;
  }};

  &.ant-btn-round {
    width: 32px !important;
    height: 32px !important;
    padding: 0 15px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
  }

  &:before {
    opacity: 0;
    background-color: #54a46e;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &.ant-btn-sm.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 20px;
  }

  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only)
    .anticon:not(:last-child) {
    margin-left: -9px;
  }
`;
