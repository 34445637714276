import React, { Suspense } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import GateLayout from '../containers/GateLayout';
import LoadingIndicator from 'components/LoadingIndicator';
import { ErrorBoundary } from 'components/CategoryNav/ErrorBoundary';

interface GateLayoutProps extends RouteProps {
  component: any;
}

export default function({ component: Component, ...rest }: GateLayoutProps) {
  const loggedIn = !!localStorage.getItem('token');

  return (
    <Route
      {...rest}
      render={matchProps =>
        loggedIn ? (
          <GateLayout size="large">
            <Suspense fallback={<LoadingIndicator />}>
              <ErrorBoundary>
                <Component {...matchProps} />
              </ErrorBoundary>
            </Suspense>
          </GateLayout>
        ) : (
          <Redirect
            push
            to={{
              pathname: '/signin',
              search: `?redirect=${window.location.pathname}${window.location.search}`,
              state: { referrer: matchProps.location },
            }}
          />
        )
      }
    />
  );
}
