import gql from 'graphql-tag';

export default gql`
  mutation orderUpdateUnitPrice($input: OrderUpdateUnitPriceInput!) {
    orderUpdateUnitPrice(input: $input) {
      userErrors {
        field
        message
      }
      success
      order {
        id
        total
        items {
          total
          price
          note
          orderedPrice
          product {
            id
            name
            note
            price
            vat
            uom
            uomLocal
            inStock
          }
          deliveryQuantity
          orderedQuantity
          deliveredNote
          buyerNote
        }

        status
        note
      }
    }
  }
`;
