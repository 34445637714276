import gql from 'graphql-tag';

export default gql`
  query categoryDetail($id: ID!) {
    categoryDetail(id: $id) {
      id
      slug
      name
      nameLocal
      description
      descriptionVn
      thumbnail
      parentId
    }
  }
`;