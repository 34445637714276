import React, { RefObject, useEffect } from 'react';

const useImageSize = (element: RefObject<HTMLImageElement>) => {
  const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateSize = () => {
      if (element.current) {
        setImageSize({
          width: element.current.height,
          height: element.current.width,
        });
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [element.current]);

  return imageSize;
};

export default useImageSize;
