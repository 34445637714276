import * as qs from 'utils/queryString';

import { ApplicationRootState } from 'types';
import { createSelector } from 'reselect';
import { initialState } from './reducer';
import { Category } from 'types/schema';
import { createSearchQueryfromSlug, getPathSegmentFromSlug } from 'utils/getPathSegmentFromSlug';

const selectRoute = (state: ApplicationRootState) => {
  return state.router;
};

const makeSelectLocation = () => createSelector(selectRoute, (routeState) => routeState.location);

/**
 * Direct selector to the mainLayout state domain
 */

const selectMainLayoutDomain = (state: ApplicationRootState) => {
  return state ? state.main : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by MainLayout
 */

const selectMainLayout = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return substate;
  });

const selectCountPendingOrder = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).orderCountSummary.countPendingOrders;
  });

const selectStore = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return ((substate as any) || {}).store;
  });

const selectStores = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).buyer.stores;
  });

const selectCart = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).cart;
  });

const selectAddedProducts = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).addedProducts;
  });

const selectUser = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return ((substate as any) || {}).user;
  });

const selectUserPermissions = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    if (!substate) {
      return [];
    }
    const user = (substate as any).user;
    const permissions = (user.permissions || []).map((permission) => permission.action);
    (user.userGroups || []).forEach((group) => {
      group.permissions.forEach((permission) => {
        if (permissions.indexOf(permission.action) === -1) {
          permissions.push(permission.action);
        }
      });
    });
    return permissions;
  });

const selectBuyer = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return substate?.buyer;
  });

const selectPendingApprovalMode = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return ((substate as any).buyer.enabledFeatures || []).includes('ORDER_APPROVAL');
  });

const selectUserGroups = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).buyer.userGroups;
  });

const selectIsFreeshipOffer = () => createSelector(selectMainLayoutDomain, (substate) => {
  return (substate as any).buyer.isFreeshipOffer;
});

const selectCategories = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).categories;
  });

const selectAddingProducts = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).addingProducts;
  });

const selectUpdateQuantityProducts = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).updateQuantityProducts;
  });

const selectSelectedCategory = () => (state: any) => {
  if (state) {
    const substate = state.main;
    const location = state.router.location;
    const segment = getPathSegmentFromSlug(location);
    const searchQuery = createSearchQueryfromSlug(location, segment);
    const search = qs.parse(searchQuery);
    if (search.filter && search.filter.categoryName) {
      const name = search.filter.categoryName;
      const categories = (substate as any).categories;
      const parent = categories.find((c: Category) => name.indexOf(c.name) > -1);
      if (parent && name.indexOf('/') > -1) {
        return parent.children.find((c: Category) => c.name === name);
      }
      return parent;
    }
    return null;
  }
};

const selectSuppliers = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).suppliers;
  });
const selectOrderDisabled = () =>
  createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).orderDisabled;
  });

const selectNotificationTokenId = () => {
  return createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).notification.id;
  });
};

const selectNotificationSubmitting = () => {
  return createSelector(selectMainLayoutDomain, (substate) => {
    return (substate as any).notification.submitting;
  });
};

const selectNotificationData = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.notificationMessages;
  });
};

const selectHasUnreadNotification = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.hasUnreadNotification;
  });
};

const selectBreadCrumbDataMarkup = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.breadCrumbDataMarkup;
  });
};

const selectPromotionDataPopup = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.promotionDataPopup;
  });
};

const selectSearchProductResults = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.searchProductResults;
  });
};

const selectBuyerWebVersion = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.buyerWebVersion;
  });
};

const selectUnder18State = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.isUnder18;
  });
};

const selectViewportWidth = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.viewportWidth;
  });
}

const selectLang = () => {
  return createSelector(selectMainLayoutDomain, (subState) => {
    return subState.lang;
  });
};

export default selectMainLayout;

export {
  makeSelectLocation,
  selectMainLayoutDomain,
  selectStores,
  selectStore,
  selectCart,
  selectUser,
  selectBuyer,
  selectIsFreeshipOffer,
  selectCategories,
  selectSelectedCategory,
  selectAddedProducts,
  selectAddingProducts,
  selectSuppliers,
  selectUserGroups,
  selectUserPermissions,
  selectPendingApprovalMode,
  selectCountPendingOrder,
  selectUpdateQuantityProducts,
  selectOrderDisabled,
  selectNotificationTokenId,
  selectNotificationSubmitting,
  selectNotificationData,
  selectHasUnreadNotification,
  selectBreadCrumbDataMarkup,
  selectPromotionDataPopup,
  selectSearchProductResults,
  selectBuyerWebVersion,
  selectUnder18State,
  selectViewportWidth,
  selectLang
};
