import { transform, isArray, isObject, camelCase } from 'lodash';

/**
 *
 * @param obj an object or an array of object
 * @returns new camel version of input
 */
export const camelize = <TOut, TIn = any>(obj: TIn): TOut =>
  transform(obj as unknown as object, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(String(key));
    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });
