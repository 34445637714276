interface ITag {
  name: string;
  nameLocal: string;
  value: string;
}

export const tags: ITag[] = [
  {
    name: 'Combo',
    nameLocal: 'Combo',
    value: 'SUPER_COMBO',
  },
];

export const ALL_PRODUCTS = 'All';
