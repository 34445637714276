import styled from 'styled-components';
import React from 'react';

export const DetailsContainerInner = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  cursor: auto;
  padding: 8px;
  height: 100%;
`;

function swallowClicks(event) {
  event.stopPropagation();
}

export const DetailsContainer = props => <DetailsContainerInner onClick={swallowClicks} {...props} />;
