const IMAGE_ORIGIN = [
  'images-handler.kamereo.vn',
  'images-handler.qa.kamereo.vn',
  'image-handler-prod.s3-ap-southeast-1.amazonaws.com/supplier',
  'image-handler-qa.s3-ap-southeast-1.amazonaws.com/supplier',
];

const isValidImageURL = (url: string): boolean => {
  const urlString = `^(http|https)://(${IMAGE_ORIGIN.join('|')})/[^ "]+$`;
  const urlRegex = new RegExp(urlString);
  return urlRegex.test(url);
};

export default isValidImageURL;
