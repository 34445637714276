import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectCreateStorePopupComponentDomain = (state: ApplicationRootState) => {
  return state ? state.createStorePopupComponent : initialState;
};

const selectCreateStorePopupComponent = () =>
  createSelector(selectCreateStorePopupComponentDomain, (substate) => substate);

export default selectCreateStorePopupComponent;
export { selectCreateStorePopupComponentDomain };
