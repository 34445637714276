const context = require['context']('./', true, /\.(svg)$/);
const files: {
  [key: string]: any;
} = {};

context.keys().forEach((filename) => {
  files[filename.replace('./', '').replace('.svg', '')] = context(filename);
});

export default files;
