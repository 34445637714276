import gql from 'graphql-tag';

export default gql`
  mutation productSetNote($input: ProductNoteInput!) {
    productSetNote(input: $input) {
      userErrors {
        field
        message
      }
      success
      product {
        id
        note
        name
      }
    }
  }
`;
