import Button from '../Button/Button';
import Heart from './Heart.svg';
import { Modal } from 'antd';
import React from 'react';
import SVGIcon from '../SVGIcon/SVGIcon';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import media from 'utils/mediaStyle';

export const FavoriteIcon = styled(SVGIcon)`
  width: 16px;
  svg {
    width: 100%;
  }
  path {
    fill: #ffffff;
  }
`;
export const Favorite = styled(Button)<any>`
  position: ${({ position }) => position || 'absolute'};
  z-index: 2;
  box-shadow: none;
  border: none;
  top: ${({ alignto }) => (alignto === 'bottom' ? 'auto' : '8px')};
  bottom: ${({ alignto }) => (alignto === 'bottom' ? '4px' : 'auto')};
  right: 8px;
  min-width: 24px !important;
  height: 24px;
  background: #ffffff;
  :active,
  :focus {
    background: #ffffff;
  }
  :hover {
    background: #ffffff;
    ${FavoriteIcon} {
      ${({ favorite }) =>
        favorite === 'true'
          ? `
        animation: heartBeat 0.8s infinite;
      `
          : ``};
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  ${FavoriteIcon} {
    path {
      fill: ${({ favorite }) => (favorite === 'true' ? '#FF4852' : '#B4B4B4')};
    }
  }

  ${media.md`
    top: ${({ alignto }) => (alignto === 'auto' ? 'auto' : '16px')};
    right: ${({ alignto }) => (alignto === 'auto' ? 'auto' : '16px')};
  `};
`;

const FavoriteContainer = ({ onClick, intl, favorite, ...rest }: any) => {
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (favorite === 'false') {
      return onClick(event);
    }
    const removeFavoriteMessage = intl.formatMessage(utilsMessages.removeFavoriteMessage);
    const yes = intl.formatMessage(utilsMessages.yes);
    const no = intl.formatMessage(utilsMessages.no);
    Modal.confirm({
      autoFocusButton: null,
      title: removeFavoriteMessage,
      okText: yes,
      cancelText: no,
      onOk: () => {
        onClick(event);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  return (
    <Favorite type="secondary" shape="circle" favorite={favorite} {...rest} onClick={handleClick}>
      <FavoriteIcon renderAsImg={false} src={Heart} />
    </Favorite>
  );
};

export default injectIntl(FavoriteContainer);
