import gql from 'graphql-tag';

export default gql`
  query brands(
    $name: String
    $categoryName: String
    $categoryIds: [Int]
    $brandIds: [Int!]
    $slug: String
    $pagination: Pagination!
    $groupName: String
  ) {
    brands(
      name: $name
      categoryName: $categoryName
      categoryIds: $categoryIds
      brandIds: $brandIds
      slug: $slug
      pagination: $pagination
      groupName: $groupName
    ) {
      totalResults
      totalPage
      data {
        id
        slug
        name
        imageUrl
        description
        description_vi
        imageUrl
        createdAt
        updatedAt
        productCategories {
          id
          slug
          name
          activated
        }
      }
    }
  }
`;
