import React, { ReactElement, useImperativeHandle } from 'react';

interface TrackingEventHandlers {
  onBlur: (...args: any[]) => any;
  onFocus: (...args: any[]) => any;
}

export interface TrackingOptions {
  action: string;
  category: string;
  label?: string;
}

export interface Props {
  children: ReactElement;
  options: TrackingOptions;
  [key: string]: any;
}

const TrackingInput: React.ForwardRefRenderFunction<any, Props> = ({ children, options, ...restProps }, ref) => {
  const inputRef = React.useRef();
  useImperativeHandle(ref, () => inputRef.current!);
  const { onBlur, onFocus } = children.props || ({} as TrackingEventHandlers);
  const trackingOnFocus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
  };
  const trackingOnBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  return React.cloneElement(children, {
    ...restProps,
    onFocus: trackingOnFocus,
    onBlur: trackingOnBlur,
    ref: inputRef,
  });
};

export default React.forwardRef(TrackingInput);
