import Alcohol from './Alcohol.svg';
import Beverage from './Beverage.svg';
import Dairy from './Dairy.svg';
import DryFood from './DryFood.svg';
import Meat from './Meat.svg';
import NoImage from './noIMG.svg';
import NonFood from './NonFood.svg';
import SeaFood from './SeaFood.svg';
import Seasoning from './Seasoning.svg';
import Veggies from './Veggies.svg';

export default {
  Alcohol: Alcohol,
  Beverage: Beverage,
  Dairy: Dairy,
  'Dry Food': DryFood,
  Meat: Meat,
  'Non-food': NonFood,
  'Sea Food': SeaFood,
  Seasoning: Seasoning,
  Veggies: Veggies,
  NoImage: NoImage,
};
