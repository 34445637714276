import gql from 'graphql-tag';

export default gql`
  mutation userGroupUpdate($input: UserGroupUpdateInput!) {
    userGroupUpdate(input: $input) {
      success
      userErrors {
        field
        message
      }
      userGroup {
        groupId
        name
        active
        permissions {
          action
        }
        members {
          id
          name
          email
        }
      }
    }
  }
`;
