import React from 'react';
import styled from 'styled-components';

/**
 * Enhances a component with optional styled-component styles.
 * @param Component - The React component to enhance.
 * @param styles - Optional styles to apply.
 * @returns A styled component if styles are provided, otherwise the original component.
 */
function withStyledComponent<T>(Component: React.ComponentType<T>, styles?: string) {
  // If styles are provided, return a styled component with those styles.
  if (styles) {
    return styled(Component)`
      ${styles}
    `;
  }

  // If no styles are provided, return the original component.
  // This is essentially a no-op enhancement.
  return styled(Component)``;
}

export default withStyledComponent;
