/*
 * SignupPage Messages
 *
 * This contains all the text for the SignupPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.SignupPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the SignupPage container!',
  },
  alreadyHasAccount: {
    id: `${scope}.alreadyHasAccount`,
    defaultMessage: 'Đã có tài khoản?',
  },
  signinBang: {
    id: `${scope}.signinBang`,
    defaultMessage: 'Đăng nhập!',
  },
  waitingApprove: {
    id: `${scope}.waitingApprove`,
    defaultMessage: 'Tài khoản đang chờ duyệt',
  },
  accountDeleted: {
    id: `${scope}.accountDeleted`,
    defaultMessage: 'Tài khoản này đã bị xoá',
  },
  welcome: {
    id: `${scope}.welcome`,
    defaultMessage: 'Chào mừng đến với Kamereo!',
  },
  thankyou: {
    id: `${scope}.thankyou`,
    defaultMessage: 'Cám ơn bạn đã đăng kí tài khoản!',
  },
  gotoSignIn: {
    id: `${scope}.gotoSignIn`,
    defaultMessage: 'Đăng Nhập',
  },
  signupMessage: {
    id: `${scope}.signupMessage`,
    defaultMessage: 'Tài khoản của bạn đã được tạo thành công, bạn có thể đăng nhập bằng cách bấm vào nút bên dưới.',
  },
  contactUs: {
    id: `${scope}.contactUs`,
    defaultMessage: 'Hãy liên hệ chúng tôi nếu bạn cần hỗ trợ!',
  },
  confirmPasswordNotCorrect: {
    id: `${scope}.confirmPasswordNotCorrect`,
    defaultMessage: 'Xác nhận mật khẩu không đúng!',
  },
  atLeast8Char: {
    id: `${scope}.atLeast8Char`,
    defaultMessage: 'Ít nhất 8 ký tự',
  },
  atLeast1Num: {
    id: `${scope}.atLeast1Num`,
    defaultMessage: 'Ít nhất một ký tự là số',
  },
  noSpace: {
    id: `${scope}.noSpace`,
    defaultMessage: 'Không có khoảng trắng',
  },
  accountLogin: {
    id: `${scope}.accountLogin`,
    defaultMessage: 'Tài khoản đăng nhập Kamereo',
  },
});
