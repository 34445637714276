import gql from 'graphql-tag';

export default gql`
  mutation orderPendingApprovalUpdateItem($input: OrderUpdateItemInput!) {
    orderPendingApprovalUpdateItem(input: $input) {
      userErrors {
        field
        message
      }
      success
      order {
        id
        total
        items {
          total
          note
          price
          orderedPrice
          product {
            id
            name
            note
            price
            vat
            uom
            uomLocal
            inStock
          }
          deliveryQuantity
          orderedQuantity
          deliveredNote

          buyerNote
        }
        orderDate
        deliveryTime {
          start
          end
        }
        status
        statusText
        note
        buyer {
          id
          name
        }
        store {
          id
          name
        }
        supplier {
          id
          name
          imageUrl
          location {
            address
            district
            city
          }
        }
        creator {
          id
          name
        }
        paymentMethod
        redelivery
      }
    }
  }
`;
