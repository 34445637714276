import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useIntersection } from 'hooks';

export const Container = styled.div<any>`
  ${({ hasShadow }) => (hasShadow ? `box-shadow: 4px 4px 8px rgba(4,15,15,0.12);` : ``)};
  ${({ hasBackground }) =>
    hasBackground
      ? `
      margin-bottom: 6pt; 
      background: white; 
      border-radius: 4px; 
      padding: 14px; 
      border: none;`
      : ``};
  ${({ loading }) => {
    if (loading) {
      return `
        pointer-events: none;
        cursor: default;
      `;
    }
    return `
      pointer-events: auto;
      cursor: pointer;
    `;
  }};
  height: 100%;
  .ant-btn.ant-btn-circle {
    min-width: 24px !important;
  }
`;

interface ICardLinkProps extends RouteComponentProps {
  loading?: boolean;
  onClick?: (...arg: any) => void;
  to?: string;
  hasBackground?: boolean;
  hasShadow?: boolean;
  onTrack?: () => void;
}

const CardLink: FC<ICardLinkProps> = (props) => {
  const { loading, onClick, onTrack, to, history, hasBackground, hasShadow, children } = props;
  const ref = useRef();
  const isVisibleInViewport = useIntersection(ref, '0px');
  const [trackedStatus, setTrackedStatus] = useState<boolean>();

  const containerClick = (event) => {
    const targetTagName = event.target.tagName;
    const pattern = /^a|button|input$/i;

    if (loading || pattern.test(targetTagName)) {
      return;
    }
    if (onClick) {
      onClick(event);
    }
    if (to) {
      history.push(to);
    }
  };

  useEffect(() => {
    if (!onTrack) {
      return;
    }
    if (!isVisibleInViewport) {
      setTrackedStatus(false);
    }
  }, [onTrack, isVisibleInViewport, setTrackedStatus]);

  useEffect(() => {
    if (!onTrack) {
      return;
    }
    if (isVisibleInViewport && !trackedStatus) {
      onTrack();
      setTrackedStatus(true);
    }
  }, [onTrack, isVisibleInViewport, trackedStatus, setTrackedStatus]);

  return (
    <Container loading={loading} hasBackground={hasBackground} hasShadow={hasShadow} onClick={containerClick} ref={ref}>
      {children}
    </Container>
  );
};

export default withRouter(CardLink);
