import gql from 'graphql-tag';

export default gql`
  query me {
    me {
      name
      id
      email
      status
      imageUrl
      lang
      telephone
      permissions {
        action
      }
      userGroups {
        name
        permissions {
          action
        }
      }
    }
  }
`;
