import gql from 'graphql-tag';

export default gql`
  mutation invitationAccept($buyerId: Int!, $invitationId: ID!) {
    invitationAccept(buyerId: $buyerId, invitationId: $invitationId) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
