import TagManager from 'react-gtm-module';
import { Product, SingleSupplierCart } from 'types/schema';
import isGoogleBot from './urls/isGoogleBot';
export enum EventName {
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
  VIEW_ITEM = 'view_item',
  ADD_TO_CART = 'add_to_cart',
  SIGN_UP = 'sign_up',
  LOGIN = 'login',
}
interface IOrderItem {
  item_id: string;
  item_name: string;
  index: number;
  item_category?: string;
  item_category2?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_brand: string;
  price: number;
  quantity: number;
}
interface IEcommerceData {
  currency: string;
  value: string | number;
  items: IOrderItem[];
  shipping_tier?: string;
  payment_type?: string;
  transaction_id?: string;
}
interface IProduct extends Product {
  quantity?: number;
  addFrom?: string;
}
interface ICart extends SingleSupplierCart {
  id?: string;
}
type DataParams = ICart | IProduct;

interface IDataLayer extends Record<string, any> {
  event?: string;
  ecommerce?: IEcommerceData | null;
}

const sendDataLayer = (dataLayer: IDataLayer) => {
  if (isGoogleBot() || process.env.APP_ENV !== 'production') return;
  TagManager.dataLayer({
    dataLayer: dataLayer,
  });
};
const generateScreenID = (screenName: string) => {
  const screenNameArr = screenName.split(/(?=[A-Z])/);
  return screenNameArr.map((word) => word.toLowerCase()).join('_');
};

const handleGenerateOrderListData = (data: ICart) => {
  return data?.items.map((itemCart, index) => {
    const { product, quantity } = itemCart;
    const result: IOrderItem = {
      item_id: product?.id ?? '',
      item_name: product?.name ?? '',
      index: index,
      item_brand: 'KAMEREO',
      price: product?.price ?? 0,
      quantity: quantity ?? 0,
    };
    if (product?.category) {
      result.item_category = product.category?.parent?.name;
      result.item_category2 = product.category?.name;
    }
    return result;
  });
};
const handleGenerateSingleItem = (data: IProduct) => {
  const result: IOrderItem = {
    item_id: data?.id ?? '',
    item_name: data?.name ?? '',
    index: 0,
    item_category: data.category?.parent?.name ?? '',
    item_category2: data.category?.name ?? '',
    item_brand: 'KAMEREO',
    price: data?.price ?? 0,
    quantity: data.quantity ?? 1,
  };
  if (data.addFrom) {
    result.item_list_id = generateScreenID(data.addFrom);
    result.item_list_name = data.addFrom;
  }
  return [result];
};
const isCheckoutJourney = (data: DataParams): data is ICart => 'items' in data;

const triggerGA4EcommerceEvent = (eventName: EventName, data: DataParams) => {
  if (isGoogleBot() || process.env.APP_ENV !== 'production') return;
  const ecommerceData: IEcommerceData = {
    currency: 'VND',
    value: 0,
    items: [],
  };
  if (isCheckoutJourney(data)) {
    ecommerceData.items = handleGenerateOrderListData(data);
    ecommerceData.value = data.total ?? 0;
    switch (eventName) {
      case EventName.ADD_SHIPPING_INFO:
        ecommerceData.shipping_tier = 'GROUND';
        break;
      case EventName.ADD_PAYMENT_INFO:
        ecommerceData.payment_type = 'COD';
        break;
      case EventName.PURCHASE:
        ecommerceData.transaction_id = data.id;
        break;
      default:
        break;
    }
  } else {
    ecommerceData.items = handleGenerateSingleItem(data);
    switch (eventName) {
      case EventName.VIEW_ITEM:
        ecommerceData.value = data.price;
        break;
      case EventName.ADD_TO_CART:
        ecommerceData.value = data.price * (data?.quantity ?? 1);
        break;
      default:
        break;
    }
  }
  sendDataLayer({ ecommerce: null }); // Clear the previous ecommerce object.
  sendDataLayer({
    event: eventName,
    ecommerce: ecommerceData,
  });
};
export const triggerGA4SignUpEvent = (data) => {
  sendDataLayer({
    event: EventName.SIGN_UP,
    ...data,
  });
};
export const triggerGA4SendUserIdEvent = (userId: string) => {
  sendDataLayer({
    user_id: userId,
  });
};
export default triggerGA4EcommerceEvent;
