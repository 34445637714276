import gql from 'graphql-tag';

export default gql`
  mutation buyerCreateAccount($input: BuyerCreateAccountInput) {
    buyerCreateAccount(input: $input) {
      success
      userErrors {
        field
        message
      }
      token
      rememberMeToken
    }
  }
`;
