import { isEmpty } from 'lodash';

export const webDomainMaping = {
  development: 'https://buyer.qa.kamereo.vn',
  production: 'https://kamereo.vn',
};

/**
 * Render absolute URL path base on the appEnv variable
 * @param appEnv The application environment
 * @param slug The slug of the web page, remove unnecessary forward slashes
 * @returns String absolute URL path
 */
const renderWithWebDomain = (appEnv: string | undefined, slug?: string): string => {
  const forwardSlash = slug && slug?.search('/') !== -1 ? '' : '/';
  if (!appEnv || (appEnv && isEmpty(webDomainMaping[appEnv]))) {
    return `${webDomainMaping.production}${forwardSlash}${slug || ''}`;
  }
  return `${webDomainMaping[appEnv]}${forwardSlash}${slug || ''}`;
};

export default renderWithWebDomain;
