import styled from 'styled-components';

export const Card = styled.article`
  background-color: #fcfffc;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
