import gql from 'graphql-tag';

export default gql`
  query orderDisabled {
    orderDisabled {
      orderDisabled
      message
    }
  }
`;
