import gql from 'graphql-tag';

export default gql`
  mutation meRelogin($token: String!) {
    meRelogin(token: $token) {
      token
      rememberMeToken
      success
      userErrors {
        field
        message
      }
    }
  }
`;
