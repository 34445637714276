import gql from 'graphql-tag';

export default gql`
  mutation checkEmail($email: String) {
    checkEmail(email: $email) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
