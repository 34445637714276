import gql from 'graphql-tag';

export default gql`
  mutation cartCreateOrderDraft($input: CartCreateOrderDraftInput!) {
    cartCreateOrderDraft(input: $input) {
      success
      userErrors {
        field
        message
      }
      draftToken
    }
  }
`;
