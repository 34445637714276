import styled from 'styled-components';
import { ReactSVG, Props as ReactSVGProps } from 'react-svg';
import React, { ImgHTMLAttributes } from 'react';
import { css } from 'styles/styled-components';
import withStyledComponent from 'utils/withStyledComponent';

interface StyledSVGProps extends ReactSVGProps {
  renderAsImg?: boolean;
  imgAttr?: ImgHTMLAttributes<HTMLImageElement>; // Allows passing all ImgHTMLAttributes
  className?: string;
  as?: string; // Add the 'as' property
}

const commonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  &.relative-position {
    position: relative;
    left: -6px;
  }
`;

const SVGIconWrapper = styled.div`
  ${commonStyles}
`;

const StyledSVG = styled(ReactSVG)<ReactSVGProps>`
  ${({ as }) => as !== 'img' && commonStyles}
`;

// Component for rendering SVG as an image with all passed ImgHTMLAttributes
const ImgStyledSVG: React.FC<StyledSVGProps> = ({ imgAttr, ...props }) => {
  if (props.as === 'img' && imgAttr) {
    // Directly render an img tag when as="img" and imgAttr is provided
    return <img src={props.src} {...imgAttr} className={props.className} />;
  }
  // For SVG rendering, continue using StyledSVG without imgAttr
  return <StyledSVG {...props} />;
};

// Component for rendering SVG with optional image styling and attributes
const StyledSVGWithImg: React.FC<StyledSVGProps> = ({ className, imgAttr, ...props }) => (
  <SVGIconWrapper className={className}>
    <div>
      <ImgStyledSVG as="img" {...props} imgAttr={imgAttr} className="injected-svg" />
    </div>
  </SVGIconWrapper>
);

/**
 * SVGIcon Component
 *
 * This component is designed to render SVG icons with optional enhancements. It supports rendering as a traditional SVG or as an image tag (`<img>`), depending on the provided props. Additional image attributes can be passed for more control over the rendering when using the `<img>` tag. It also supports custom styling through styled-components.
 *
 * Props:
 * - `renderAsImg` (boolean): Determines if the SVG should be rendered as an `<img>` tag. Defaults to true.
 * - `className` (string): Optional. Custom class name to apply to the component for styling.
 * - `imgAttr` (ImgHTMLAttributes<HTMLImageElement>): Optional. Attributes to pass to the `<img>` tag when `renderAsImg` is true.
 * - Additional props supported by `ReactSVGProps`.
 *
 * Returns:
 * - A React component that renders an SVG icon, either as a traditional SVG or as an image, with optional custom styling.
 */

// Enhanced SVGIcon component that can receive and pass all ImgHTMLAttributes
const SVGIcon: React.FC<StyledSVGProps> = ({ renderAsImg = true, className, imgAttr, ...props }) => {
  return renderAsImg ? (
    <StyledSVGWithImg className={className} imgAttr={imgAttr} {...props} />
  ) : (
    <StyledSVG {...props} className={className} />
  );
};

export default withStyledComponent(SVGIcon);
