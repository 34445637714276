import('intersection-observer');
import { useState, useEffect, RefObject } from 'react';

export const useIntersection = (element: RefObject<any>, rootMargin: string): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin: rootMargin },
    );
    if (element.current) {
      observer.observe(element.current);
    }
    return () => observer.unobserve(element.current);
  }, []);

  return isVisible;
};
