import gql from 'graphql-tag';

export default gql`
  query suppliers($pagination: Pagination!, $filter: SupplierFilterInput) {
    suppliers(pagination: $pagination, filter: $filter) {
      totalResults
      totalPage
      data {
        id
        imageUrl
        productCount
        name
      }
    }
  }
`;
