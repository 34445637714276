/*
 * DownloadApp Messages
 *
 * This contains all the text for the DownloadApp component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.DownloadApp';

export default defineMessages({
  orderAnytime: {
    id: `${scope}.orderAnytime`,
    defaultMessage: 'Đặt hàng mọi lúc, mọi nơi thông qua ứng dụng của chúng tôi:',
  },
});
