import React, { useEffect, useState } from 'react';

import Chatting from './Chatting.svg';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal/Modal';
import messages from 'containers/CheckoutPage/messages';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';

const Textarea = styled.textarea`
  border-radius: 4px;
  border: none;
  width: 100%;
  transition: padding 0.3s ease;

  &:focus {
    outline-width: 1px;
    padding: 10pt;
  }
`;

export default ({
  isOpen,
  onSubmit,
  onClose,
  headerLabel,
  note,
  children,
  confirmLoading,
  disableOkButton,
}: {
  isOpen: boolean;
  disableOkButton?: boolean;
  onSubmit: (data) => void;
  onClose: () => void;
  headerLabel: JSX.Element | string;
  note: string;
  confirmLoading?: boolean;
  children?: JSX.Element | JSX.Element[] | '';
}) => {
  const [noteValue, onChangeNote] = useState(note);

  useEffect(
    () => {
      onChangeNote(note);
    },
    [note],
  );

  return (
    <Modal
      headerIcon={Chatting}
      headerLabel={headerLabel}
      confirmLoading={confirmLoading}
      disableOkButton={!noteValue}
      isOpen={isOpen}
      submitLabel={<FormattedMessage {...utilsMessages.save} />}
      closeLabel={<FormattedMessage {...utilsMessages.cancel} />}
      onSubmit={() => {
        if (noteValue.trim()) {
          onSubmit(noteValue.trim());
        }
        onChangeNote(note);
      }}
      onClose={onClose}
    >
      <FormattedMessage {...messages.writeYourNote}>
        {(placeholder: string) => (
          <Textarea
            name="note"
            value={noteValue}
            onChange={(event: any) => onChangeNote(event.target.value)}
            rows={10}
            placeholder={placeholder}
          />
        )}
      </FormattedMessage>
      {children}
    </Modal>
  );
};
