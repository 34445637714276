import React, { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import media from 'utils/mediaStyle';
import MainLayout from 'containers/MainLayout';
import styled from 'styled-components';
import LoadingIndicator from 'components/LoadingIndicator';
import CategoryNavMobile from 'containers/CategoryNavMobile';
interface MainLayoutProps extends RouteProps {
  component: any;
}

const Main = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lg`
    flex-direction: row;
  `};
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - 53px);
`;

export default function ({ component: Component, ...rest }: MainLayoutProps) {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MainLayout>
          <Main>
            <CategoryNavMobile />
            <Suspense fallback={<LoadingIndicator isHomePage={window.location.pathname === '/'} />}>
              <MainContainer>
                <Component {...matchProps} />
              </MainContainer>
            </Suspense>
          </Main>
        </MainLayout>
      )}
    />
  );
}
