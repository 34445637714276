import gql from 'graphql-tag';

export default gql`
  query orderDraft($token: String!) {
    orderDraft(token: $token) {
      total
      orderDrafts {
        total
        paymentMethod
        deliveryNotes
        items {
          total
          note
          quantity
          deliveredNote
          buyerNote
          price
          orderedPrice
          product {
            id
            name
            imageUrl
            note
            vat
            uom
            uomLocal
            price
            supplierInternalCode
            category {
              name
              parent {
                name
              }
            }
          }
        }
        supplier {
          fulfillmentTime {
            hoursToDelivery
            days
            cutoffTime
          }
          moq
          id
          name
          private
        }
        deliveryDatetime {
          start
          end
        }
      }
    }
  }
`;
