import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './type';

export const initialState: any = {};

export default (state: ContainerState = initialState, action: ContainerActions) => {
  const {
    type,
    payload,
  } = action as any;
  switch (type) {
    case ActionTypes.FETCH_PROVINCE_SUCCESS: {
      return {
        ...state,
        [payload?.instanceId]: {
          ...state[payload?.instanceId],
          provinces: payload?.value,
        },
      };
    }
    case ActionTypes.FETCH_DISTRICT_SUCCESS: {
      return {
        ...state,
        [payload?.instanceId]: {
          ...state[payload?.instanceId],
          districts: payload?.value,
        },
      };
    }
    case ActionTypes.FETCH_WARDS_SUCCESS: {
      return {
        ...state,
        [payload?.instanceId]: {
          ...state[payload?.instanceId],
          wards: payload?.value,
        },
      };
    }
    default:
      return state;
  }
};
