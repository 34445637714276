import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './type';

export const initialState: ContainerState = {
  loading: false,
};

export default (state: ContainerState = initialState, action: ContainerActions) => {
  const { type } = action as any;
  switch (type) {
    case ActionTypes.CREATE_STORE_REQUEST: {
      return {
        loading: true,
      };
    }
    case ActionTypes.CREATE_STORE_SUCCESS:
    case ActionTypes.CREATE_STORE_FAILURE: {
      return {
        loading: false,
      };
    }
    default:
      return state;
  }
};
