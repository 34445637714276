import gql from 'graphql-tag';

export default gql`
  query orders($filter: OrderFilterInput, $pagination: Pagination!) {
    orders(pagination: $pagination, filter: $filter) {
      totalPage
      totalResults
      data {
        id
        supplier {
          id
          name
        }
        creator {
          id
          name
        }
        orderDate
        status
        statusText
        total
        deliveryTime {
          start
          end
        }
        items {
          product {
            id
            price
            name
            note
            uom
            uomLocal
            supplierInternalCode
          }
          note
          buyerNote
          quantity
        }
      }
    }
  }
`;
