/*
 *
 * CheckoutStep1Page constants
 *
 */

enum ActionTypes {
  DEFAULT_ACTION = 'app/CheckoutStep2Page/DEFAULT_ACTION',
  UPDATE_DELIVERY_DETAILS = 'app/CheckoutStep2Page/UPDATE_DELIVERY_DETAILS',
  UPDATE_DELIVERY_NOTE = 'app/CheckoutStep2Page/UPDATE_DELIVERY_NOTE',
  UPDATE_DELIVERY_NOTE_REQUEST = 'app/CheckoutStep2Page/UPDATE_DELIVERY_NOTE_REQUEST',
  UPDATE_DELIVERY_NOTE_SUCCESS = 'app/CheckoutStep2Page/UPDATE_DELIVERY_NOTE_SUCCESS',
  UPDATE_DELIVERY_NOTE_FAILURE = 'app/CheckoutStep2Page/UPDATE_DELIVERY_NOTE_FAILURE',
  ADD_NOTE = 'app/CheckoutStep2Page/ADD_NOTE',
  ADD_NOTE_REQUEST = 'app/CheckoutStep2Page/ADD_NOTE_REQUEST',
  ADD_NOTE_SUCCESS = 'app/CheckoutStep2Page/ADD_NOTE_SUCCESS',
  ADD_NOTE_FAILURE = 'app/CheckoutStep2Page/ADD_NOTE_FAILURE',
}

export default ActionTypes;
