import gql from 'graphql-tag';

export default gql`
  mutation companyInviteUsers($emails: [String!]!) {
    companyInviteUsers(email: $emails) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
