import React, { Suspense } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import GateLayout from '../containers/GateLayout';
import LoadingIndicator from 'components/LoadingIndicator';

interface GateLayoutProps extends RouteProps {
  component: any;
  acceptGuest?: boolean;
}

export default function({ component: Component, acceptGuest, ...rest }: GateLayoutProps) {
  const loggedIn = !!localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={matchProps =>
        !loggedIn || acceptGuest ? (
          <GateLayout>
            <Suspense fallback={<LoadingIndicator />}>
              <Component {...matchProps} />
            </Suspense>
          </GateLayout>
        ) : (
          <Redirect
            push
            to={{
              pathname: '/',
              state: { referrer: matchProps.location },
            }}
          />
        )
      }
    />
  );
}
