import gql from 'graphql-tag';

export default gql`
  mutation meLogin($email: String!, $password: String!, $rememberMe: Boolean) {
    meLogin(email: $email, password: $password, rememberMe: $rememberMe) {
      token
      rememberMeToken
      success
      userErrors {
        field
        message
      }
    }
  }
`;
