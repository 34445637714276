/*
 *
 * CheckoutStep1Page actions
 *
 */

import { action, createAsyncAction } from 'typesafe-actions';
import {} from './types';

import ActionTypes from './constants';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const updateDeliveryDetails = (data: any) => action(ActionTypes.UPDATE_DELIVERY_DETAILS, data);
export const updateDeliveryNote = (data: any) => action(ActionTypes.UPDATE_DELIVERY_NOTE, data);
export const deliveryNote = createAsyncAction(
  ActionTypes.UPDATE_DELIVERY_NOTE_REQUEST,
  ActionTypes.UPDATE_DELIVERY_NOTE_SUCCESS,
  ActionTypes.UPDATE_DELIVERY_NOTE_FAILURE,
)<void, {}, Error>();
export const addNoteSubmit = (data: any) => action(ActionTypes.ADD_NOTE, data);
export const addNote = createAsyncAction(
  ActionTypes.ADD_NOTE_REQUEST,
  ActionTypes.ADD_NOTE_SUCCESS,
  ActionTypes.ADD_NOTE_FAILURE,
)<void, {}, Error>();
