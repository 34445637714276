import { useCallback, useState } from 'react';

export enum SessionStorageKey {
  HOMEPAGE_POPUP = 'checkOpenedHomepagePopup',
  CAMPAIGN_SOURCE = 'campaignSource',
  NOT_SHOW_ALCOHOL_POPUP_AGAIN = 'notShowAlcoholPopupAgain',
}

type UseSessionStorage<T> = [
  T, // type of data retrieve from Session storage
  (value: T) => void, // set data to Session storage
  () => void, // remove data from Session storage
];

/**
 * Storage a string or an object to Session storage with given key
 * @param key of storage data
 * @param defaultValue could be string or and object
 * @returns [retrieved data,@function to set data,@function to remove data]
 */
const useSessionStorage = <T>(key: SessionStorageKey, defaultValue?: T): UseSessionStorage<T> => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const value = window.sessionStorage.getItem(key);
      if (!value) {
        window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
      return JSON.parse(value);
    } catch (error) {}
  });

  const setValue = useCallback(
    (newValue: T) => {
      setStoredValue(newValue);
      try {
        window.sessionStorage.setItem(key, JSON.stringify(newValue));
      } catch (error) {}
    },
    [key],
  );

  const removeValue = useCallback(() => {
    window.sessionStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, removeValue];
};

export default useSessionStorage;
