import gql from 'graphql-tag';

export default gql`
  mutation cartClearSupplierLight($supplierId: Int!, $storeId: String!) {
    cartClearSupplierLight(supplierId: $supplierId, storeId: $storeId) {
      success
      userErrors {
        field
        message
      }
      cart {
        id
        buyer {
          id
        }
        store {
          id
        }
        total
        carts {
          items {
            item {
              id
              vat
              note
              price
              name
              uom
            }
            total
            orderedQty
          }
          supplier {
            id
            name
            moq
          }
          total
        }
      }
    }
  }
`;
