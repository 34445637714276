import gql from 'graphql-tag';

export default gql`
  query recommendedForYou($pagination: Pagination!) {
    recommendedForYou(pagination: $pagination) {
      totalPage
      totalResults
      recommendationId
      data {
        id
        supplier {
          id
          name
        }
        imageUrl
        uomLocal
        uom
        price
        originalPrice
        category {
          name
          parent {
            name
          }
        }
        inStock
        isFavorite
        name
        note
        tags
        supplierInternalCode
      }
    }
  }
`;
