/*
 *
 * DeliveryDateTime actions
 *
 */

import ActionTypes from './constants';
import { createAsyncAction } from 'typesafe-actions';

export const loadDeliveryDateTime = createAsyncAction(
  ActionTypes.LOAD_DELIVERY_DATE_TIME_REQUEST,
  ActionTypes.LOAD_DELIVERY_DATE_TIME_SUCCESS,
  ActionTypes.LOAD_DELIVERY_DATE_TIME_FAILURE,
)<{}, {}, Error>();

export const loadDeliveryDateOff = createAsyncAction(
  ActionTypes.LOAD_DELIVERY_DATE_OFF_REQUEST,
  ActionTypes.LOAD_DELIVERY_DATE_OFF_SUCCESS,
  ActionTypes.LOAD_DELIVERY_DATE_OFF_FAILURE,
)<{}, {}, Error>();
