import gql from 'graphql-tag';

export default gql`
  query storeOrderItemsSummary($queryInput: StoreOrderItemsSummaryInput) {
    storeOrderItemsSummary(queryInput: $queryInput) {
      node {
        cursor
        summary {
          date
          summaries {
            supplier {
              id
              name
            }
            product {
              id
              name
              note
              vat
              uom
              uomLocal
              supplierInternalCode
            }
            quantity
            # Please calculate prices as below
            # Price = totalNetPrice / quantity
            # Price (incl. VAT) = totalGrossPrice / quantity
            totalNetPrice
            totalGrossPrice
            lastMonthTotalGrossPrice
            # MoM = totalGrossPrice / lastMonthTotalGrossPrice
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        nextCursor
        previousCursor
      }
    }
  }
`;
