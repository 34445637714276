import { keys, get } from 'lodash';

const _flattenKeys = (obj: any, keyPrefix: string): object => {
  const type = typeof obj;
  switch (type) {
    case 'object': {
      return keys(obj).reduce(
        (accumulated, nextKey) => ({
          ...accumulated,
          ..._flattenKeys(get(obj, nextKey), `${keyPrefix}.${nextKey}`),
        }),
        {},
      );
    }
    case 'undefined':
    case 'boolean':
    case 'number':
    case 'string':
    case 'symbol':
    case 'bigint':
    case 'function':
      return {
        [keyPrefix]: obj,
      };
  }
};

const flattenKeys = (obj: object) => {
  return keys(obj).reduce(
    (accumulated, nextKey) => ({
      ...accumulated,
      ..._flattenKeys(get(obj, nextKey), `${nextKey}`),
    }),
    {},
  );
};

export default flattenKeys;
