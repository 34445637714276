import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectAddressComponentDomain = (state: ApplicationRootState) => {
  return state ? state.addressComponent : initialState;
};

const selectAddressComponentComponent = () => createSelector(selectAddressComponentDomain, substate => substate);

export default selectAddressComponentComponent;
export { selectAddressComponentDomain };
