import styled from 'styles/styled-components';
import React, { useMemo, useRef, useEffect, useState } from 'react';
import { Spin } from 'antd';

const Banner = styled.div`
  height: 100%;
  position: relative;
  background-size: cover !important;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
  opacity: 0;
  &.show-banner {
    opacity: 1;
  }
`;
const BannerContainer = styled.div`
  height: 100%;
  position: relative;
`;
const SpinnerBanner = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #eeeeee;
  background-size: cover !important;
`;
const SpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
interface IBannerProps {
  onClick?: () => void;
  imageUrl?: string;
}
const option = {
  root: null,
  rootMargin: '0px 0px 0px -50%',
  threshold: 0,
};
export default (props: IBannerProps) => {
  const { onClick, imageUrl } = props;
  const bannerRef = useRef<HTMLElement>(null);
  const [hasBackground, setHasBackground] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (bannerRef?.current && !bannerRef?.current?.style?.background) {
          bannerRef.current.style.background = `url('${imageUrl}') no-repeat center`;
          setHasBackground(true);
        }
      }
    }, option);
    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }
    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, [bannerRef.current, imageUrl]);
  const content = useMemo(
    () => (
      <>
        <Banner imageUrl={imageUrl} onClick={onClick} ref={bannerRef} className={hasBackground ? 'show-banner' : ''} />
        {!hasBackground && (
          <SpinnerBanner>
            <SpinnerWrapper>
              <Spin />
            </SpinnerWrapper>
          </SpinnerBanner>
        )}
      </>
    ),
    [onClick, imageUrl, hasBackground],
  );

  return <BannerContainer>{content}</BannerContainer>;
};
