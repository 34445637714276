import gql from 'graphql-tag';

export default gql`
  mutation featureFlagToggle($input: FeatureFlagToggleInput!) {
    featureFlagToggle(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
