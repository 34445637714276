import gql from 'graphql-tag';

export default gql`
  mutation companyUpdateRedInvoice($input: BuyerUpdateInput!) {
    companyUpdateRedInvoice(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
