import gql from "graphql-tag";

export default gql`
  query getRTELocationList($city: String!) {
      getRTELocationList(city: $city) {
          total
          data {
              id
              distributor
              name_vi
              name_en
              addr_vi
              addr_en
              district_vi
              district_en
              city_vi
              city_en
              geo_lat
              geo_long
              open_time
              close_time
              tel
              plus_code
          }
      }
  }
`;