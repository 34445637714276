import gql from 'graphql-tag';

export default gql`
  query getGroupCategories($brandIdsList: [Int], $tagsList: [String]) {
    getGroupCategories(brandIdsList: $brandIdsList, tagsList: $tagsList) {
      cateId
      count
    }
  }
`;
