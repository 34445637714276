/**
 *
 * DownloadApp
 *
 */

import * as React from 'react';

import AppStoreImg from './AppStore.svg';
import { FormattedMessage } from 'react-intl';
import GoogleStoreImg from './GoogleStore.svg';
import messages from './messages';
import styled from 'styles/styled-components';

const Container = styled.div`
  text-align: center;
`;

const StoreContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 16px;
`;

const AppStoreImage = styled.img`
  width: 140px;
  height: 40px;
  margin-right: 7px;
`;
const GoogleStoreImage = styled.img`
  width: 140px;
  height: 40px;
  margin-left: 7px;
`;

interface OwnProps {}

const DownloadApp: React.SFC<OwnProps> = (props: OwnProps) => {
  return (
    <Container>
      <p>
        <FormattedMessage {...messages.orderAnytime} />
      </p>
      <StoreContainer>
        <a href="https://apps.apple.com/us/app/kamereo/id1469505654" target="_blank">
          <AppStoreImage src={AppStoreImg} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=vn.kamereo.buyer" target="_blank">
          <GoogleStoreImage src={GoogleStoreImg} />
        </a>
      </StoreContainer>
    </Container>
  );
};

export default DownloadApp;
