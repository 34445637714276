import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

const selectHomePageDomain = (state: ApplicationRootState) => state.homePage || initialState;

const selectHomePage = () => createSelector(selectHomePageDomain, substate => substate);

const selectLoading = () => createSelector(selectHomePageDomain, substate => substate.loading);
const selectWidgets = () => createSelector(selectHomePageDomain, substate => substate.widgets);
const selectSections = () => createSelector(selectHomePageDomain, substate => substate.sections);
const selectNoteItemLoading = () => createSelector(selectHomePageDomain, substate => substate.loadingNote);

export default selectHomePage;
export { selectHomePageDomain, selectLoading, selectWidgets, selectSections, selectNoteItemLoading };
