import gql from 'graphql-tag';

export default gql`
  query deliveryNotes {
    deliveryNotes {
      id
      text
    }
  }
`;
