import React, { CSSProperties } from 'react';
import formatMoney from '../../../utils/formatMoney';
import styled from 'styled-components';
import { Product } from 'types/schema';

export const PriceText = styled.p`
  color: #595959;
  font-size: 16px;
  font-weight: 700;
`;

export interface PriceProps {
  style?: CSSProperties;
  product: Product;
}

const PriceContainer = styled.div`
  height: 36px;
`;

export const Price = (props: PriceProps) => {
  const { product } = props;
  const { price, originalPrice, uomLocal } = product;

  const getPriceText = (price: number, style?: any) => {
    return (
      <PriceText style={style}>
        {formatMoney(price)}
        đ/
        {uomLocal}
      </PriceText>
    );
  };

  return (
    <PriceContainer>
      {getPriceText(price)}
      {originalPrice > price
        ? getPriceText(originalPrice, {
            color: '#8c8c8c',
            textDecoration: 'line-through',
            fontWeight: 400,
            fontSize: 12,
          })
        : null}
    </PriceContainer>
  );
};
