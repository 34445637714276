import * as React from 'react';
import styled from 'styled-components';
import LazyImage from 'components/Image/LazyImage';
import noImage from './noIMG.svg';
import media from 'utils/mediaStyle';

export const ImageWrapper = styled.div<any>`
  padding-bottom: 100%;
  position: relative;
  background: #ffffff;
  overflow: hidden;
  ${({ zoomIn }) =>
    zoomIn
      ? `
  img {
    transition: transform 0.3s ease-out;
    transform: scale(1, 1, 1);
    &:hover {
      transform: scale3d(1.2, 1.2, 1.2);
    }
  }
  `
      : ``};
`;

export const RImage = styled(({ noImage, ...rest }) => <LazyImage {...rest} />)<any>`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
  object-fit: ${({ noImage }) => (noImage ? 'contain' : 'cover')};
  ${({ noImage }) => (noImage ? `opacity: 0.3;` : '')};

  ${media.md`
    padding: 8px;
    border-radius: 10px;
  `};
`;

export interface ImageProps {
  src?: string;
  alt: string;
  zoomIn?: boolean;
}

export const Image = ({ src, alt, zoomIn }: ImageProps) => (
  <ImageWrapper zoomIn={zoomIn}>
    <RImage noImage={!src} src={src || noImage} alt={alt} />
  </ImageWrapper>
);
