import gql from 'graphql-tag';

export default gql`
  mutation cartClearSupplier($supplierId: Int!, $storeId: String!) {
    cartClearSupplier(supplierId: $supplierId, storeId: $storeId) {
      success
      userErrors {
        field
        message
      }
      cart {
        redInvoiceInfo {
          registeredName
          registeredAddress
          redInvoiceNumber
        }
        deliveryNotes
        cartSet {
          paymentMethod
          deliveryNotes
          orderDeadline
          supplier {
            fulfillmentTime {
              hoursToDelivery
              days
              cutoffTime
            }
            moq
            id
            name
            private
          }
          earliestDate
          deliveryDatetime {
            start
            end
          }
          deliveryTimeSlots {
            start
            end
          }
          total
          items {
            product {
              supplierInternalCode
              id
              name
              imageUrl
              note
              vat
              uom
              uomLocal
              price
              allowPurchase
              inStock
              category {
                name
                parent {
                  name
                }
              }
            }
            quantity
          }
        }
      }
    }
  }
`;
