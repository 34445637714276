import React from 'react';
import styled from 'styles/styled-components';

interface OwnProps {
  match: any;
}

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;
interface State {}

const DOCS = [
  {
    path: 'privacy-policy',
    lang: 'en',
    meta: {
      pdfTitle: 'KAMEREO Privacy Policy',
      pdfUrl: 'https://s3-ap-southeast-1.amazonaws.com/public-docs.kamereo.vn/Kamereo+Privacy+Policy+(EN).pdf',
    },
  },
  {
    path: 'privacy-policy',
    lang: 'vi',
    meta: {
      pdfTitle: 'KAMEREO Chính sách bảo mật',
      pdfUrl: 'https://s3-ap-southeast-1.amazonaws.com/public-docs.kamereo.vn/Kamereo+Privacy+Policy+(VN).pdf',
    },
  },
  {
    path: 'term-of-service',
    lang: 'vi',
    meta: {
      pdfTitle: 'KAMEREO Điều khoản sử dụng',
      pdfUrl: 'https://s3-ap-southeast-1.amazonaws.com/public-docs.kamereo.vn/Kamereo+Term+of+Service+-+Buyer+(VN).pdf',
    },
  },
  {
    path: 'term-of-service',
    lang: 'en',
    meta: {
      pdfTitle: 'KAMEREO Term of Service',
      pdfUrl: 'https://s3-ap-southeast-1.amazonaws.com/public-docs.kamereo.vn/Kamereo+Term+of+Service+-+Buyer+(EN).pdf',
    },
  },
];

const Container = styled.div`
  text-align: center;
`;

const Title = styled.h1`
  padding: 16px;
`;

const Content = styled.iframe`
  min-height: 100vh;
`;

export class DocPage extends React.PureComponent<Props, State> {
  public render() {
    const { match } = this.props;
    const path = match.params.slug;
    const lang = window.localStorage.getItem('lang') || 'vi';

    const doc = DOCS.find(d => d.path === path && d.lang === lang);

    let pdfTitle = '';
    let pdfUrl = '';

    if (doc) {
      pdfTitle = doc.meta.pdfTitle;
      pdfUrl = doc.meta.pdfUrl;
    }

    return (
      <Container>
        <Title>{pdfTitle}</Title>
        <Content src={pdfUrl} frameBorder="0" height="1000px" width="100%" />
      </Container>
    );
  }
}

export default DocPage;
