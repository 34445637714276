import React, { useRef, useEffect, useMemo } from 'react';
import noImage from '../MarketItem/noIMG.svg';
import generateResponsiveImage from 'utils/generateResponsiveImage';
import isValidURL from 'utils/urls/isValidImageURL';
import { RESPONSIVE_IMAGE_SIZES } from 'utils/urls/constants';
import useImageSize from 'hooks/useImageSize';
interface Props {
  src?: string;
  responsive?: boolean;
  alt?: string;
}

const option = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const responiveImageSizes = [RESPONSIVE_IMAGE_SIZES.MEDIUM, RESPONSIVE_IMAGE_SIZES.LARGE];

const getResponsiveSrc = (src: string) => {
  return responiveImageSizes.reduce(
    (acc, size) => ({
      ...acc,
      [size]: generateResponsiveImage(src, size, size),
    }),
    {},
  );
};

const findClosestSize = (width: number, sizes: RESPONSIVE_IMAGE_SIZES[]) => {
  return sizes.reduce((prev, curr) => (Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev));
};

const LazyImage = ({ src, responsive, ...rest }: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const hasSetSrc = useRef(false);
  const imageSize = useImageSize(imageRef);

  const imageSrcs = useMemo(() => {
    if (src && responsive && isValidURL(src)) return getResponsiveSrc(src);
    return {};
  }, [src]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        if (imageRef?.current?.hasAttribute('src-lazy')) {
          if (!hasSetSrc.current) {
            imageRef.current.src = imageRef.current.getAttribute('src-lazy') || noImage;
          }
          imageRef.current.removeAttribute('src-lazy');
        }
      }
    }, option);
    if (imageRef.current) {
      if (src && responsive) {
        const closestSize = findClosestSize(imageSize.width, responiveImageSizes);
        imageRef.current.src = imageSrcs[closestSize] ?? src;
        hasSetSrc.current = true;
      }
      observer.observe(imageRef.current);
    }
    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, [imageRef.current, src, imageSize]);
  return <img src={noImage} src-lazy={src} {...rest} ref={imageRef} />;
};

export default LazyImage;
