enum ActionTypes {
  SEND_IDENTIDY = 'SEND_IDENTIDY',
  SPLIT_READY = 'SPLIT_READY',
  SPLIT_UPDATE = 'SPLIT_UPDATE',
}

export enum TreatmentNames {
  BUYER_WEB_NOTIFICATION = 'BuyerWebNotification',
  BUYER_WEB_HOME_PAGE_POPUP = 'BuyerWebHomePagePopup',
}

export default ActionTypes;
