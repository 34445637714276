import React, { Component } from 'react';
import Mobile from './subCategoryMobile';
import { Viewport } from 'components/ViewportProvider';

class CategoryNavMobile extends Component<any, any> {
  public render() {
    return <Viewport.Consumer>{({ width }) => width < 1025 && <Mobile {...this.props} />}</Viewport.Consumer>;
  }
}

export default CategoryNavMobile;
