import gql from 'graphql-tag';

export default gql`
  mutation meForgetPassword($email: String!) {
    meForgetPassword(email: $email) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
