import gql from 'graphql-tag';

export default gql`
  mutation companyUpdate($buyer: BuyerUpdateInput, $redInvoice: CompanyUpdateRedInvoiceInput) {
    buyerUpdate(input: $buyer) {
      success
      userErrors {
        field
        message
      }
    }
    companyUpdateRedInvoice(input: $redInvoice) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
