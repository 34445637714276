import { selectTreatmentValue } from '@splitsoftware/splitio-redux';
import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { TreatmentNames } from './constants';

const selectTreatmentDomain = (state: ApplicationRootState) =>
  state.splitio || {
    isReady: true,
    treatments: {
      BuyerWebNotification: 'control',
      BuyerWebHomePagePopup: process.env.HOMEPAGE_POPUP_TREATMENT || 'off',
    },
  };

export const selectIsTreatmentReady = () => createSelector(selectTreatmentDomain, (subState) => subState.isReady);

export const selectTreatmentValueByName = (name: TreatmentNames) =>
  createSelector(selectTreatmentDomain, (subState) =>
    process.env.SPLITIO_ON === 'true' ? selectTreatmentValue(subState, name) : subState.treatments[name],
  );
