import gql from 'graphql-tag';

export default gql`
  mutation meUpdatePassword($input: MeUpdatePasswordInput!) {
    meUpdatePassword(input: $input) {
      success
      userErrors {
        field
        message
      }
    }
  }
`;
