import React from 'react';

interface InputState {
  durationUserFocused: number;
}

/**
 * @deprecated
 * Use TrackingInput.tsx instead
 */
class TrackingInput extends React.PureComponent<any, InputState> {
  constructor(props: any) {
    super(props);
    this.state = {
      durationUserFocused: 0,
    };
  }

  public trackOnFocus = (event) => {
    const { onFocus } = this.props;
    if (typeof onFocus === 'function') {
      onFocus(event);
    }

    this.setState({
      durationUserFocused: window.performance.now(),
    });
  };

  public trackOnBlur = (event) => {
    const { onBlur } = this.props;
    if (typeof onBlur === 'function') {
      onBlur(event);
    }
  };

  public render() {
    const { InputComponent, trackingCategory, trackingAction, onBlur, onFocus, onRef, ...props } = this.props;
    return <InputComponent {...props} onFocus={this.trackOnFocus} onBlur={this.trackOnBlur} ref={onRef} />;
  }
}

export default TrackingInput;
