/*
 * StoreForm Messages
 *
 * This contains all the text for the StoreForm component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.StoreForm';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the StoreForm component!',
  },
  basicInfo: {
    id: `${scope}.basicInfo`,
    defaultMessage: 'Thông tin cơ bản',
  },
  storeName: {
    id: `${scope}.storeName`,
    defaultMessage: 'Tên chi nhánh',
  },
  storeAddress: {
    id: `${scope}.storeAddress`,
    defaultMessage: 'Địa chỉ chi nhánh',
  },
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Số điện thoại không đúng định dạng',
  },
});
