import styled from 'styled-components';
export const QuantityInnerInput = styled.input`
  width: 30pt;
  box-sizing: border-box;
  text-align: center;
  border: none;
  color: rgba(0, 0, 0, 0.87);
  &:focus {
    outline: none;
  }
`;
